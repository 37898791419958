<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <div class="w-full lg:w-6/12 px-4 text-left">
          <h6 class="text-blueGray-700 text-xl font-bold">
            Customer Next Of Kin
          </h6>
        </div>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4" v-if="viewSearch">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Customer
            </label>
            <CustomerSearch
              :parsedCustomer="parsedCustomer"
              @select-client="assignCustomer"
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4" v-else>
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Customer Name
            </label>
            <p>{{ parsedCustomer.actual_buyer_name }}</p>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Next Of Kin Name
            </label>
            <input
              v-model="nextOfKin.cus_nok_name"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Next of Kin Relation
            </label>
            <input
              v-model="nextOfKin.cus_nok_relation"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Next Of Kin Address
            </label>
            <input
              v-model="nextOfKin.cus_nok_address"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Next of Kin Email
            </label>
            <input
              v-model="nextOfKin.cus_nok_email"
              type="email"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Next of Kin Phone
            </label>
            <input
              v-model="nextOfKin.cus_nok_phone"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Next of Kin Residence
            </label>
            <input
              v-model="nextOfKin.cus_nok_residence"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
      </div>
      <div class="mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <div class="w-full lg:w-12/12 px-4 text-right">
            <button
              v-if="!isProcessing && !isGetting && !viewSearch"
              @click="prevPage()"
              class="
                bg-orange-500
                text-white
                active:bg-orange-600
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Go Back
            </button>
            <button
              v-if="!isProcessing && !isGetting"
              @click="validateEntry()"
              class="
                bg-lightBlue-500
                text-white
                active:bg-lightBlue-600
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Save Details
            </button>
            <button
              v-if="!isProcessing && !isGetting && !viewSearch"
              @click="nextPage()"
              class="
                bg-indigo-500
                text-white
                active:bg-indigo-600
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Go To Next
            </button>
            <button
              v-if="isProcessing || isGetting"
              class="
                bg-emerald-500
                text-white
                active:bg-emerald-600
                font-bold
                uppercase
                text-small
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                mb-1
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              <i class="fas fa-spinner"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-auto">
          <CardCustomerNextOfKinTable
            :color="'light'"
            :nextOfKinList="nextOfKinList"
            :isProcessing="isProcessing"
            @delete-nextOfKin="deleteNextOfKin"
            @update-nextOfKin="updateNextOfKin"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardCustomerNextOfKinTable from "@/components/Tables/CardCustomerNextOfKinTable";
import CustomerSearch from "@/components/Search/CustomerSearch.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "CardCustomerNextOfKin",
  props: {
    parsedSelectedCustomer: {
      type: Object,
      default: () => ({}),
    },
    viewSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      nextOfKin: {
        customer_id: "",
        cus_nok_name: "",
        cus_nok_relation: "",
        cus_nok_address: "",
        cus_nok_email: "",
        cus_nok_phone: "",
        cus_nok_residence: "",
      },
      customers: [],
      nextOfKinList: [],
      sites: [],
      plotUsages: [],
      plotSizes: [],
      purchaseUsages: [],
      parsedCustomer: {},
    };
  },
  validations() {
    return {
      nextOfKin: {
        customer_id: { required },
        cus_nok_name: { required },
        cus_nok_relation: { required },
        cus_nok_address: { required },
        cus_nok_email: { required },
        cus_nok_phone: { required },
        cus_nok_residence: { required },
      },
    };
  },
  components: {
    CardCustomerNextOfKinTable,
    CustomerSearch,
  },
  created() {},
  mounted() {
    // this.getDemarcations();
    // this.getPurchaseUsages();
    if (Object.keys(this.parsedSelectedCustomer).length > 0) {
      this.parsedCustomer = this.parsedSelectedCustomer;
      this.nextOfKin.customer_id = this.parsedSelectedCustomer.id;
      this.getNextOfKinList(this.parsedSelectedCustomer.id);
    } else {
      if (!Object.keys(this.$route.query).length) {
        this.getNextOfKinListAll();
      } else {
        let selectedCustomer = JSON.parse(this.$route.query.customerToConvert);
        this.parsedCustomer = this.parsedSelectedCustomer;
        this.nextOfKin.customer_id = this.parsedSelectedCustomer.id;
        this.getNextOfKinList(selectedCustomer.id);
      }
    }
  },
  methods: {
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveNextOfKin();
      }
    },
    assignCustomer(customer) {
      this.nextOfKin.customer_id = customer.id;
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPurchaseUsages() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("purchase_usage")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.purchaseUsages = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getSites() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-sites")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.sites = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getNextOfKinList(id) {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customer-nok-list/" + id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.nextOfKinList = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getNextOfKinListAll() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customer-nok-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.nextOfKinList = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCustomers() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customers/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.customers = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    saveNextOfKin() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-nok-store", this.nextOfKin)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getNextOfKinList(_.nextOfKin.customer_id);
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    deleteNextOfKin(nextOfKin) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("customer-nok-delete/" + nextOfKin.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getNextOfKinList();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updateNextOfKin(nextOfKin) {
      this.nextOfKin = nextOfKin;
      this.isEdit = true;
    },
    emptyEntry() {
      this.nextOfKin = {
        customer_id: "",
        cus_nok_name: "",
        cus_nok_relation: "",
        cus_nok_address: "",
        cus_nok_email: "",
        cus_nok_phone: "",
        cus_nok_residence: "",
      };
    },
    nextPage() {
      this.$emit("next-page");
    },
    prevPage() {
      this.$emit("prev-page");
    },
  },
  watch: {
    parsedSelectedCustomer() {
      if (Object.keys(this.parsedSelectedCustomer).length > 0) {
        this.parsedCustomer = this.parsedSelectedCustomer;
        this.nextOfKin.customer_id = this.parsedSelectedCustomer.id;
        this.getNextOfKinList(this.parsedSelectedCustomer.id);
      }
    },
  },
};
</script>
