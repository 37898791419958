<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <CardCompanySettings />
    </div>
  </div>
</template>
<script>
import CardCompanySettings from "@/components/Cards/CardCompanySettings.vue";

export default {
  components: {
    CardCompanySettings,
  },
  data() {
    return {
      pageName: "Company Information",
    };
  },
};
</script>
