<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Land Owner Payments</h6>
        <button
          v-if="!isProcessing && !isGetting"
          @click="validateEntry()"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Make Payment
        </button>
        <button
          v-if="isProcessing || isGetting"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Land Owner
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="payment.land_owner_id"
            >
              <option v-for="(b, i) in owners" :key="i" :value="b.id">
                {{ b.land_owner_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Amount Paid
            </label>
            <input
              v-model="payment.amount_paid"
              type="number"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <!-- <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Payment Mode
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="payment.payment_mode_id"
            >
              <option v-for="(b, i) in paymentModes" :key="i" :value="b.id">
                {{ b.mode_name }}
              </option>
            </select>
          </div>
        </div> -->
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Currency
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="payment.currency_id"
            >
              <option v-for="(c, i) in currencies" :key="i" :value="c.id">
                {{ c.currency_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardLandOwnerPaymentsTable
            :color="'light'"
            :payments="payments"
            :isProcessing="isProcessing"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardLandOwnerPaymentsTable from "@/components/Tables/CardLandOwnerPaymentsTable";
// import CustomerSearch from "@/components/Search/CustomerSearch.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
export default {
  name: "LandOwnerPayments",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      payment: {
        land_owner_id: "",
        amount_paid: "",
        // payment_mode_id: "",
        currency_id: "",
      },
      payments: [],
      paymentModes: [],
      sites: [],
      currencies: [],
      properties: [],
      showForm: false,
      owners: [],
    };
  },
  validations() {
    return {
      payment: {
        land_owner_id: { required },
        amount_paid: { required },
        // payment_mode_id: { required },
        currency_id: { required },
      },
    };
  },
  components: {
    CardLandOwnerPaymentsTable,
    // CustomerSearch,
  },
  created() {},
  mounted() {
    this.getPaymentModes();
    this.getCurrencies();
    this.getPayments();
    this.getLandOwners();
  },
  methods: {
    assignCustomer(client) {
      this.payment.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.savePayment();
      }
    },
    getLandOwners() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-owner-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.owners = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPayments() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-owner-payments")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.payments = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPaymentModes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("payment-mode")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.paymentModes = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCurrencies() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("currencies")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.currencies = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    savePayment() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("make-landowner-payment", this.payment)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved", {
              duration: 800,
              className: "wk-success",
            });
            // _.renderPaymentVoucher(res.data.data);
            _.getPayments();

            let savedData = res.data.data.payment;
            let cashData = "";

            let momoData = "";

            let chequeNumber = "";

            let amountInWords = this.amountToWords(savedData.amount_paid);

            let currency_words = "";

            if (savedData.currency_name.toLowerCase() == "ghs") {
              currency_words = " Cedis";
            } else {
              currency_words = " Dollars";
            }

            let paymentReason = "Land Owner Payment";

            let values = {
              id: savedData.id,
              amount: savedData.amount_paid,
              date: savedData.created_at,
              cash: cashData,
              momo: momoData,
              cheque_number: chequeNumber,
              amount_in_words: amountInWords + currency_words,
              payment_reason: paymentReason,
              user_name: savedData.created_by,
              customer_name: savedData.name,
            };
            let invoiceParams = values;
            setTimeout(() => {
              _.$router.push({ path: "/invoice", query: invoiceParams });
            }, 500);
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    renderPaymentVoucher(savedData) {
      let _ = this;
      loadFile("/payment_receipt.docx", function (error, content) {
        try {
          if (error) {
            throw error;
          }
          var zip = new PizZip(content);
          var doc = new Docxtemplater(zip);

          let cashData = "";

          let momoData = "";

          let chequeNumber = "";

          let amountInWords = _.amountToWords(savedData.amount_paid);

          let paymentReason = "Payment For Property";

          doc.setData({
            id: savedData.id,
            amount: savedData.amount_paid,
            date: savedData.created_at,
            cash: cashData,
            momo: momoData,
            cheque_number: chequeNumber,
            amount_in_words: amountInWords,
            payment_reason: paymentReason,
            user_name: savedData.created_by,
            customer_name: savedData.name,
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            _.$wkToast(error, {
              duration: 3000,
              className: "wk-alert",
            });
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            /*eslint-disable*/
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function (
                  error,
                  key
                ) {
                  error[key] = value[key];
                  return error;
                },
                {});
              }
              return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));
            /*eslint-enable*/

            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join("\n");
              /*eslint-disable*/
              console.log("errorMessages", errorMessages);
              /*eslint-enable*/
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          var out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }); //Output the document using Data-URI
          let fullname = savedData.name;
          fullname = fullname.replace(/\s+/g, "_");
          // let business_name = _.approval.loan_application.operator.business_name
          // business_name = business_name.replace(/\s+/g, '_');
          saveAs(out, fullname + "_" + "Payment_Receipt.docx");
          _.$wkToast("Payment Receipt Issued!", {
            duration: 3000,
            className: "wk-success",
          });
          _.isProcessing = false;
        } catch (error) {
          /*eslint-disable*/
          console.log(error);
          /*eslint-enable*/
          _.$wkToast(error, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
          return;
        }
      });
    },
    amountToWords(amount) {
      if (!amount) return "";
      var converter = require("number-to-words");
      return this.captializeText(converter.toWords(amount));
    },
    captializeText(value) {
      if (!value) return "";
      // value = value.toString()
      // return value.charAt(0).toUpperCase() + value.slice(1)

      let capitalized = [];
      value.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
    emptyEntry() {
      this.payment = {
        land_owner_id: "",
        amount_paid: "",
        // payment_mode_id: "",
        currency_id: "",
      };
    },
  },
};
</script>
