<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded light"
  >
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Name
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Notes
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, i) in leaveCategories" :key="i">
          <th
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              flex
              items-center
            "
          >
            <span
              class="ml-3 font-bold"
              :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
            >
              {{ p.category_name }}
            </span>
          </th>

          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ p.category_notes }}
          </td>
          <!-- <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span v-if="p.active == 1">Active</span>
            <span v-else>Inactive</span>
          </td> -->
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <a
              href="#"
              @click.prevent="deleteLeaveCategory(p)"
              v-if="!isProcessing && $store.state.department == $store.state.storeDepartments.superAdmin"
              class="text-red-500 mr-4"
              type="button"
            >
              <i class="fas fa-trash"></i> Delete
            </a>
            <!-- <a
              href="#"
              @click.prevent="updateLeaveCategory(p)"
              v-if="!isProcessing"
              class="text-lightBlue-400"
              type="button"
            >
              <i class="fas fa-edit"></i> Edit
            </a> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    leaveCategories: {
      type: Array,
    },
    isProcessing: {
      type: Boolean,
    },
  },
  methods: {
    deleteLeaveCategory(obj) {
      this.$emit("delete-category", obj);
    },
    updateLeaveCategory(obj) {
      this.$emit("update-category", obj);
    },
  },
};
</script>
