<template>
  <div>
    <div class="relative w-full lg:w-3/12 px-3 mb-3">
      <CustomerSearch @select-client="assignCustomer" />
    </div>
    <CardCustomerConvo
      v-if="showModal"
      :isEdit="isEdit"
      :convoToUpdate="convoToUpdate"
      :selectedCustomer="selectedCustomer"
      @toggle-modal="toggleModal"
      @get-convo="getConvo"
      @clearData="clearData"
    />
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4 overflow-auto">
        <CardCustomerConvoTable
          :customerConvos="customerConvos"
          :isProcessing="isProcessing"
          :isGetting="isGetting"
          @toggleModal="toggleModal"
          @update-convo="updateConvo"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CardCustomerConvoTable from "@/components/Tables/CardCustomerConvoTable.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import CardCustomerConvo from "@/components/Cards/CardCustomerConvo.vue";
import CustomerSearch from "@/components/Search/CustomerSearch.vue";

export default {
  name: "CustomerConvo",
  components: {
    CardCustomerConvoTable,
    CardCustomerConvo,
    CustomerSearch,
  },
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      isGetting: false,
      isEdit: false,
      customerConvos: [],
      convo: {
        customer_id: "",
        reason_for_call: "",
        description: "",
      },
      convoToUpdate: {},
      enquiryTypes: [],
      enquiryStatus: [],
      showModal: false,
      selectedCustomer: {},
    };
  },
  validations() {
    return {
      enquiry: {
        customer_id: { required },
        reason_for_call: { required },
        description: { required },
      },
    };
  },
  mounted() {},
  methods: {
    assignCustomer(customer) {
      this.convo.customer_id = customer.id;
      this.selectedCustomer = customer;
      setTimeout(() => {
        this.getConvo(customer.id);
      }, 500);
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    validateEntry() {
      this.v$.$validate();
      if (this.v$.error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveConvo();
      }
    },
    saveConvo() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-convo-store", this.convo)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved!", {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
            _.clearData();
            _.showModal = false;
            _.getConvo();
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getConvo(id) {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customer-convo/" + id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            setTimeout(() => {
              _.customerConvos = res.data.data;
            }, 500);
            _.convoToUpdate = {};
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          setTimeout(() => {
            _.isGetting = false;
          }, 500);
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    updateConvo(enquiry) {
      this.convoToUpdate = enquiry;
      // this.emitter.emit("emitEnquiry", enquiry);
      this.showModal = true;
      this.isEdit = true;
    },
    clearData() {
      this.convo = {
        customer_id: "",
        reason_for_call: "",
        description: "",
      };
      this.convoToUpdate = {};
      this.isEdit = false;
    },
  },
};
</script>
