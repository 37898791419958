<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
      bg-white
      px-4
      py-4
      transition-all
      duration-150
    "
  >
    <form class="flex flex-wrap px-3 py-3">
      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Title
        </label>
        <select
          v-model="employee.employee_title_id"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option v-for="(id, i) in titles" :key="i" :value="id.id">
            {{ id.title_name }}
          </option>
        </select>
      </div>
      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Designation
        </label>
        <select
          v-model="employee.designation_id"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option v-for="(id, i) in designations" :key="i" :value="id.id">
            {{ id.designation_name }}
          </option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Surname
        </label>
        <input
          v-model="employee.surname"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
          placeholder="Surname"
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Other Names
        </label>
        <input
          v-model="employee.other_names"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
          placeholder="Other Names"
        />
      </div>
      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Gender
        </label>
        <select
          v-model="employee.gender_id"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option v-for="(id, i) in genders" :key="i" :value="id.id">
            {{ id.gender }}
          </option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Date of birth
        </label>
        <input
          v-model="employee.dob"
          type="date"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Place of Birth
        </label>
        <input
          v-model="employee.place_of_birth"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>
      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Country
        </label>
        <select
          v-model="employee.country_id"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option v-for="(id, i) in countries" :key="i" :value="id.id">
            {{ id.country_name }}
          </option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          ID type
        </label>
        <select
          v-model="employee.id_type"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option v-for="(id, i) in identifications" :key="i" :value="id.id">
            {{ id.id_name }}
          </option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          ID number
        </label>
        <input
          v-model="employee.id_number"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
          placeholder="ID number"
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Religion
        </label>
        <select
          v-model="employee.religion_id"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option v-for="(id, i) in religions" :key="i" :value="id.id">
            {{ id.religion }}
          </option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Residential Address
        </label>
        <input
          v-model="employee.residential_address"
          type="email"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
          placeholder="Address"
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Town
        </label>
        <input
          v-model="employee.town"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
          placeholder="Town"
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Land Mark
        </label>
        <input
          v-model="employee.land_mark"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
          placeholder="Land Mark"
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Contact Phone
        </label>
        <input
          v-model="employee.contact_phone"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Email
        </label>
        <input
          v-model="employee.email"
          type="email"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
          placeholder="Email"
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Illness/Disability
        </label>
        <select
          v-model="employee.illness_disability"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Illness/Durability Note
        </label>
        <input
          v-model="employee.illness_note"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Illness/Durability Note
        </label>
        <input
          v-model="employee.illness_note"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Marital Status
        </label>
        <select
          v-model="employee.marital_status_id"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option v-for="(b, i) in maritalStatus" :key="i" :value="b.id">
            {{ b.marital_status }}
          </option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Name of Spouse
        </label>
        <input
          v-model="employee.name_of_spouse"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Number of Children
        </label>
        <input
          v-model="employee.no_of_children"
          type="number"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Address
        </label>
        <input
          v-model="employee.address"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Father Name
        </label>
        <input
          v-model="employee.father_name"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Father Address
        </label>
        <input
          v-model="employee.father_address"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Father Alive?
        </label>
        <select
          v-model="employee.father_alive"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Father Phone
        </label>
        <input
          v-model="employee.father_phone"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Mother Name
        </label>
        <input
          v-model="employee.mother_name"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Mother Address
        </label>
        <input
          v-model="employee.mother_address"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Mother Alive?
        </label>
        <select
          v-model="employee.mother_alive"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Mother Phone
        </label>
        <input
          v-model="employee.mother_phone"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Professional Body?
        </label>
        <select
          v-model="employee.prof_body"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        >
          <option>---</option>
          <option value="1">Yes</option>
          <option value="0">No</option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Professional Body Name
        </label>
        <input
          v-model="employee.prof_body_name"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Professional Body Contact
        </label>
        <input
          v-model="employee.prof_body_contact"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Emergency Contact Name
        </label>
        <input
          v-model="employee.emergency_contact_name"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Relation to Emergency Contact
        </label>
        <input
          v-model="employee.relation_to_emergency_contact"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Emergency Contact Phone
        </label>
        <input
          v-model="employee.emergency_contact_tel"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Emergency Contact Email
        </label>
        <input
          v-model="employee.emergency_contact_email"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        />
      </div>

      <div class="relative w-full lg:w-3/12"></div>
      <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
        <button
          @click="cancelClose()"
          class="
            bg-blueGray-800
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Cancel &amp; Close
        </button>
      </div>

      <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
        <button
          @click="validateEntry()"
          class="
            bg-lightBlue-500
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Register
        </button>
      </div>

      <div v-if="isProcessing" class="relative w-full lg:w-12/12 px-3 mt-3">
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
export default {
  name: "CardCustomerRegistration",
  props: {
    countries: {
      type: Array,
    },
    identifications: {
      type: Array,
    },
    genders: {
      type: Array,
    },
    titles: {
      type: Array,
    },
    maritalStatus: {
      type: Array,
    },
    religions: {
      type: Array,
    },
    designations: {
      type: Array,
    },
    selectedEmployee: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
    },
    referrals: {
      type: Array,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      employee: {
        employee_title_id: "",
        designation_id: "",
        surname: "",
        other_names: "",
        gender_id: "",
        dob: "",
        place_of_birth: "",
        country_id: "",
        id_type: "",
        id_number: "",
        religion_id: "",
        residential_address: "",
        town: "",
        land_mark: "",
        contact_phone: "",
        email: "",
        illness_disability: "",
        illness_note: "",
        marital_status_id: "",
        name_of_spouse: "",
        no_of_children: "",
        address: "",
        father_name: "",
        father_address: "",
        father_alive: "",
        father_phone: "",
        mother_name: "",
        mother_address: "",
        mother_alive: "",
        mother_phone: "",
        prof_body: "",
        prof_body_name: "",
        prof_body_contact: "",
        emergency_contact_name: "",
        relation_to_emergency_contact: "",
        emergency_contact_tel: "",
        emergency_contact_email: "",
      },
      customerToEdit: {},
      users: [],
    };
  },
  validations() {
    return {
      employee: {
        employee_title_id: { required },
        designation_id: { required },
        surname: { required },
        other_names: { required },
        gender_id: { required },
        dob: { required },
        place_of_birth: { required },
        country_id: { required },
        id_type: { required },
        id_number: { required },
        religion_id: { required },
        residential_address: { required },
        town: {},
        land_mark: {},
        contact_phone: { required },
        email: { required },
        illness_disability: { required },
        illness_note: {},
        marital_status_id: { required },
        name_of_spouse: {},
        no_of_children: {},
        address: {},
        father_name: { required },
        father_address: {},
        father_alive: { required },
        father_phone: {},
        mother_name: { required },
        mother_address: {},
        mother_alive: { required },
        mother_phone: {},
        prof_body: { required },
        prof_body_name: {},
        prof_body_contact: {},
        emergency_contact_name: { required },
        relation_to_emergency_contact: { required },
        emergency_contact_tel: { required },
        emergency_contact_email: {},
      },
      customerToEdit: {
        name: { required },
        email: {
          email,
        },
        dob: {
          required,
        },
        phone_number: {
          required,
        },
        id_type: {
          required,
        },
        id_number: {
          required,
        },
        country_id: {
          required,
        },
        branch_id: {
          required,
        },
        source_of_contact: {},
      },
    };
  },
  components: {},
  created() {
    // this.emitter.on("view-customer-details", (data) => {
    //   this.customer = data.customer;
    // });
  },
  mounted() {
    this.company = this.$store.getters.getCompanyInfo;
    // this.getEmployees();
  },
  methods: {
    getUsers() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("users/paginate/no", this.user)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.users = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    cancelClose() {
      this.employee = {
        employee_title_id: "",
        designation_id: "",
        surname: "",
        other_names: "",
        gender_id: "",
        dob: "",
        place_of_birth: "",
        country_id: "",
        id_type: "",
        id_number: "",
        religion_id: "",
        residential_address: "",
        town: "",
        land_mark: "",
        contact_phone: "",
        email: "",
        illness_disability: "",
        illness_note: "",
        marital_status_id: "",
        name_of_spouse: "",
        no_of_children: "",
        address: "",
        father_name: "",
        father_address: "",
        father_alive: "",
        father_phone: "",
        mother_name: "",
        mother_address: "",
        mother_alive: "",
        mother_phone: "",
        prof_body: "",
        prof_body_name: "",
        prof_body_contact: "",
        emergency_contact_name: "",
        relation_to_emergency_contact: "",
        emergency_contact_tel: "",
        emergency_contact_email: "",
      };
      this.customerToEdit = {
        name: "",
        dob: "",
        phone_number: "",
        address: "",
        tin: "",
        id_type: "",
        id_number: "",
        country_id: "",
        email: "",
        digital_address: "",
        residential_permit: "",
        branch_id: "",
        sales_rep: "",
        source_of_contact: "",
      };
      this.toggleModal();
      this.$emit("clear-selected-employee");
    },
    toggleModal() {
      this.$emit("toggle-modal");
    },
    emptyCustomer() {
      this.customer = {
        name: "",
        dob: "",
        phone_number: "",
        address: "",
        tin: "",
        id_type: "",
        id_number: "",
        country_id: "",
        email: "",
        digital_address: "",
        residential_permit: "",
        branch_id: "",
        sales_rep: "",
        source_of_contact: "",
      };
    },
    validateEntry() {
      this.v$.customer.$reset();
      this.v$.customer.$validate();
      if (this.v$.customer.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.register();
      }
    },
    validateEntryToEdit() {
      this.v$.customerToEdit.$reset();
      this.v$.customerToEdit.$validate();
      if (this.v$.customerToEdit.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.updateCustomer();
      }
    },
    register() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("employee-store", this.employee)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.cancelClose();
            _.$emit("get-employees");
            _.$wkToast("Great! Employee Added.", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    updateCustomer() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .put("employee-store", this.selectedCustomer)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.cancelClose();
            _.$emit("get-customers");
            _.$wkToast("Great! Customer Added.", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
  watch: {
    selectedEmployee() {
      if (this.isEdit) {
        this.employee = this.selectedEmployee;
      }
      // else {
      //   this.emptyCustomer();
      // }
    },
  },
};
</script>
