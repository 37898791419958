<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded light" id="jobApplications">
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th v-if="list == 1" class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            <input v-model="selectAll" type="checkbox" name="" id="">
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Applicant
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Date of Birth
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Email
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Date Applied
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Years of Experience
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Highest Education
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, i) in jobApplications" :key="i">
          <th v-if="list == 1" class="
              px-6
              align-middle
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            ">
            <input v-model="selectedJobApplications" :value="p.id" type="checkbox"
              :checked="selectedJobApplications.includes(p.id)" name="" id="">
          </th>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ p.full_name }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ $filters.cleanDate(p.dob) }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ p.email_address }}
          </td>

          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ $filters.cleanDate(p.created_at) }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ p.years_of_experience }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ p.highest_education }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ p.other_qualificaation }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedJobApplications: [],
      selectAll: false,
      isMutating: false,
    };
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    list: {
      type: Number,
    },
    jobApplications: {
      type: Object,
      default: () => ({}),
    },
    isProcessing: {
      type: Boolean,
    },
  },
  created() {},
  mounted() {
    this.emitter.on("clearSelectedApplicants", () => {
      this.selectedJobApplications = []
      this.selectAll = false
    })
  },
  methods: {
    printReport() {
      this.$htmlToPaper("periodicSalesReport");
    },
    updatePrevEmp(obj) {
      this.$emit("update-prevEmp", obj);
    },
  },
  watch: {
    selectedJobApplications() {
      this.$emit('shortlistArray', this.selectedJobApplications)
    },
    selectAll() {
      let newList = this.selectedJobApplications
      if (this.selectAll) {
        this.jobApplications.forEach(element => {
          if (!newList.includes(element.id)) {
            newList.push(element.id)
          }
        });
        this.$emit('shortlistArray', newList)
      } else {
        newList = []
        this.selectedJobApplications = newList
        this.$emit('shortlistArray', newList)
      }
    }
  }
};
</script>
