<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      isLoggedIn: this.$store.getters.getLoggedInStatus,
      isGetting: false,
    };
  },
  components: {},
  created() {
    if (
      localStorage.getItem("accessToken") == null &&
      localStorage.getItem("accessToken") == undefined
    ) {
      this.$router.push("/auth/login");
      this.$wkToast("Please Log In", {
        transition: "slide-up",
        duration: 1500,
      });
    } else {
      this.$router.push("/admin/dashboard");
      this.$wkToast("Welcome", {
        className: "wk-success",
        transition: "slide-up",
        duration: 1500,
      });
    }
  },
  mounted() {
    this.emitter.on("get-company-info", () => {
      this.getCompanyInfo();
    });
  },
  methods: {
    getCompanyInfo() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("company-info")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            let company = res.data.data;
            localStorage.setItem("companyInfo", JSON.stringify(company));
            _.$store.dispatch("setCompanyInfo", company);
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
  },
};
</script>

<style>
.card {
  overflow-x: scroll; 
}
td:last-of-type {
  overflow-x: visible;
}
.wk-wrapper {
  width: auto !important;
  max-width: 70vw !important;
  background-color: rgb(0, 0, 0) !important;
}
.wk-wrapper.wk-success {
  background-color: rgb(21, 130, 59) !important;
}
.wk-wrapper.wk-alert {
  background-color: rgb(225, 20, 20) !important;
}
.wk-wrapper.wk-warn {
  background-color: rgba(241, 151, 34, 0.9) !important;
}
.wk-wrapper.wk-info {
  background-color: rgba(32, 83, 201, 0.7) !important;
}
.bg-estate-masters {
  background-color: rgba(98, 20, 85, 1);
}
.text-estate-masters-500 {
  color: rgba(83, 25, 73, 0.7);
}
.text-estate-masters-600 {
  color: rgba(83, 25, 73, 0.9);
}
button {
  transition: ease-in-out;
}
.fa-spinner {
  /* duration: 360 * ARCTIME / (ARCSTARTROT + (360-ARCSIZE)) */
  -webkit-animation: container-rotate 1568ms linear infinite;
  animation: container-rotate 1568ms linear infinite;
}

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>