<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Customer Payments</h6>
        <button v-if="!isProcessing && !isGetting" @click="validateEntry()" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          " type="button">
          Make Payment
        </button>
        <button v-if="isProcessing || isGetting" class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          " type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Customer
            </label>
            <CustomerSearch :parsedCustomer="parsedCustomer" @select-client="assignCustomer" />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Amount Paid
            </label>
            <input v-model="payment.amount_paid" type="number" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Payment Mode
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="payment.payment_mode_id">
              <option v-for="(b, i) in paymentModes" :key="i" :value="b.id">
                {{ b.mode_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Payment Reference
            </label>
            <input v-model="payment.payment_reference" type="text" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Currency
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="payment.currency_id">
              <option v-for="(c, i) in currencies" :key="i" :value="c.id">
                {{ c.currency_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <hr>
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <div class="flex flex-wrap">
              <div class="w-full lg:w-4/12 px-2">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  Denomination
                </label>
                <input v-model="cashDenomination" type="number" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
              </div>
              <div class="w-full lg:w-4/12 px-2">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  Count
                </label>
                <input v-model="cashCount" type="number" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
              </div>
              <div class="w-full lg:w-4/12">
                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                  Save to List
                </label>
                <button @click="addPaymentdetailsObjectToArray" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          " type="button">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
                    Denomination
                  </th>
                  <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
                    Count
                  </th>
                  <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(b, i) in payment.details" :key="i">
                  <th class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              flex
              items-center
            ">
                    <span class="ml-3 font-bold" :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']">
                      {{ b.denomination }}
                    </span>
                  </th>
                  <th class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              flex
              items-center
            ">
                    <span class="ml-3 font-bold" :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']">
                      {{ b.count }}
                    </span>
                  </th>
                  <th class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              flex
              items-center
            ">
                    <span class="ml-3 font-bold" :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']">
                      {{ b.total }}
                    </span>
                  </th>
                  <th class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              flex
              items-center
            ">
                    <span class="ml-3 font-bold" :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']">
                      <button @click="removePaymentDetails(b)" class="
            bg-red-500
            text-white
            active:bg-red-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          " type="button">
                        <i class="fa fa-trash"></i>
                      </button>
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardCustomerPaymentsTable :color="'light'" :payments="payments" :isProcessing="isProcessing" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardCustomerPaymentsTable from "@/components/Tables/CardCustomerPaymentsTable";
import CustomerSearch from "@/components/Search/CustomerSearch.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
export default {
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      payment: {
        customer_id: "",
        amount_paid: "",
        payment_mode_id: "",
        currency_id: "",
        payment_reference: "",
        details: []
      },
      payments: [],
      paymentModes: [],
      sites: [],
      currencies: [],
      properties: [],
      showForm: false,
      parsedCustomer: {},
      paymentDetails: {
        denomination: "",
        count: "",
        total: ""
      },
      cashDenomination: "",
      cashCount: "",
    };
  },
  props: {
    customerId: {
      default: "",
      type: String,
    },
    parsedSelectedCustomer: {
      type: Object,
      default: () => ({}),
    },
  },
  validations() {
    return {
      payment: {
        customer_id: { required },
        amount_paid: { required },
        payment_mode_id: { required },
        currency_id: { required },
        payment_reference: { required },
        details: { required }
      },
    };
  },
  components: {
    CardCustomerPaymentsTable,
    CustomerSearch,
  },
  created() { },
  mounted() {
    this.getPaymentModes();
    this.getCurrencies();
    if (!Object.keys(this.$route.query).length) {
      if (Object.keys(this.parsedSelectedCustomer).length > 0) {
        this.parsedCustomer = this.parsedSelectedCustomer;
        this.payment.customer_id = this.parsedSelectedCustomer.id;
      } else {
        this.getPayments();
      }
    } else {
      let parsedRouteCustomer = JSON.parse(this.$route.query.customer);
      this.parsedCustomer = parsedRouteCustomer;
      this.payment.customer_id = parsedRouteCustomer.id;
    }
  },
  methods: {
    assignCustomer(client) {
      this.payment.customer_id = client.id;
    },
    addPaymentdetailsObjectToArray() {
      let paymentDetails = {};
      paymentDetails.denomination = this.cashDenomination
      paymentDetails.count = this.cashCount
      paymentDetails.total = this.cashDenomination * this.cashCount
      this.payment.details.push(paymentDetails)
      this.cashDenomination = ""
      this.cashCount = ""
    },
    removePaymentDetails(el) {
      this.payment.details.splice(this.payment.details.indexOf(el), 1);
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.savePayment();
      }
    },
    getPayments() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customer-payment-account")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.payments = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPaymentModes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("payment-mode")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.paymentModes = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCurrencies() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("currencies")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.currencies = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    savePayment() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("make-customer-payment", this.payment)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved", {
              duration: 800,
              className: "wk-success",
            });
            // _.renderPaymentVoucher(res.data.data);
            _.getPayments();

            let savedData = res.data.data.payment;
            // let cashData = "";

            // let momoData = "";

            // let chequeNumber = "";

            let amountInWords = this.amountToWords(savedData.amount_paid);

            let currency_words = "";

            if (savedData.currency_name.toLowerCase() == "ghs") {
              currency_words = " Cedis";
            } else {
              currency_words = " Dollars";
            }

            let paymentReason = "Payment For Property";

            let values = {
              id: savedData.id,
              amount: savedData.amount_paid,
              date: savedData.created_at,
              paymentMode: this.retrievePaymentMode(savedData.payment_mode_id),
              paymentReference: savedData.payment_reference,
              amount_in_words: amountInWords + currency_words,
              payment_reason: paymentReason,
              user_name: savedData.created_by,
              customer_name: savedData.name,
            };
            let invoiceParams = values;
            setTimeout(() => {
              _.$router.push({ path: "/invoice", query: invoiceParams });
            }, 500);
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    renderPaymentVoucher(savedData) {
      let _ = this;
      loadFile("/payment_receipt.docx", function (error, content) {
        try {
          if (error) {
            throw error;
          }
          var zip = new PizZip(content);
          var doc = new Docxtemplater(zip);

          let cashData = "";

          let momoData = "";

          let chequeNumber = "";

          let amountInWords = _.amountToWords(savedData.amount_paid);

          let paymentReason = "Payment For Property";

          doc.setData({
            id: savedData.id,
            amount: savedData.amount_paid,
            date: savedData.created_at,
            cash: cashData,
            momo: momoData,
            cheque_number: chequeNumber,
            amount_in_words: amountInWords,
            payment_reason: paymentReason,
            user_name: savedData.created_by,
            customer_name: savedData.name,
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            _.$wkToast(error, {
              duration: 3000,
              className: "wk-alert",
            });
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            /*eslint-disable*/
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function (
                  error,
                  key
                ) {
                  error[key] = value[key];
                  return error;
                },
                  {});
              }
              return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));
            /*eslint-enable*/

            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join("\n");
              /*eslint-disable*/
              console.log("errorMessages", errorMessages);
              /*eslint-enable*/
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          var out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }); //Output the document using Data-URI
          let fullname = savedData.name;
          fullname = fullname.replace(/\s+/g, "_");
          // let business_name = _.approval.loan_application.operator.business_name
          // business_name = business_name.replace(/\s+/g, '_');
          saveAs(out, fullname + "_" + "Payment_Receipt.docx");
          _.$wkToast("Payment Receipt Issued!", {
            duration: 3000,
            className: "wk-success",
          });
          _.isProcessing = false;
        } catch (error) {
          /*eslint-disable*/
          console.log(error);
          /*eslint-enable*/
          _.$wkToast(error, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
          return;
        }
      });
    },
    amountToWords(amount) {
      if (!amount) return "";
      var converter = require("number-to-words");
      return this.captializeText(converter.toWords(amount));
    },
    captializeText(value) {
      if (!value) return "";
      // value = value.toString()
      // return value.charAt(0).toUpperCase() + value.slice(1)

      let capitalized = [];
      value.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
    emptyEntry() {
      this.payment = {
        customer_id: "",
        amount_paid: "",
        payment_mode_id: "",
        currency_id: "",
      };
    },
    retrievePaymentMode(id) {
      let response = "";
      switch (id) {
        case 1:
          response = "Cash";
          break;
        case 2:
          response = "Cheque";
          break;
        case 3:
          response = "Bank Transfer";
          break;
        case 4:
          response = "Mobile Money";
          break;

        default:
          break;
      }

      return response;
    },
  },
  watch: {
  }
};
</script>
