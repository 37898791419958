<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex">
        <div class="w-full lg:w-6/12 px-4 text-left">
          <h6 class="text-blueGray-700 text-xl font-bold">
            Customer Payment Options
          </h6>
        </div>
        <div class="w-full lg:w-6/12 px-4 text-right">
          <button v-if="!showForm" @click="showForm = true" class="
              bg-emerald-500
              text-white
              active:bg-emerald-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              ml-2
              ease-linear
              transition-all
              duration-150
            " type="button">
            Add Payment Option
          </button>
          <button v-if="showForm" @click="cancelClose" class="
              bg-black
              text-white
              active:bg-black
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              ease-linear
              transition-all
              duration-150
              ml-2
            " type="button">
            Cancel &amp; Close
          </button>
          <button v-if="showForm && !isProcessing && !isGetting" @click="validateEntry()" class="
              bg-lightBlue-500
              text-white
              active:bg-lightBlue-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              ml-2
              ease-linear
              transition-all
              duration-150
            " type="button">
            Make Payment
          </button>
          <button v-if="isProcessing || isGetting" class="
              bg-emerald-500
              text-white
              active:bg-emerald-600
              font-bold
              uppercase
              text-small
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              ml-2
              ease-linear
              transition-all
              duration-150
            " type="button">
            <i class="fas fa-spinner"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="px-2">
      <div v-show="showForm" class="px-2 mb-2 mt-2 flex flex-wrap">
        <!-- <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Purchase Type
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="option.purchase_type_id"
            >
              <option v-for="(b, i) in purchaseTypes" :key="i" :value="b.id">
                {{ b.purchase_type }}
              </option>
            </select>
          </div>
        </div> -->
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Customer Property
            </label>
            <!-- <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="option.customer_property_id"
            >
              <option v-for="(b, i) in properties" :key="i" :value="b.id">
                {{ `${b.name} - ${b.branch_name} - ${b.site_name}` }}
              </option>
            </select> -->
            <p>
              {{
                `${foundCustomerProperty.actual_buyer_name} [${foundCustomerProperty.name}] -
                            ${foundCustomerProperty.branch_name} - ${foundCustomerProperty.site_name}`
              }}
            </p>
          </div>
        </div>
        <div class="d-none w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Customer
            </label>
            <CustomerSearch :parsedCustomer="parsedCustomer" @select-client="assignCustomer" />
          </div>
        </div>
        <!-- <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Land Range From
            </label>
            <input
              v-model="option.land_range_from"
              type="number"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Land Range To
            </label>
            <input
              v-model="option.land_range_to"
              type="number"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div> -->
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Payment Mode
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="selectedPaymentMode">
              <option v-for="(b, i) in paymentModes" :key="i" :value="b">
                {{ b.mode_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Payment Reference
            </label>
            <input v-model="option.payment_reference" type="text" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Next Month Due
            </label>
            <input v-model="option.next_month_due" type="date" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div v-show="Object.keys(parsedRecord).length > 0 && parsedRecord.discount == null" class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Property Cost
            </label>
            <input v-model="option.property_cost" type="number" readonly class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div v-show="Object.keys(parsedRecord).length > 0 && parsedRecord.discount != null" class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Property Cost
            </label>
            <input v-model="parsedRecord.costAfterDiscount" type="number" readonly class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div v-if="Object.keys(parsedRecord).length > 0 && parsedRecord.discount != null" class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Discount Applied
            </label>
            <label class="block uppercase text-blueGray-600 text-lg font-bold mb-2">
              <span>{{ parsedRecord.discount }}</span>
              <span v-if="parsedRecord.discountType == 'percentage'">%</span>
              <span v-if="parsedRecord.discountType == 'amount'">.00</span>
            </label>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Initial Deposit
            </label>
            <input v-model="option.initial_deposit" type="text" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Currency
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="option.currency_id">
              <option v-for="(c, i) in currencies" :key="i" :value="c.id">
                {{ c.currency_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="openDenominationDisplay">
        <hr>
        <p class="pl-4 ml-2">Cash Details</p>

        <div class="px-2 mb-2 mt-2 flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <div class="flex flex-wrap">
                <div class="w-full lg:w-4/12 px-2">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    Denomination
                  </label>
                  <input v-model="cashDenomination" type="number" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
                </div>
                <div class="w-full lg:w-4/12 px-2">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    Count
                  </label>
                  <input v-model="cashCount" type="number" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
                </div>
                <div class="w-full lg:w-4/12">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                    Save to List
                  </label>
                  <button @click="addPaymentdetailsObjectToArray" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          " type="button">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <table class="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
                      Denomination
                    </th>
                    <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
                      Count
                    </th>
                    <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
                      Total
                    </th>
                    <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(b, i) in option.details" :key="i">
                    <th class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
                      <span class="ml-3 font-bold">
                        {{ b.denomination }}
                      </span>
                    </th>
                    <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
                      <span class="ml-3 font-bold">
                        {{ b.count }}
                      </span>
                    </td>
                    <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
                      <span class="ml-3 font-bold">
                        {{ b.total }}
                      </span>
                    </td>
                    <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
                      <span class="ml-3 font-bold">
                        <button @click="removePaymentDetails(b)" class="
            bg-red-500
            text-white
            active:bg-red-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          " type="button">
                          <i class="fa fa-trash"></i>
                        </button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardCustomerPaymentOptionsTable :color="'light'" :options="options" :isProcessing="isProcessing"
            @delete-option="deletePaymentOption" @update-option="updatePaymentOption" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardCustomerPaymentOptionsTable from "@/components/Tables/CardCustomerPaymentOptionsTable";
import CustomerSearch from "@/components/Search/CustomerSearch.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
// import Docxtemplater from "docxtemplater";
// import PizZip from "pizzip";
// import PizZipUtils from "pizzip/utils/index.js";
// import { saveAs } from "file-saver";

// function loadFile(url, callback) {
//   PizZipUtils.getBinaryContent(url, callback);
// }
export default {
  name: "CustomerPaymentOptions",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isGettingPaymentOptions: false,
      isProcessing: false,
      isEdit: false,
      option: {
        customer_property_id: "",
        customer_id: "",
        payment_mode_id: "",
        next_month_due: "",
        property_cost: "",
        initial_deposit: "",
        currency_id: "",
        payment_reference: "",
        payment_terms_id: "",
        details: [],
        costAfterDiscount: ""
      },
      purchaseTypes: [],
      paymentModes: [],
      sites: [],
      currencies: [],
      properties: [],
      showForm: false,
      selectedProperty: {},
      parsedCustomer: {},
      parsedRecord: {},
      foundCustomerProperty: "",
      cashDenomination: "",
      cashCount: "",
      selectedPaymentMode: {},
      openDenominationDisplay: false
    };
  },
  validations() {
    return {
      option: {
        customer_property_id: { required },
        customer_id: { required },
        payment_mode_id: { required },
        next_month_due: { required },
        property_cost: { required },
        initial_deposit: { required },
        currency_id: { required },
        payment_reference: { required },
        payment_terms_id: {},
        details: {},
        costAfterDiscount: {},
      },
    };
  },
  components: {
    CardCustomerPaymentOptionsTable,
    CustomerSearch,
  },
  created() {
    // if (!Object.keys(this.$route.query).length) {
    //   this.getPaymentOptions();
    // } else {
    //   let record = JSON.parse(this.$route.query.record);
    //   this.option.customer_property_id = record.id;
    //   this.option.property_cost = record.property_cost;
    //   this.option.customer_id = record.customer_id;
    //   this.showForm = true;
    //   this.getPaymentOptions();
    // }
  },
  mounted() {
    this.getPurchaseTypes();
    this.getPaymentModes();
    this.getCurrencies();
    this.getProperties();
    if (!Object.keys(this.$route.query).length) {
      this.getPaymentOptions();
    } else {
      let record = JSON.parse(this.$route.query.record);
      console.log(record);
      this.parsedRecord = record;
      this.getCustomerSpecificProperty(
        this.parsedRecord.id,
        this.parsedRecord.customer_id
      );
      this.option.customer_property_id = this.parsedRecord.id;
      this.selectedProperty = this.parsedRecord.id;
      this.option.property_cost = this.parsedRecord.property_cost;
      this.option.customer_id = this.parsedRecord.customer_id;
      this.option.next_month_due = this.parsedRecord.next_due_payment;
      this.option.initial_deposit = this.parsedRecord.initial_deposit;
      this.option.payment_reference = this.parsedRecord.payment_reference;
      this.option.payment_terms_id = this.parsedRecord.payment_terms_id;
      this.option.costAfterDiscount = this.parsedRecord.costAfterDiscount;
      this.showForm = true;
      this.getPaymentOptions();
    }
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    assignCustomer(client) {
      this.option.customer_id = client.id;
    },
    addPaymentdetailsObjectToArray() {
      let paymentDetails = {};
      paymentDetails.denomination = this.cashDenomination
      paymentDetails.count = this.cashCount
      paymentDetails.total = this.cashDenomination * this.cashCount
      this.option.details.push(paymentDetails)
      this.cashDenomination = ""
      this.cashCount = ""
    },
    removePaymentDetails(el) {
      this.option.details.splice(this.option.details.indexOf(el), 1);
    },
    validateEntry() {
      if (!Object.keys(this.$route.query).length) {
        this.option.property_cost = this.selectedProperty.land_price;
      }
      if (Object.keys(this.selectedPaymentMode).length > 0) {
        this.option.payment_mode_id = this.selectedPaymentMode.id
      } else {
        this.$wkToast("Select Payment Method!", {
          duration: 3000,
          className: "wk-alert",
        });
      }
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        if (!this.openDenominationDisplay) {
          this.savePaymentOptions();
        } else {
          if (this.checkTotalDenominations()) {
            this.savePaymentOptions();
          } else {
            this.$wkToast("Total Denominations Not Equal To Amount Paid!", {
              duration: 3000,
              className: "wk-alert",
            });
          }
        }


        // let savedData = {
        //   id: "001",
        //   amount: this.option.initial_deposit,
        //   created_at: new Date(),
        //   created_by: "User One",
        //   name: "Mohammed",
        //   land_range_from: this.option.land_range_from,
        //   land_range_to: this.option.land_range_to,
        //   property_cost: this.option.property_cost,
        // };

        // this.renderPaymentVoucher(savedData);
      }
    },
    checkTotalDenominations() {
      let sum = 0
      let array = this.option.details
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        sum += element.total
      }

      if (Number(this.option.initial_deposit).toFixed(2) == Number(sum).toFixed(2)) {
        return true
      } else {
        return false
      }
    },
    getCustomerSpecificProperty(id, customer_id) {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customer/property/" + customer_id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.option.customer_property_id = id;
            // let element = {};
            let array = res.data.data;
            array.every((e) => {
              if (e.id == id) {
                _.foundCustomerProperty = e;
                return false;
              }
              return true;
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getProperties() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("property-details")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.properties = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPurchaseTypes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("purchase-types")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.purchaseTypes = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPaymentOptions() {
      let _ = this;
      if (this.isGettingPaymentOptions) {
        return;
      }
      this.isGetting = true;
      this.isGettingPaymentOptions = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("payment-options")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.options = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isGettingPaymentOptions = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
          _.isGettingPaymentOptions = false;
        });
    },
    getPaymentModes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("payment-mode")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.paymentModes = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCurrencies() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("currencies")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.currencies = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    savePaymentOptions() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("payment-option-store", this.option)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved", {
              duration: 800,
              className: "wk-success",
            });
            // _.renderPaymentVoucher(res.data.data);
            _.getPaymentOptions();
            _.emptyEntry();
            // _.emitter.emit("emit-receipt-values", res.data.data);

            let savedData = res.data.data;

            let amountInWords = this.amountToWords(
              savedData.payment.amount_paid
            );

            let currency_words = "";

            if (savedData.payment.currency_name.toLowerCase() == "ghs") {
              currency_words = " Ghana Cedis";
            } else {
              currency_words = " Dollars";
            }

            // let comparedProperty = {};

            // this.properties.every((e) => {
            //   if (e.id == savedData.property.customer_property_id) {
            //     comparedProperty = e;
            //     return false;
            //   }
            //   return true;
            // });

            let paymentReason =
              "Initial Payment For " +
              this.foundCustomerProperty.branch_name +
              " " +
              this.foundCustomerProperty.site_name +
              " " +
              this.foundCustomerProperty.purchase_usage +
              " " +
              this.foundCustomerProperty.demarcation_name +
              " with plot number(s) " +
              this.foundCustomerProperty.plot_numbers +
              ". Which Costs " +
              savedData.payment.currency_name +
              " " +
              savedData.property.property_cost;

            let finalDiscount = "";
            let discountedNewCost = "";
            if (savedData.propDets.discount != null) {
              if (savedData.propDets.discountType == 'percentage') {
                finalDiscount = savedData.propDets.discount + '%'
              } else {
                finalDiscount = savedData.payment.currency_name + ' ' + savedData.propDets.discount + '.00'
              }
              discountedNewCost = 'Discounted to ' + savedData.payment.currency_name + ' ' + savedData.propDets.costAfterDiscount
            }

            let values = {
              id: savedData.payment.id,
              currency: savedData.payment.currency_name,
              amount: Number(_.numberWithCommas(savedData.payment.amount_paid)).toFixed(2),
              date: savedData.payment.created_at,
              paymentMode: savedData.payment.payment_mode,
              paymentReference: savedData.payment_reference,
              amount_in_words: amountInWords + currency_words,
              payment_reason: paymentReason,
              customer_name: savedData.payment.name,
              teller: savedData.payment.creator,
              balance_after: savedData.payment.balance_after,
              cashDetails: JSON.stringify(savedData.payment_denominations),
              discount: finalDiscount,
              discountedCost: Number(_.numberWithCommas(discountedNewCost)).toFixed(2),
            };
            let invoiceParams = values;
            setTimeout(() => {
              _.$router.push({ path: "/invoice", query: invoiceParams });
            }, 500);
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    savePaymentOptionsNew() {
      let _ = this;
      this.isProcessing = true;
      this.option.property_cost = this.selectedProperty.land_price;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("payment-option-store", this.option)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved", {
              duration: 800,
              className: "wk-success",
            });
            _.getPaymentOptions();
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    deletePaymentOption(property) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("payment-option-delete/" + property.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getPaymentOptions();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updatePaymentOption(option) {
      this.option = option;
      this.isEdit = true;
    },
    emptyEntry() {
      this.option = {
        customer_property_id: "",
        customer_id: "",
        payment_mode_id: "",
        next_month_due: "",
        property_cost: "",
        initial_deposit: "",
        currency_id: "",
        payment_reference: "",
        details: [],
        costAfterDiscount: ""
      };
    },
    // renderPaymentVoucher(savedData) {
    //   let _ = this;
    //   loadFile("/payment_receipt.docx", function (error, content) {
    //     try {
    //       if (error) {
    //         throw error;
    //       }
    //       var zip = new PizZip(content);
    //       var doc = new Docxtemplater(zip);

    //       let cashData = "";

    //       let momoData = "";

    //       // let chequeNumber = "";

    //       let amountInWords = _.amountToWords(savedData.initial_deposit);

    //       let paymentReason =
    //         "Initial Payment For Land With Range From " +
    //         savedData.land_range_from +
    //         " To " +
    //         savedData.land_range_to +
    //         " Which Costs " +
    //         savedData.property_cost;

    //       doc.setData({
    //         id: savedData.id,
    //         amount: savedData.initial_deposit,
    //         date: savedData.created_at,
    //         cash: cashData,
    //         momo: momoData,
    //         cheque_number: chequeNumber,
    //         amount_in_words: amountInWords,
    //         payment_reason: paymentReason,
    //         user_name: savedData.created_by,
    //         customer_name: savedData.name,
    //       });
    //       try {
    //         // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
    //         doc.render();
    //       } catch (error) {
    //         _.$wkToast(error, {
    //           duration: 3000,
    //           className: "wk-alert",
    //         });
    //         // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
    //         /*eslint-disable*/
    //         function replaceErrors(key, value) {
    //           if (value instanceof Error) {
    //             return Object.getOwnPropertyNames(value).reduce(function (
    //               error,
    //               key
    //             ) {
    //               error[key] = value[key];
    //               return error;
    //             },
    //             {});
    //           }
    //           return value;
    //         }
    //         console.log(JSON.stringify({ error: error }, replaceErrors));
    //         /*eslint-enable*/

    //         if (error.properties && error.properties.errors instanceof Array) {
    //           const errorMessages = error.properties.errors
    //             .map(function (error) {
    //               return error.properties.explanation;
    //             })
    //             .join("\n");
    //           /*eslint-disable*/
    //           console.log("errorMessages", errorMessages);
    //           /*eslint-enable*/
    //           // errorMessages is a humanly readable message looking like this :
    //           // 'The tag beginning with "foobar" is unopened'
    //         }
    //         throw error;
    //       }
    //       var out = doc.getZip().generate({
    //         type: "blob",
    //         mimeType:
    //           "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //       }); //Output the document using Data-URI
    //       let fullname = savedData.name;
    //       fullname = fullname.replace(/\s+/g, "_");
    //       // let business_name = _.approval.loan_application.operator.business_name
    //       // business_name = business_name.replace(/\s+/g, '_');
    //       saveAs(out, fullname + "_" + "Payment_Receipt.docx");
    //       _.$wkToast("Payment Receipt Issued!", {
    //         duration: 3000,
    //         className: "wk-success",
    //       });
    //       _.isProcessing = false;
    //     } catch (error) {
    //       /*eslint-disable*/
    //       console.log(error);
    //       /*eslint-enable*/
    //       _.$wkToast(error, {
    //         duration: 3000,
    //         className: "wk-alert",
    //       });
    //       _.isProcessing = false;
    //       return;
    //     }
    //   });
    // },
    amountToWords(amount) {
      if (!amount) return "";
      var converter = require("number-to-words");
      return this.captializeText(converter.toWords(amount));
    },
    captializeText(value) {
      if (!value) return "";
      // value = value.toString()
      // return value.charAt(0).toUpperCase() + value.slice(1)

      let capitalized = [];
      value.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
    cancelClose() {
      this.showForm = false;
      this.emptyEntry();
    },
    retrievePaymentMode(id) {
      let response = "";
      switch (id) {
        case 1:
          response = "Cash";
          break;
        case 2:
          response = "Cheque";
          break;
        case 3:
          response = "Bank Transfer";
          break;
        case 4:
          response = "Mobile Money";
          break;

        default:
          break;
      }

      return response;
    },
  },
  computed: {
    computedNextMonthDue() {
      return this.$filters.nextMonthDate();
    },
  },
  watch: {
    selectedPaymentMode() {
      if (Object.keys(this.selectedPaymentMode).length > 0) {
        if (this.selectedPaymentMode.mode_name.toLowerCase() == 'cash') {
          this.openDenominationDisplay = true
        } else {
          this.openDenominationDisplay = false;
          this.option.details = []
          this.cashDenomination = ""
          this.cashCount = ""
        }
      }
    },
    // selectedProperty() {
    //   if (!Object.keys(this.selectedProperty).length) {
    //     return;
    //   } else {
    //     this.option.customer_property_id = this.selectedProperty.id;
    //     this.option.customer_id = this.selectedProperty.customer_id;
    //   }
    // },
    // parsedRecord() {
    //   if (!Object.keys(this.parsedRecord).length) {
    //     return;
    //   } else {
    //     this.option.customer_property_id = this.parsedRecord.id;
    //     this.option.property_cost = this.parsedRecord.property_cost;
    //     this.option.customer_id = this.parsedRecord.customer_id;
    //   }
    // },
  },
};
</script>

<style>
.d-none {
  display: none;
}
</style>