<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12">
        <div class="flex flex-wrap px-2">
          <div class="w-full md:w-12/12 lg:w-6/12">
            <div class="w-full lg:w-12/12 pb-2">
              <h6 class="pl-3 text-blueGray-700 text-xl font-bold">Job Applicant Interview Evaluation</h6>
            </div>
            <div class="flex flex-wrap px-3">
              <div class="w-full lg:w-12/12">
                <ShortlistedApplicantsSearch @select-applicant="assignApplicant" />
              </div>
              <div class="w-full lg:w-12/12">
                <CardBio v-if="Object.keys(selectedApplicant).length > 0" :applicant="selectedApplicant" />
              </div>
            </div>
          </div>
          <div class="w-full md:w-12/12 lg:w-6/12">
            <InterviewEvaluationForm v-if="Object.keys(selectedApplicant).length > 0" :applicant="selectedApplicant" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ShortlistedApplicantsSearch from "@/components/Search/ShortlistedApplicantsSearch.vue";
import CardBio from "@/components/Cards/CardJobApplicantBio.vue";
import InterviewEvaluationForm from "@/components/Cards/CardInterviewEvaluationForm.vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: "EvaluateApplicant",
  components: {
    ShortlistedApplicantsSearch,
    CardBio,
    InterviewEvaluationForm,
  },
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      isGetting: false,
      customers: [],
      isEdit: false,
      isGettingApplicant: false,
      page: 0,
      user: {
        name: "",
        type: "1",
        email: "",
        password: "",
        password_confirmation: "",
      },
      showModal: false,
      countries: [],
      identifications: [],
      branches: [],
      referrals: [],
      selectedApplicant: {},
      transactions: [],
      bio: {},
      properties: [],
      transactionsSum: "",
    };
  },
  created() {
    // this.emitter.on("view-customer-details", (data) => {
    //   this.selectedApplicant = data;
    //   this.showModal = true;
    // });
  },
  mounted() { },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    clearSelectedApplicant() {
      this.selectedApplicant = {};
      this.isEdit = false;
    },
    viewSelectedApplicant(data) {
      this.selectedApplicant = data;
      this.isEdit = true;
      this.showModal = true;
    },
    assignApplicant(applicant) {
      this.selectedApplicant = applicant;
    },
    getReferrals() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("referrals")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.referrals = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCustomerReport() {
      if (this.isGettingApplicant) {
        return;
      }
      this.page = 0;
      this.isGettingApplicant = true;
      let _ = this;
      this.emptyData();
      let obj = {};
      obj.customer_id = this.selectedApplicant.id;
      if (!Object.keys(obj).length) {
        this.isGettingApplicant = false;
        this.getCustomerReport();
      }
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-report", obj)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.properties = res.data.data.property;
            _.transactions = res.data.data.transaction;
            let bioRes = res.data.data.customer_account;
            if (bioRes == null) {
              _.bio = {};
            } else {
              _.bio = res.data.data.customer_account;
            }
            _.transactionsSum = res.data.data.sum;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGettingApplicant = false;
        })
        .catch((err) => {
          _.isGettingApplicant = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getCountries() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("countries")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.countries = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getIdentifications() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("identifications")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.identifications = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    emptyData() {
      this.transactions = -[];
      this.bio = {};
      this.properties = [];
      this.transactionsSum = "";
    },
    saveDocumentation() { },
  },
};
</script>
