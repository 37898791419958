<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-lg
      rounded
    "
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Land Sites Sold
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            v-if="isProcessing"
            class="
              bg-indigo-500
              text-white
              active:bg-indigo-600
              text-xs
              font-bold
              uppercase
              px-3
              py-1
              rounded
              outline-none
              focus:outline-none
              mr-1
              mb-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
          >
            <i class="fas fa-spinner"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="thead-light">
          <tr>
            <th
              class="
                px-6
                bg-blueGray-50
                text-blueGray-500
                align-middle
                border border-solid border-blueGray-100
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
            >
              Land Site
            </th>
            <th
              class="
                px-6
                bg-blueGray-50
                text-blueGray-500
                align-middle
                border border-solid border-blueGray-100
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
            >
              Quantity
            </th>
            <th
              class="
                px-6
                bg-blueGray-50
                text-blueGray-500
                align-middle
                border border-solid border-blueGray-100
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
            >
              Left
            </th>
            <th
              class="
                px-6
                bg-blueGray-50
                text-blueGray-500
                align-middle
                border border-solid border-blueGray-100
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                min-w-140-px
              "
            >
              % Sold
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(s, i) in finalSites" :key="i">
            <th
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
              "
            >
              {{ s.site_name }}
            </th>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ s.plot_qty }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ s.total_plot_left }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              <div class="flex items-center">
                <span class="mr-2">{{ s.percentSold }}%</span>
                <div class="relative w-full">
                  <div
                    :class="
                      'overflow-hidden h-2 text-xs flex rounded ' +
                      s.percentColor +
                      '-200'
                    "
                  >
                    <div
                      :style="'width: ' + s.percentSold + '%'"
                      :class="
                        'shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ' +
                        s.percentColor +
                        '-500'
                      "
                    ></div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      finalSites: [],
    };
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    sites: {
      type: Array,
    },
    isProcessing: {
      type: Boolean,
    },
  },
  mounted() {},
  methods: {},
  watch: {
    sites() {
      if (this.sites.length > 0) {
        this.$emit("setisProcessing", true);
        this.sites.forEach((element) => {
          let percent =
            ((Number(element.plot_qty) - Number(element.total_plot_left)) /
              Number(element.plot_qty)) *
            100;
          let percentColor = "";
          if (percent < 5) {
            percentColor = "bg-red";
          } else if (percent >= 5 && percent <= 45) {
            percentColor = "bg-purple";
          } else if (percent > 45 && percent <= 60) {
            percentColor = "bg-purple";
          } else if (percent > 60) {
            percentColor = "bg-emerald";
          }

          element.percentSold = Number(percent).toFixed(1);
          element.percentColor = percentColor;
          this.finalSites.push(element);
        });
        this.$emit("setIsProcessing", false);
      }
    },
  },
};
</script>