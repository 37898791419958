<template>
  <div class="container">
    <!-- <navbar /> -->
    <main class="invoice">
      <div class="content">
        <p class="c36">
          <img :src="bg" class="height: 50px" alt="" /><span class="c34">PAYMENT RECEIPT</span>
        </p>
        <p class="c36">TO BE VALID THIS RECEIPT MUST BEAR COMPANY'S STAMP</p>
        <br>
        <p class="c21">
          <span class="c27">RECEIPT NO: </span><span class="c11">{{ values.id }}</span>
        </p>
        <table class="table denomination-table">
          <thead>
            <tr>
              <th style="width: 35%;" class="text-left">{{ values.date }}</th>
              <th class="text-right">TELLER: {{ values.teller }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>REF NO</td>
              <td>{{ values.paymentReference }}</td>
            </tr>
            <tr>
              <td>AMT IN FIGURES</td>
              <td>{{ values.currency }}&nbsp;{{ values.amount }}</td>
            </tr>
            <tr>
              <td>AMT IN WORDS</td>
              <td>{{ values.amount_in_words }} Only</td>
            </tr>
            <tr>
              <td>RECEIVED FROM</td>
              <td>{{ values.customer_name }}</td>
            </tr>
            <tr>
              <td>PAYMENT METHOD</td>
              <td>{{ values.paymentMode }}</td>
            </tr>
            <tr>
              <td>NARRATION</td>
              <td>{{ values.payment_reason }}
                <!-- &nbsp;<span v-if="values.discount != ''">{{ values.discountedCost }}</span> -->
              </td>
            </tr>
            <tr v-if="values.discount != ''">
              <td>DISCOUNT</td>
              <td>{{ values.discount }}</td>
            </tr>
            <tr>
              <td>OUTST. BALANCE</td>
              <td>{{ values.currency }}&nbsp;{{
                values.balance_after }}</td>
            </tr>
          </tbody>
        </table>
        <br>
        <h4 class="cash-title" v-if="values.cashDetails.length > 0">DENOMINATION BREAKDOWN</h4>
        <table class="table denomination-table" v-if="values.cashDetails.length > 0">
          <thead>
            <tr>
              <th>DENOMINATION</th>
              <th>COUNT</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(c, i) in values.cashDetails" :key="i">
              <td>{{ c.denomination }}</td>
              <td>{{ c.count }}</td>
              <td>{{ c.total }}</td>
            </tr>
          </tbody>
        </table>
        <br>
        <br>
        <p class="c36">Stamp/Signature: </p>
      </div>
    </main>
    <!-- <footer-component /> -->
    <div class="contract row">
      <div class="column">
        <h4>T&amp;C</h4>
        <p>
          This Contract of Sale is made this date (as stated above) between
          ESTATE MASTERS LTD (hereinafter called “the Lessor ”), which
          expression shall where the context so admits or requires include its
          heirs, agents, successors, representatives or assigns) a limited
          liability company duly registered under the laws of the Republic of
          Ghana, represented by Mr. Francis Nesta Opey of P. O. Box CO 4279,
          Tema of the one part and client named with address details above
          (hereinafter referred to as ‘Lessee’ which expression shall where the
          context so admits or requires include its heirs, agents, successors,
          representatives or assigns) of the other part
        </p>
        <h4>WHEREAS;</h4>
        <p>
          a. The Lessor is the legal and beneficial owner of all that piece and
          parcel of property found and situate in the location aforestated and
          particularly described in the schedule and site plan to be attached to
          leasehold agreement. b. The Lessor has offered to lease and the lessee
          has offered to take lease of this property (number of properties as
          indicated overleaf - hereinafter called ‘the Property’) as per the
          site plan to be attached to the lease agreement, for a period of
          EIGHTY (80) Years commencing from the execution of the lease agreement
          which shall be entered into after the full and final payment of the
          purchase price has been made. c. The Parties hereto have agreed to
          enter into this Contract of Sale (hereinafter called “the Agreement”)
          to set forth the terms and conditions of the sale.
        </p>
        <h4>NOW THEREFORE IT IS AGREED AS FOLLOWS:</h4>
        <p class="underline">1. Construction</p>
        <p>
          1.1 The recitals form part of this Agreement and shall have the same
          force and effect as if set out in the body of this Agreement.
        </p>
        <p>
          1.2 The headings are inserted for convenience only and shall not
          affect the construction of this Agreement.
        </p>
        <p class="underline">2. Purchase Price and terms</p>
        <p>
          The Lessor shall lease and the lessee shall take lease of the Property
          together with all the rights, title and interest of the lessor at the
          price and term as aforestated
        </p>
        <p class="underline">3. Penalty</p>
        <p>
          Penalty of 5% would be charged on the balance at every first day of a
          new month for defaulted payments.
        </p>

        <p class="underline">4. Possession</p>
        <p>
          4.1 The lessor shall give vacant possession of the Property to the
          lessee after the lessee has paid up to 75% of the purchase price
          stated herein.
        </p>
        <p>4.2 At 50% payment the lessee will be shown the property.</p>
        <p>
          4.3 The lessee shall only be allowed to start any project on the
          property when full payment is made and the indenture given to him/her
        </p>

        <p class="underline">5. Completion</p>
        <p>
          5.1 The sale and purchase of the Property shall be completed after the
          lessee pays the full and final purchase price to the lessor.
        </p>
        <p>
          5.2 All costs and expenses to be incurred to procure the transfer of
          the lessor’s title in the Property to the lessee shall be borne by the
          lessee.
        </p>
        <p>
          5.3 The cost of provision of amenities on the sites shall be borne by
          the lessee. Lessee shall make full payment of amenities to lessor
          before start of construction on the property. NB: See appendix for
          provisional costs of amenities
        </p>
        <p>
          5.4 A lease agreement (indenture) shall be executed between the
          parties after the full and final payment of the purchase price has
          been made.
        </p>

        <p class="underline">6. Project execution</p>
        <p>
          6.1 The lessee shall construct a fence wall around the property within
          6 months and construction of main project shall be completed or
          ongoing within 3 years of receipt of leasehold agreement.
        </p>
        <p>
          6.2 Lessee shall obtain building permits from lessor pending
          commencement of any project on the property. All projects shall be
          monitored by the construction department/subsidiary of lessor
        </p>
        <p>
          6.3 Residential properties shall in no wise be used for other purposes
          without the prior written consent of the lessor and subsequent payment
          of the required fees.
        </p>
        <p>
          6.4 Any intension of the lessee to resell his/her property shall be
          made known to the lessor in writing 15 working days before same is
          allowed to be carried out. Lessee shall not use alien surveyors,
          planners on the property.
        </p>
      </div>
      <div class="column">
        <p class="underline">7. Warranty</p>
        <p>
          The lessor warrants that it has not sub-let, assigned, charged,
          mortgaged, or otherwise transferred or encumbered its right, title, or
          interest in the Property and hereby undertakes not to encumber its
          right, title or interest in the Property during the subsistence of
          this Agreement.
        </p>
        <p class="underline">8. Breach</p>
        <p>
          8.1 In the event of a breach of any provision of this Agreement, the
          party not in breach shall by notice in writing request the party in
          breach to remedy the breach within twenty (21) days of the receipt of
          such notice.
        </p>
        <p>
          8.2 Where a party in breach fails, refuses or neglects to remedy the
          breach within the notified period (as provided in sub clause 8.1
          above), the party not in breach shall be entitled to terminate or
          repudiate this contract or request specific performance.
        </p>
        <p>
          8.3 Where the lessor terminates this Agreement on default of the
          lessee in accordance with clause 8.2 herein, the lessor shall refund
          to the lessee all sums paid by the lessee less an amount equivalent to
          40%.
        </p>
        <p>
          8.4 Where a lessee on any installment payment plan fails for whatever
          reason to make the monthly payments due for three consecutive months,
          the lessor reserves the right to terminate this contract and apply the
          charges in clause 8.3 (and 8.5 if applicable) herein
        </p>
        <p>
          8.5 If at the time of the termination of this agreement in accordance
          with clause 8.3, leasehold agreement have already been prepared for
          the lessee, the cost incurred in preparation of these leasehold
          agreement shall be borne by the lessee exclusive of whatever other
          penalties may be levied.
        </p>
        <p>
          8.6 Where within ninety days after full payment of the purchase price
          by the lessee, the lessor for whatever reason is unable to provide the
          property for which the lessee has made full payment for, the lessee
          shall as in clause 8.1 herein request redress from the Lessor . NB:
          The lessor shall resort to a laid down resettlement plan of the
          company if within the said 90 days it’s unable to remedy the situation
          as described in 8.6
        </p>

        <!-- <p class="underline">9. Refund</p>
        <p>
          9.1 Where the lessee for whatever reason wants refund of any deposits
          or payments made in respect of any property, he/she shall do so in
          writing with the specific reasons to the lessor.
        </p>
        <p>
          9.2 The lessor in response shall state the specific date for the
          refund and shall do so in accordance with penalty terms as stated in
          clause 8.3.
        </p> -->

        <p class="underline">9. Waiver</p>
        <p>
          The waiver by a party of any term of this Agreement shall not prevent
          the subsequent enforcement of that term and shall not be deemed a
          waiver of any subsequent breach.
        </p>

        <p class="underline">10. Dispute Settlement</p>
        <p>
          Any dispute arising out of the interpretation and enforcement of this
          agreement shall be resolved with exclusive recourse to the provisions
          of the Alternative Dispute Resolution Act, 2010, Act 798.
        </p>

        <p class="underline">11. Security fees</p>
        <p>
          The lessor’s responsibility to protect lessee’s property ceases from
          the third month after full payment for the property is made save for a
          monthly payment of GHS 50 per property as security fees to the lessor
          from the expiration of the three months to enable the lessor provide
          continuous security for lessee’s property.
        </p>

        <p class="underline">12. Entire Agreement</p>
        <p>
          This Agreement embodies the entire understanding of the parties in
          respect of the matters contained or referred to in it and there are no
          promises, terms, conditions or obligations oral or written express or
          implied other than those contained in this Agreement.
        </p>

        <p class="underline">13. Notice</p>
        <p>
          Any notice given or to be given to either party to this contract shall
          be in writing and be delivered personally or via electronic mail to
          either party at the last known address.
        </p>

        <p class="underline">14. Force majeure</p>
        <p>
          Take note that portions of this agreement may change, be altered or
          supplemented intermittently, notice of which shall be communicated to
          the lessees through an electronic mail.
        </p>
      </div>
      <div class="bottom">
        <p class="sign">
          I/we,__________________________________________________________________________________________________________________
          have read and understood the above terms and conditions and hereby
          append my/our signature.
        </p>

        <p>
          Signature: _________________________
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Date:_________________________
        </p>

        <p class="appendix">
          <i><b>Appendix:</b> Provision of amenities means making water and
            electricity available at the closest proximity to clients’ property.
            The projected value for the provision of this amenities for all
            sites and branches are estimated at GHS5,000. Actual value would be
            arrived at at the commencement of clients’ construction activities
            on their respective properties.</i>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
// import Navbar from "@/components/Navbars/AuthNavbar.vue";
// import FooterComponent from "@/components/Footers/Footer.vue";

import bg from "@/assets/img/em_logo.png";

export default {
  data() {
    return {
      bg,
      values: {
        cashDetails: []
      },
    };
  },
  components: {
    // Navbar,
    // FooterComponent,
  },
  created() {
    // this.emitter.on("emit-receipt-values", (savedData) => {
    //   this.prepareReceipt(savedData);
    // });
    // this.emitter.on("emit-voucher-values", (savedData) => {
    //   this.prepareVoucher(savedData);
    // });
  },
  mounted() {
    let query = this.$route.query;
    // let printType = query.printType;
    // switch (printType) {
    //   case "receipt":
    //     this.prepareReceipt(query);
    //     break;
    //   case "voucher":
    //     this.prepareVoucher(query);
    //     break;
    // }
    this.preparePrint(query);
  },
  methods: {
    preparePrint(query) {
      this.values = query;
      this.values.cashDetails = JSON.parse(query.cashDetails)
      setTimeout(() => {
        window.print();
      }, 500);
    },
    prepareReceipt(savedData) {
      let cashData = "";

      let momoData = "";

      let chequeNumber = "";

      let amountInWords = this.amountToWords(savedData.initial_deposit);

      let paymentReason =
        "Initial Payment For Land With Range From " +
        savedData.land_range_from +
        " To " +
        savedData.land_range_to +
        " Which Costs " +
        savedData.property_cost;

      this.values = {
        id: savedData.id,
        amount: savedData.initial_deposit,
        date: savedData.created_at,
        cash: cashData,
        momo: momoData,
        cheque_number: chequeNumber,
        amount_in_words: amountInWords,
        payment_reason: paymentReason,
        user_name: savedData.created_by,
        customer_name: savedData.name,
      };
      window.print();
    },
    prepareVoucher(savedData) {
      let cashData = "";

      let momoData = "";

      let chequeNumber = "";

      let amountInWords = this.amountToWords(savedData.expense_amount);

      this.values = {
        id: savedData.reference_num,
        amount: savedData.expense_amount,
        date: savedData.created_at,
        cash: cashData,
        momo: momoData,
        cheque_number: chequeNumber,
        amount_in_words: amountInWords,
        payment_reason: savedData.expense_description,
        user_name: savedData.paid_by,
        customer_name: savedData.name,
      };
      window.print();
    },
    amountToWords(amount) {
      if (!amount) return "";
      var converter = require("number-to-words");
      return this.captializeText(converter.toWords(amount));
    },
    captializeText(value) {
      if (!value) return "";
      // value = value.toString()
      // return value.charAt(0).toUpperCase() + value.slice(1)

      let capitalized = [];
      value.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
  },
};
</script>

<style scoped>
img {
  height: 75px;
  width: auto;
}

.content {
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.invoice {
  page-break-after: always;
}

.column {
  float: left;
  width: 50%;
}

.column:nth-child(1) {
  padding-right: 2.5px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.contract h4 {
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 0;
  padding-top: 6pt;
}

.contract p {
  font-size: 9.8px;
  margin-bottom: 0;
}

.contract p.underline {
  font-weight: bold;
}

.contract .appendix,
.contract .sign {
  margin-top: 12px;
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c50 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 53.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c17 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 220.4pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c53 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: transparent;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 48.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c25 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 17.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c40 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 17.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c22 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 237.7pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c7 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 460.5pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c39 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 240.1pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c3 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 5.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c10 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 222.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c6 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 48.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c16 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 5.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c31 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 53.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c20 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 48.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c24 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 5.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c14 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 53.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c12 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 110.3pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c5 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c30 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Arial";
  font-style: normal;
}

.c29 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}

.c4 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c54 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 7pt;
  font-family: "Arial";
  font-style: normal;
}

.c11 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c28 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-family: "Arial";
  font-style: normal;
}

.c13 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12.5pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c33 {
  padding-top: 11.1pt;
  padding-bottom: 0pt;
  line-height: 1.2916666666666667;
  text-align: right;
  margin-right: 4.8pt;
  height: 11pt;
}

.c34 {
  -webkit-text-decoration-skip: none;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 16pt;
  font-family: "Calibri";
}

.c35 {
  margin-left: 6.1pt;
  padding-top: 3.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c21 {
  margin-left: 10.3pt;
  padding-top: 4.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c19 {
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left;
  height: 11pt;
}

.c38 {
  padding-top: 11.1pt;
  padding-bottom: 0pt;
  line-height: 1.2916666666666667;
  text-align: left;
  height: 11pt;
}

.c51 {
  margin-left: 4.6pt;
  padding-top: 3.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c41 {
  margin-left: 5.2pt;
  padding-top: 3.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c18 {
  margin-left: 5.3pt;
  padding-top: 3.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c48 {
  padding-top: 8.4pt;
  padding-bottom: 0pt;
  line-height: 0.85;
  text-align: left;
  height: 11pt;
}

.c46 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-style: normal;
}

.c52 {
  margin-left: 4.6pt;
  padding-top: 3.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c26 {
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}

.c49 {
  margin-left: 6.1pt;
  padding-top: 3.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c9 {
  margin-left: 6.1pt;
  padding-top: 2.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c37 {
  margin-left: 16.8pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c15 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left;
  height: 11pt;
}

.c23 {
  margin-left: 6.1pt;
  padding-top: 3.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c36 {
  margin-left: 11pt;
  padding-top: 1.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c1 {
  margin-left: 6.1pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c2 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}

.c47 {
  margin-left: 10.8pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

.c8 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c43 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.c27 {
  font-size: 11pt;
  font-family: "Calibri";
  font-weight: 400;
}

.c44 {
  font-weight: 700;
  font-family: "Calibri";
}

.c42 {
  height: 91.5pt;
}

.c32 {
  height: 27.9pt;
}

.c45 {
  height: 27.8pt;
}

.c0 {
  height: 28.1pt;
}

.title {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 33.5pt;
  padding-bottom: 0pt;
  font-family: "Courier New";
  line-height: 1;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}

h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

.cash-title {
  margin-left: 20px;
}

.denomination-table {
  margin-left: 16px;
}

.denomination-table thead {
  padding: auto;
}

.denomination-table th {
  padding-right: 60px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.denomination-table tr {
  margin-bottom: 4px;
}
</style>