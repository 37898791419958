<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar style="background-color: rgb(98, 20, 85)" />
      <!-- <header-stats /> -->
      <div class="px-4 md:px-10 mx-auto w-full md:pt-32 pt-12">
        <router-view />
        <footer-admin />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
// import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
export default {
  name: "accounts-layout",
  components: {
    AdminNavbar,
    Sidebar,
    // HeaderStats,
    FooterAdmin,
  },
};
</script>
