import { createWebHistory, createRouter } from "vue-router";

// layouts

import Admin from "@/layouts/Admin.vue";
import Marketing from "@/layouts/Marketing.vue";
import Projects from "@/layouts/Projects.vue";
import Accounts from "@/layouts/Accounts.vue";
import Hr from "@/layouts/Hr.vue";
import Auth from "@/layouts/Auth.vue";
import Approvals from "@/layouts/Approvals.vue";
import Commissions from "@/layouts/Commissions.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Branches from "@/views/admin/Branches.vue";
import Departments from "@/views/admin/Departments.vue";
import UnitOfMeasurements from "@/views/admin/UnitOfMeasurements.vue";
import UserRegistration from "@/views/admin/UserRegistration.vue";
import Profile from "@/views/admin/Profile.vue";
import Tables from "@/views/admin/Tables.vue";
import Maps from "@/views/admin/Maps.vue";
import CustomerConvo from "@/views/admin/CustomerConversations.vue";
import CustomerConvoReport from "@/views/admin/CustomerConvoReport.vue";
import EnquiriesComplaints from "@/views/admin/EnquiriesComplaints.vue";
import EnquiryReport from "@/views/admin/EnquiryReport.vue";
import ProductsPage from "@/views/admin/ProductsPage.vue";
import Products from "@/views/admin/Products.vue";
import ProductStocks from "@/views/admin/ProductStocks.vue";
import StockDisbursals from "@/views/admin/StockDisbursals.vue";
import StockInventoryReport from "@/views/admin/StockInventoryReport.vue";

import Invoice from "@/views/Invoice.vue";
import Voucher from "@/views/Voucher.vue";

// views for Projects Layout

import Sites from "@/views/projects/Sites.vue";
import LandDemarcations from "@/views/projects/LandDemarcations.vue";
import LandPricing from "@/views/projects/LandPricing.vue";
import CustomerActivity from "@/views/projects/CustomerActivity.vue";
import CustomerCentre from "@/views/projects/CustomerCentre.vue";
import LandDocumetationsReport from "@/views/projects/LandDocumetationsReport.vue";
import LandRegistrationsReport from "@/views/projects/LandRegistrationsReport.vue";
import LandInventoryReport from "@/views/projects/LandInventoryReport.vue";
import DocumentationDue from "@/views/projects/DocumentationDue.vue";
import LandRanging from "@/views/projects/LandRanging.vue";

// views for Marketing Layout

import Prospects from "@/views/marketing/Prospects.vue";
import NewBookings from "@/views/marketing/NewBookings.vue";
import CustomersPage from "@/views/marketing/CustomersPage.vue";
import Customers from "@/views/marketing/Customers.vue";
import CustomersWizard from "@/views/marketing/CustomersWizard.vue";
import ProspectsReport from "@/views/marketing/ProspectsReport.vue";
import CustomersNextOfKin from "@/views/admin/CustomerNextOfKin.vue";
// import ClientsTransfer from "@/views/marketing/ClientsTransfer.vue";
import CustomerProperty from "@/views/marketing/CustomerProperty.vue";
import LandOwner from "@/views/marketing/LandOwnerManagement.vue";
import SiteVisits from "@/views/marketing/SiteVisits.vue";
import SiteVisitsReport from "@/views/marketing/SiteVisitsReport.vue";
import PeriodicSalesReport from "@/views/marketing/PeriodicSalesReport.vue";

//views for Accounts Layout

import CustomerPayments from "@/views/accounts/CustomerPayments.vue";
import CustomerPaymentOptions from "@/views/accounts/CustomerPaymentOptions.vue";
import CustomerPaymentsPage from "@/views/accounts/CustomerPaymentsPage.vue";
import CustomerPaymentReceipt from "@/views/accounts/CustomerPaymentReceipt.vue";
import ExpenseTypes from "@/views/accounts/ExpenseTypes.vue";
import ExpensePayments from "@/views/accounts/Expenses.vue";
import ExpenseReports from "@/views/accounts/ExpenseReports.vue";
import CustomerBalances from "@/views/accounts/CustomerBalances.vue";
import ReadyToPay from "@/views/accounts/ReadyToPay.vue";
import CustomerPaymentsReport from "@/views/accounts/CustomerPaymentsReport.vue";
import LandOwnerPaymentsReport from "@/views/accounts/LandOwnerPaymentsReport.vue";
import LandOwnerBalancesReport from "@/views/accounts/LandOwnerBalancesReport.vue";
import LandOwnerPayments from "@/views/accounts/LandOwnerPayments.vue";

//views for HR layout
import WorkingDays from "@/views/hr/WorkingDays.vue";
import Designations from "@/views/hr/Designations.vue";
import PreviousEmployee from "@/views/hr/PreviousEmployee.vue";
import JobVacancies from "@/views/hr/Vacancy.vue";
import JobApplications from "@/views/hr/JobApplications.vue";
import EvaluateApplicant from "@/views/hr/EvaluateApplicant.vue";
import InterviewEvaluations from "@/views/hr/InterviewEvaluations.vue";
import LeaveCategory from "@/views/hr/LeaveCategory.vue";
import Guardian from "@/views/hr/Guardian.vue";
import NextofKin from "@/views/hr/NextofKin.vue";
import EmployeeRegistration from "@/views/hr/Employees.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

//views for Approvals Layout
import Discounts from "@/views/approvals/Discounts.vue";

//views for Commissions Layout
import MarketingCommissions from "@/views/commissions/Marketing.vue";
import OtherCommissions from "@/views/commissions/Other.vue";
// import CommissionsReport from "@/views/commissions/Report.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
// import Index from "@/views/Index.vue";

const routes = [
    {
        path: "/admin",
        // redirect: "/admin/dashboard",
        component: Admin,
        children: [
            {
                path: "/admin/dashboard",
                component: Dashboard,
            },
            {
                path: "/admin/settings",
                component: Settings,
            },
            {
                path: "/admin/branches",
                component: Branches,
            },
            {
                path: "/admin/departments",
                component: Departments,
            },
            {
                path: "/admin/unit-of-measurement",
                component: UnitOfMeasurements,
            },
            {
                path: "/admin/user-registration",
                component: UserRegistration,
            },
            {
                path: "/admin/customer/convo",
                component: CustomerConvo,
            },
            {
                path: "/admin/customer/convo/report",
                component: CustomerConvoReport,
            },
            {
                path: "/admin/enquiries",
                component: EnquiriesComplaints,
            },
            {
                path: "/admin/enquiries/report",
                component: EnquiryReport,
            },
            {
                path: "/admin/profile",
                component: Profile,
            },
            {
                path: "/admin/tables",
                component: Tables,
            },
            {
                path: "/admin/maps",
                component: Maps,
            },
            {
                path: "/admin/products/page",
                component: ProductsPage,
            },
            {
                path: "/admin/products",
                component: Products,
            },
            {
                path: "/admin/products/stock",
                component: ProductStocks,
            },
            {
                path: "/admin/stock/disbursal",
                component: StockDisbursals,
            },
            {
                path: "/admin/stock/inventory/report",
                component: StockInventoryReport,
            },
        ],
    },
    {
        path: "/projects",
        // redirect: "/projects/dashboard",
        component: Projects,
        children: [
            {
                path: "/admin/dashboard",
                component: Dashboard,
            },
            {
                path: "/projects/sites",
                component: Sites,
            },
            {
                path: "/projects/landdemarcations",
                component: LandDemarcations,
            },
            {
                path: "/projects/customer/activity",
                component: CustomerActivity,
            },
            {
                path: "/projects/customer/centre",
                component: CustomerCentre,
            },
            {
                path: "/projects/documentations/report",
                component: LandDocumetationsReport,
            },
            {
                path: "/projects/registrations/report",
                component: LandRegistrationsReport,
            },
            {
                path: "/projects/customers/balances",
                component: CustomerBalances,
            },
            {
                path: "/projects/land/inventory/report",
                component: LandInventoryReport,
            },
            {
                path: "/projects/due_for_documentations",
                component: DocumentationDue,
            },
            {
                path: "/projects/land_ranging",
                component: LandRanging,
            },
        ],
    },
    {
        path: "/marketing",
        // redirect: "/marketing/dashboard",
        component: Marketing,
        children: [
            {
                path: "/admin/dashboard",
                component: Dashboard,
            },
            {
                path: "/marketing/prospects",
                component: Prospects,
            },
            {
                path: "/marketing/prospects/report",
                component: ProspectsReport,
            },
            {
                path: "/marketing/newbooking",
                component: NewBookings,
            },
            {
                path: "/marketing/customers",
                component: Customers,
            },
            // {
            //     path: "/marketing/customers/transfer",
            //     component: ClientsTransfer,
            // },
            {
                path: "/marketing/customerswizard",
                component: CustomersWizard,
            },
            {
                path: "/marketing/customers/page",
                component: CustomersPage,
            },
            {
                path: "/marketing/customers/nextofkin",
                component: CustomersNextOfKin,
            },
            {
                path: "/marketing/customers/property",
                component: CustomerProperty,
            },
            {
                path: "/marketing/landowner",
                component: LandOwner,
            },
            {
                path: "/marketing/site/visits",
                component: SiteVisits,
            },
            {
                path: "/marketing/site/visits/report",
                component: SiteVisitsReport,
            },
            {
                path: "/marketing/customers/balances",
                component: CustomerBalances,
            },
            {
                path: "/marketing/customers/payment/options",
                component: CustomerPaymentOptions,
            },
            {
                path: "/marketing/land/inventory/report",
                component: LandInventoryReport,
            },
            {
                path: "/marketing/enquiries",
                component: EnquiriesComplaints,
            },
            {
                path: "/marketing/enquiries/report",
                component: EnquiryReport,
            },
            {
                path: "/marketing/periodic/sales/report",
                component: PeriodicSalesReport,
            },
        ],
    },
    {
        path: "/accounts",
        // redirect: "/accounts/dashboard",
        component: Accounts,
        children: [
            {
                path: "/admin/dashboard",
                component: Dashboard,
            },
            {
                path: "/accounts/customers",
                component: Customers,
            },
            {
                path: "/accounts/landpricing",
                component: LandPricing,
            },
            {
                path: "/accounts/customers/payment/receipt",
                component: CustomerPaymentReceipt,
            },
            {
                path: "/accounts/customers/payment/page",
                component: CustomerPaymentsPage,
            },
            {
                path: "/accounts/customers/payment/options",
                component: CustomerPaymentOptions,
            },
            {
                path: "/accounts/expense/types",
                component: ExpenseTypes,
            },
            {
                path: "/accounts/expense/payment",
                component: ExpensePayments,
            },
            {
                path: "/accounts/expense/payment/report",
                component: ExpenseReports,
            },
            {
                path: "/accounts/customers",
                component: Customers,
            },
            {
                path: "/accounts/customers/ready-to-pay",
                component: ReadyToPay,
            },
            {
                path: "/accounts/customer/centre",
                component: CustomerCentre,
            },
            {
                path: "/accounts/customers/payments",
                component: CustomerPayments,
            },
            {
                path: "/accounts/customers/payments/report",
                component: CustomerPaymentsReport,
            },
            {
                path: "/accounts/customers/balances",
                component: CustomerBalances,
            },
            {
                path: "/accounts/landowner",
                component: LandOwner,
            },
            {
                path: "/accounts/landowner/payment",
                component: LandOwnerPayments,
            },
            {
                path: "/accounts/landowner/payments/report",
                component: LandOwnerPaymentsReport,
            },
            {
                path: "/accounts/landowner/balances/report",
                component: LandOwnerBalancesReport,
            },
            {
                path: "/accounts/land/inventory/report",
                component: LandInventoryReport,
            },
        ],
    },
    // {
    //     path: "/projects",
    //     redirect: "/projects/dashboard",
    //     component: Admin,
    //     children: [
    //         {
    //             path: "/admin/dashboard",
    //             component: Dashboard,
    //         },
    //         {
    //             path: "/admin/settings",
    //             component: Settings,
    //         },
    //         {
    //             path: "/admin/branches",
    //             component: Branches,
    //         },
    //         {
    //             path: "/admin/departments",
    //             component: Departments,
    //         },
    //         {
    //             path: "/admin/unit-of-measurement",
    //             component: UnitOfMeasurements,
    //         },
    //         {
    //             path: "/admin/user-registration",
    //             component: UserRegistration,
    //         },
    //         {
    //             path: "/admin/profile",
    //             component: Profile,
    //         },
    //         {
    //             path: "/admin/tables",
    //             component: Tables,
    //         },
    //         {
    //             path: "/admin/maps",
    //             component: Maps,
    //         },
    //     ],
    // },
    {
        path: "/human-resource",
        // redirect: "/human-resource/dashboard",
        component: Hr,
        children: [
            {
                path: "/human-resource/working-days",
                component: WorkingDays,
            },
            {
                path: "/human-resource/designation",
                component: Designations,
            },
            {
                path: "/human-resource/previous-employee",
                component: PreviousEmployee,
            },
            {
                path: "/human-resource/job-vacancies",
                component: JobVacancies,
            },
            {
                path: "/human-resource/job-applications",
                component: JobApplications,
            },
            {
                path: "/human-resource/evaluate-applicant",
                component: EvaluateApplicant,
            },
            {
                path: "/human-resource/interview-evaluations",
                component: InterviewEvaluations,
            },
            {
                path: "/human-resource/leave-category",
                component: LeaveCategory,
            },
            {
                path: "/human-resource/guardian",
                component: Guardian,
            },
            {
                path: "/human-resource/next-of-kin",
                component: NextofKin,
            },
            {
                path: "/human-resource/employee-registration",
                component: EmployeeRegistration,
            },
        ],
    },
    {
        path: "/approvals",
        // redirect: "/accounts/dashboard",
        component: Approvals,
        children: [
            {
                path: "/admin/dashboard",
                component: Dashboard,
            },
            {
                path: "/approvals/discounts",
                component: Discounts,
            },
        ],
    },
    {
        path: "/commissions",
        // redirect: "/accounts/dashboard",
        component: Commissions,
        children: [
            {
                path: "/admin/dashboard",
                component: Dashboard,
            },
            {
                path: "/commissions/marketing",
                component: MarketingCommissions,
            },
            {
                path: "/commissions/other",
                component: OtherCommissions,
            },
            // {
            //     path: "/commissions/report",
            //     component: CommissionsReport,
            // },
        ],
    },
    {
        path: "/auth",
        redirect: "/auth/login",
        component: Auth,
        children: [
            {
                path: "/auth/login",
                component: Login,
            },
            {
                path: "/auth/register",
                component: Register,
            },
        ],
    },
    {
        path: "/landing",
        component: Landing,
    },
    {
        path: "/invoice",
        component: Invoice,
    },
    {
        path: "/voucher",
        component: Voucher,
    },
    {
        path: "/",
        redirect: "/admin/dashboard",
    },
    // { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;