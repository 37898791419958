<template>
  <div class="row">
    <div class="Page pull-left">
      <p class="mt-0">Showing {{ this.data.from }} to {{ this.data.to }} of {{ this.data.total }} records</p>
    </div>
    <nav class="Page pull-right navigation example">
      <ul class="pagination justify-content-end">
        <li class="page-item" :class="{ disabled: data.current_page == 1 }" @click="nextPage(data.first_page_url)">
          <a class="page-link" href="#" tabindex="-1">
            <i class="fa fa-fast-backward"></i>
            <span class="sr-only">First</span>
          </a>
        </li>
        <li class="page-item" :class="{ disabled: data.prev_page_url == null }" @click="nextPage(data.prev_page_url)">
          <a class="page-link" href="#" tabindex="-1">
            <i class="fa fa-angle-left"></i>
            <span class="sr-only">Previous</span>
          </a>
        </li>
        <li class="page-item disabled" v-if="data.current_page > 3">
          <a class="page-link" href="#">...</a>
        </li>
        <li class="page-item" :class="{
            active: index == data.current_page,
            'd-none':
              (index > 5 && data.current_page < index) ||
              index < data.current_page - 3,
          }" v-for="index in data.last_page" :key="index" @click="nextPage(data.path + '?page=' + index)">
          <a class="page-link" href="#">{{ index }}</a>
        </li>
        <li class="page-item disabled" v-if="data.last_page > 3 && data.current_page + 1 <= data.last_page">
          <a class="page-link" href="#">...</a>
        </li>
        <li class="page-item" :class="{ disabled: data.next_page_url == null }" @click="nextPage(data.next_page_url)">
          <a class="page-link" href="#">
            <i class="fa fa-angle-right"></i>
            <span class="sr-only">Next</span>
          </a>
        </li>
        <li class="page-item" :class="{ disabled: data.to == data.total }" @click="nextPage(data.last_page_url)">
          <a class="page-link" href="#" tabindex="-1">
            <i class="fa fa-fast-forward"></i>
            <span class="sr-only">Last</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "ServersidePager",
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  methods: {
    nextPage(link) {
      this.$emit("page-clicked", link);
    },
  },
  computed: {
    activePage() {
      return this.$route.name;
    },
  },
};
</script>

<style scopped>
.pull-left {
  float: left;
  vertical-align: middle;
}

.pull-right {
  float: right
}

.Page {
  padding: 10px 15px;
}

.pagination {
  display: inline-flex;
  background: #fff;
  border-radius: 15px;
}

.pagination .page-item {
  padding: 8px 10px;
  color: rgba(71, 85, 105, 1);
}

.pagination .page-item.active {
  background: rgba(71, 85, 105, 1);
  color: #fff;
  border-radius: 15px;
}
</style>