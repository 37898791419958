<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
    "
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="text-center flex justify-between">
        <h3
          class="font-semibold text-lg"
          :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
        >
          {{ tableName }}
        </h3>
        <!-- <button
          v-if="!isProcessing && !isGetting"
          @click="toggleModal()"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Add Customer
        </button> -->
        <button
          v-if="isProcessing || isGetting"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table
        class="items-center w-full bg-transparent border-collapse"
        v-if="tablePage == 1"
      >
        <thead>
          <tr>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Actual Customer [Prospect]
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Phone
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Sales Rep
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Details
            </th>
            <!-- <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-center
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
              v-if="
                department == storeDepartments.accounts ||
                department == storeDepartments.superAdmin
              "
            >
              Payment
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(c, i) in customers" :key="i">
            <th
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
                flex
                items-center
              "
            >
              <!-- <img
                :src="bootstrap"
                class="h-12 w-12 bg-white rounded-full border"
                alt="..."
              /> -->
              <span
                class="ml-3 font-bold"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]"
              >
                {{ `${c.actual_buyer_name} [${c.name}]` }}
              </span>
            </th>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ `${c.country_code} ${c.phone_number}` }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              {{ c.sales_rep }}
            </td>
            <td
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              "
            >
              <a
                href="#"
                @click.prevent="updateCustomer(c)"
                v-if="!isProcessing"
                class="text-lightBlue-400 mr-4"
                type="button"
              >
                <i class="fas fa-eye"></i> Details
              </a>
              <a
                href="#"
                @click.prevent="transferCustomer(c)"
                v-if="!isProcessing"
                class="text-teal-500"
                type="button"
              >
                <i class="fas fa-forward"></i> Transfer
              </a>
            </td>
            <!-- <td
              class="text-center"
              v-if="
                department == storeDepartments.accounts ||
                department == storeDepartments.superAdmin
              "
            >
              <button
                @click.prevent="goToPayments(c)"
                v-if="!isProcessing"
                class="
                  bg-purple-500
                  text-white
                  active:bg-purple-600
                  font-bold
                  uppercase
                  text-xs
                  px-4
                  py-2
                  rounded
                  shadow
                  hover:shadow-md
                  outline-none
                  focus:outline-none
                  ease-linear
                  transition-all
                  duration-150
                "
                type="button"
              >
                Pay
              </button>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";

export default {
  data() {
    return {
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      team1,
      team2,
      team3,
      team4,
      tablePage: 1,
    };
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    customers: {
      type: Array,
    },
    prospects: {
      type: Array,
    },
    isProcessing: {
      type: Boolean,
    },
    isGetting: {
      type: Boolean,
    },
  },
  methods: {
    toggleModal() {
      this.$emit("toggle-modal");
    },
    updateCustomer(obj) {
      this.$emit("view-customer-details", obj);
    },
    convertCustomer(obj) {
      this.$emit("convert-prospect", obj);
    },
    goToPayments(obj) {
      this.$router.push({
        path: "/accounts/customers/payments",
        query: {
          customer: JSON.stringify(obj),
        },
      });
    },
    transferCustomer(obj) {
      this.$emit("transfer-customer", obj);
    },
  },
  mounted() {
    this.department = localStorage.getItem("department");
  },
  computed: {
    tableName() {
      let tName = "";
      switch (this.tablePage) {
        case 1:
          tName = "Customers";
          break;
        case 2:
          tName = "Prospects";
          break;

        default:
          break;
      }
      return tName;
    },
    storeDepartments() {
      return this.$store.getters.getStoreDepartments;
    },
  },
};
</script>
