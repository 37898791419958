<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <CardBranches />
    </div>
  </div>
</template>
<script>
import CardBranches from "@/components/Cards/CardBranches.vue";

export default {
  components: {
    CardBranches,
  },
  data() {
    return {
      pageName: "Branches",
    };
  },
};
</script>
