<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Previous Employees</h6>
        <button
          v-if="!isProcessing && !isGetting"
          @click="validateEntry()"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Save Previous Employee
        </button>
        <button
          v-if="isProcessing || isGetting"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Previous Employee
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="prevEmp.emp_id"
            >
              <option v-for="(b, i) in employees" :key="i" :value="b.id">
                {{ b.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Previous Employee Name
            </label>
            <input
              v-model="prevEmp.prev_emp_name"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Date Employed
          </label>
          <input
            v-model="prevEmp.date_employed"
            type="date"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
          />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Employee ID
          </label>
          <input
            v-model="prevEmp.employee_id"
            type="text"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
          />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Job Title
          </label>
          <input
            v-model="prevEmp.job_title"
            type="text"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
          />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Employment Status
          </label>
          <input
            v-model="prevEmp.employment_status"
            type="text"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
          />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Annual Salary
          </label>
          <input
            v-model="prevEmp.annual_salary"
            type="number"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
          />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Date of Separation
          </label>
          <input
            v-model="prevEmp.date_of_separation"
            type="date"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
          />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Reason for Separation
          </label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
            v-model="prevEmp.reason_for_separation"
          ></textarea>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardPreviousEmployeeTable
            :color="'light'"
            :prevEmps="prevEmps"
            :isProcessing="isProcessing"
            @delete-prevEmp="deletePrevEmp"
            @update-prevEmp="updatePrevEmp"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardPreviousEmployeeTable from "@/components/Tables/CardPreviousEmployeeTable.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "PreviousEmpolyees",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      prevEmp: {
        emp_id: "",
        prev_emp_name: "",
        date_employed: "",
        employee_id: "",
        job_title: "",
        employment_status: "",
        annual_salary: "",
        date_of_separation: "",
        reason_for_separation: "",
      },
      prevEmps: [],
      employees: [],
      departments: [],
    };
  },
  validations() {
    return {
      prevEmp: {
        emp_id: { required },
        prev_emp_name: { required },
        date_employed: { required },
        employee_id: { required },
        job_title: { required },
        employment_status: { required },
        annual_salary: { required },
        date_of_separation: { required },
        reason_for_separation: { required },
      },
    };
  },
  components: {
    CardPreviousEmployeeTable,
  },
  created() {},
  mounted() {
    this.getPrevEmps();
    this.getEmployees();
  },
  methods: {
    printReport() {
      this.$refs.enquiryReport.printReport();
    },
    assignCustomer(client) {
      console.log(client);
      this.property.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.savePrevEmp();
      }
    },
    getPrevEmps() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("prev-emp-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.prevEmps = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getEmployees() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("employee-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.employees = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    deletePrevEmp(prevEmp) {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("prev-emp-delete/" + prevEmp.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getPrevEmps();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    savePrevEmp() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("prev-emp-store", this.prevEmp)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getPrevEmps();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    updatePrevEmp() {},
    emptyEntry() {
      this.prevEmp = {
        emp_id: "",
        prev_emp_name: "",
        date_employed: "",
        employee_id: "",
        job_title: "",
        employment_status: "",
        annual_salary: "",
        date_of_separation: "",
        reason_for_separation: "",
      };
    },
  },
};
</script>
