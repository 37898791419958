<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <CardDepartments />
    </div>
  </div>
</template>
<script>
import CardDepartments from "@/components/Cards/CardDepartments.vue";

export default {
  components: {
    CardDepartments,
  },
  data() {
    return {
      pageName: "Unit Of Measurement",
    };
  },
};
</script>
