<template>
  <footer class="block py-4">
    <div class="container mx-auto px-4">
      <hr class="mb-4 border-b-1 border-blueGray-200" />
      <div class="flex flex-wrap items-center md:justify-between justify-center">
        <div class="w-full md:w-4/12 px-4">
          <div class="
              text-sm text-blueGray-500
              font-semibold
              py-1
              text-center
              md:text-left
            ">
            Copyright © {{ date }}
            <a href="http://estatemastersgh.com" class="
                text-blueGray-500
                hover:text-blueGray-700
                text-sm
                font-semibold
                py-1
              ">
              {{ company.name_of_company }}
            </a>
          </div>
        </div>
        <div class="w-full md:w-4/12 px-4">
          <div class="
              text-sm text-blueGray-500
              font-semibold
              py-1
              text-right
              md:text-right
            ">
            Digitally crafted by
            <a href="https://davidndanu.com" target="_blank" class="
                text-blueGray-500
                hover:text-blueGray-700
                text-sm
                font-semibold
                py-1
              ">
              Devedem
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
      company: {
        name_of_company: "",
        phone: "",
        email: "",
        address: "",
        location: "",
        logo: "",
      },
    };
  },
  created() {
    this.company = JSON.parse(localStorage.getItem("companyInfo"));
  },
  mounted() { },
};
</script>
