<template>
    <div class="flex">
        <VueMultiselect v-model="selectedSites" :options="sites" :multiple="true" :close-on-select="false"
            :clear-on-select="false" :loading="isLoading" :preserve-search="true" :max-height="600"
            placeholder="Type To Search" label="name" track-by="id" :preselect-first="true" :limit="2"
            :custom-label="customLabel">
            <span slot="noResult">
                Oops! No elements found. Consider changing the search query.
            </span>
        </VueMultiselect>
        <button v-if="selectedSites.length > 0" @click="emitData()" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          " type="button">
            <i class="fas fa-calculator"></i>
        </button>
    </div>
</template>
<script>
import VueMultiselect from "vue-multiselect";

export default {
    props: {
        parsedSite: {
            type: Object,
            default: () => ({}),
        },
        savedCountryCode: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            countryCode: "",
            isLoading: false,
            sites: [],
            selectedSites: []
        };
    },
    mounted() {
        this.getSites();
    },
    components: {
        VueMultiselect,
    },
    created() { },
    methods: {
        emitData() {
            this.$emit("select-site", this.selectedSites);
        },
        customLabel({ site_name, land_range_from, land_range_to, demarcation_name }) {
            if (site_name || land_range_from || land_range_to || demarcation_name) {
                return `${site_name} [Plot ${land_range_from} - ${land_range_to}] [${demarcation_name}]`;
            } else {
                return "Search Priced Land Site";
            }
        },
        getSites() {
            let _ = this;
            this.isLoading = true;
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("accessToken");
            this.axios
                .get("land-pricing/paginate/no")
                .then((res) => {
                    if (res.data.status == 201 || res.data.status == 200) {
                        _.sites = res.data.data;
                    } else {
                        _.$wkToast(res.data.error, {
                            duration: 3000,
                            className: "wk-alert",
                        });
                    }
                    _.isLoading = false;
                })
                .catch((err) => {
                    _.$wkToast(err.response.data.message, {
                        duration: 3000,
                        className: "wk-alert",
                    });
                    _.isLoading = false;
                });
        },
    },
    watch: {
        // selectedSites() {
        //     if (this.selectedSites.length > 0) {
        //         this.emitData();
        //     } else {
        //         this.selectedSites = [];
        //     }
        // },
        parsedSite() {
            if (Object.keys(this.parsedSite).length > 0) {
                this.selectedSites = this.parsedSite;
            }
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
