<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Leave Category</h6>
        <button
          v-if="!isProcessing && !isGetting"
          @click="validateEntry()"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Save Leave Category
        </button>
        <button
          v-if="isProcessing || isGetting"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Category Name
            </label>
            <input
              v-model="leaveCategory.category_name"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>

        <div class="relative w-full lg:w-8/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Notes
          </label>
          <textarea
            name=""
            id=""
            cols="30"
            rows="5"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
            v-model="leaveCategory.category_notes"
          ></textarea>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardLeaveCategoryTable
            :color="'light'"
            :leaveCategories="leaveCategories"
            :isProcessing="isProcessing"
            @delete-category="deleteLeaveCategory"
            @update-category="updateLeaveCategory"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardLeaveCategoryTable from "@/components/Tables/CardLeaveCategoryTable.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "Designations",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      leaveCategory: {
        category_name: "",
        category_notes: "",
      },
      leaveCategories: [],
    };
  },
  validations() {
    return {
      leaveCategory: {
        category_name: { required },
        category_notes: {},
      },
    };
  },
  components: {
    CardLeaveCategoryTable,
  },
  created() {},
  mounted() {
    this.getLeaveCategories();
  },
  methods: {
    printReport() {
      this.$refs.enquiryReport.printReport();
    },
    assignCustomer(client) {
      console.log(client);
      this.property.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveLeaveCategory();
      }
    },
    getLeaveCategories() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("leave-category-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.leaveCategories = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    deleteLeaveCategory(leaveCategory) {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("leave-category-delete/" + leaveCategory.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getLeaveCategories();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    saveLeaveCategory() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("leave-category-store", this.leaveCategory)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getLeaveCategories();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    updateLeaveCategory() {},
    emptyEntry() {
      this.leaveCategory = {
        category_name: "",
        category_notes: "",
      };
    },
  },
};
</script>
