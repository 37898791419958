<template>
  <div>
    <div class="wizard-1">
      <div v-show="page == 1" class="w-full lg:w-12/12 px-4">
        <CardCustomerRegistration
          v-show="showModal"
          @toggle-modal="toggleModal"
          @get-customers="getCustomers"
          @get-prospects="getProspects"
          :countries="countries"
          :identifications="identifications"
          :branches="branches"
          :referrals="referrals"
          :selectedCustomer="selectedCustomer"
          @clear-selected-customer="clearSelectedCustomer"
          @parse-saved-customer="parseSavedCustomer"
          :isEdit="isEdit"
          :isConvert="isConvert"
          @next-page="nextPage"
        />
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4 overflow-auto">
            <CardCustomersTable
              :customers="customers"
              :prospects="prospects"
              :isProcessing="isProcessing"
              :isGetting="isGetting"
              @toggleModal="toggleModal"
              @view-customer-details="viewSelectedCustomer"
              @convert-prospect="convertProspect"
            />
          </div>
        </div>
      </div>
      <div v-show="page == 2" class="w-full lg:w-12/12 px-4">
        <CardCustomerNextOfKin
          :viewSearch="false"
          :parsedSelectedCustomer="selectedCustomer"
          @next-page="nextPage"
          @prev-page="prevPage"
        />
      </div>
      <div v-show="page == 3" class="w-full lg:w-12/12 px-4">
        <CustomerProperty
          :viewSearch="false"
          :parsedSelectedCustomer="selectedCustomer"
          @prev-page="prevPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CardCustomersTable from "@/components/Tables/CardCustomersTable.vue";
import useVuelidate from "@vuelidate/core";
import CardCustomerRegistration from "@/components/Cards/CardCustomerRegistration.vue";
import CustomerProperty from "@/views/marketing/CustomerProperty.vue";
import CardCustomerNextOfKin from "@/components/Cards/CardCustomerNextOfKin.vue";

export default {
  name: "CustomersWizard",
  components: {
    CardCustomersTable,
    CardCustomerRegistration,
    CustomerProperty,
    CardCustomerNextOfKin,
  },
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      isGetting: false,
      customers: [],
      isEdit: false,
      isConvert: false,
      user: {
        name: "",
        type: "1",
        email: "",
        password: "",
        password_confirmation: "",
      },
      showModal: false,
      countries: [],
      identifications: [],
      branches: [],
      referrals: [],
      selectedCustomer: {},
      prospects: [],
      page: 1,
    };
  },
  created() {
    // this.emitter.on("view-customer-details", (data) => {
    //   this.selectedCustomer = data;
    //   this.showModal = true;
    // });
  },
  mounted() {
    this.getCountries();
    this.getIdentifications();
    this.getBranches();
    this.getReferrals();
    this.getProspects();
    this.getCustomers();
    if (!Object.keys(this.$route.query).length) {
      return;
    } else {
      this.selectedCustomer = JSON.parse(this.$route.query.customerToConvert);
      this.isConvert = this.$route.query.isConvert;
      this.showModal = this.$route.query.openModal;
    }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    clearSelectedCustomer() {
      this.selectedCustomer = {};
      this.isEdit = false;
      this.isConvert = false;
    },
    viewSelectedCustomer(data) {
      this.clearSelectedCustomer();
      this.selectedCustomer = data;
      this.isEdit = true;
      this.isConvert = false;
      this.showModal = true;
    },
    convertProspect(data) {
      this.selectedCustomer = data;
      this.isConvert = true;
      this.isEdit = false;
      this.showModal = true;
    },
    prevPage() {
      this.page--;
    },
    nextPage() {
      this.page++;
    },
    parseSavedCustomer(customer) {
      this.selectedCustomer = customer;
    },
    getProspects() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("prospects/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.prospects = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getReferrals() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("referrals")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.referrals = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCustomers() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customers/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.customers = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getCountries() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("countries")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.countries = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getIdentifications() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("identifications")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.identifications = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
};
</script>
