<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
      bg-white
      px-4
      py-4
      transition-all
      duration-150
    "
  >
    <form class="flex flex-wrap px-3 py-3">
      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Customer Name
        </label>
        <p>{{ selectedCustomer.name }}</p>
      </div>

      <div class="relative w-full lg:w-9/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Reason For Call
        </label>
        <input
          v-model="convo.reason_for_call"
          type="text"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
          placeholder=""
        />
      </div>

      <div class="relative w-full lg:w-12/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Description
        </label>
        <textarea
          name=""
          v-model="convo.description"
          id=""
          cols="30"
          rows="5"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        ></textarea>
      </div>

      <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
        <button
          @click="cancelClose()"
          class="
            bg-blueGray-800
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Cancel &amp; Close
        </button>
      </div>

      <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
        <button
          v-if="!isEdit"
          @click="validateEntry()"
          class="
            bg-lightBlue-500
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Save Conversation
        </button>
        <button
          v-if="isEdit"
          @click="validateEntry()"
          class="
            bg-purple-500
            text-white
            active:bg-purple-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Update Conversation
        </button>
      </div>

      <div v-if="isProcessing" class="relative w-full lg:w-12/12 px-3 mt-3">
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
// import CustomerSearch from "@/components/Search/CustomerSearch.vue";

export default {
  name: "CardCustomerConvo",
  props: {
    isEdit: {
      type: Boolean,
    },
    convoToUpdate: {
      type: Object,
      default: () => ({}),
    },
    selectedCustomer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      convo: {
        customer_id: "",
        reason_for_call: "",
        description: "",
      },
      departments: [],
      enquiryTypes: [],
      enquiryStatus: [],
    };
  },
  validations() {
    return {
      convo: {
        customer_id: {},
        reason_for_call: { required },
        description: { required },
      },
    };
  },
  components: {
    // CustomerSearch,
  },
  watch: {
    selectedCustomer() {
      if (Object.keys(this.selectedCustomer).length > 0) {
        this.convo.customer_id = this.selectedCustomer.id;
      }
    },
    convoToUpdate() {
      if (!Object.keys(this.convoToUpdate).length) {
        return;
      } else {
        this.convo = this.convoToUpdate;
      }
    },
  },
  created() {},
  mounted() {
    this.emitter.on("emitConvo", (convo) => {
      this.convo = convo;
    });
    this.company = this.$store.getters.getCompanyInfo;
    // this.getDepartments();
    // this.getEnquiryStatus();
    // this.getEnquiryTypes();
    if (!Object.keys(this.convoToUpdate).length) {
      return;
    } else {
      this.convo = this.convoToUpdate;
    }
  },
  methods: {
    assignCustomer(customer) {
      this.convo.customer_id = customer.id;
    },
    cancelClose() {
      this.convo = {
        customer_id: "",
        reason_for_call: "",
        description: "",
      };
      this.$emit("clearData");
      this.toggleModal();
    },
    toggleModal() {
      this.$emit("toggle-modal");
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        if (!this.isEdit) {
          this.saveConvo();
        } else {
          this.postUpdateConvo();
        }
      }
    },
    getDepartments() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("department")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.departments = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    postUpdateConvo() {
      let _ = this;
      this.convo.customer_conversation_id = this.convo.id;
      delete this.convo.id;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-convo-update", this.convo)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.cancelClose();
            _.$emit("get-convo", _.selectedCustomer.id);
            _.$wkToast("Saved!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    saveConvo() {
      let _ = this;
      this.isProcessing = true;
      this.convo.customer_id = this.selectedCustomer.id;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-convo-store", this.convo)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.cancelClose();
            _.$emit("get-convo", _.selectedCustomer.id);
            _.$wkToast("Saved!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getEnquiryTypes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("enquiry-types")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.enquiryTypes = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getEnquiryStatus() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("enquiry-status")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.enquiryStatus = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
};
</script>
