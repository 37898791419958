<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4 mb-3">
      <div class="flex flex-wrap">
        <!-- <button
          @click="page = 0"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-credit-card"></i>
          Payment Options
        </button> -->
        <button
          @click="page = 2"
          class="
            bg-indigo-500
            text-white
            active:bg-indigo-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-credit-card"></i>
          Payments
        </button>
        <button
          @click="page = 3"
          class="
            bg-purple-500
            text-white
            active:bg-purple-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-table"></i>
          Report
        </button>
      </div>
      <!-- <div v-if="page > 0">
        <button
          @click="page = 0"
          class="
            bg-black
            text-white
            active:bg-slate-100
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-arrow-left"></i>
          Go Back
        </button>
      </div> -->
    </div>
    <div v-if="page == 0" class="w-full lg:w-12/12 px-4">
      <CustomerPaymentOptions />
    </div>
    <div v-if="page == 2" class="w-full lg:w-12/12 px-4">
      <CustomerPayments />
    </div>
    <div v-if="page == 3" class="w-full lg:w-12/12 px-4">
      <CustomerPaymentsReport />
    </div>
  </div>
</template>
<script>
import CustomerPaymentOptions from "@/views/accounts/CustomerPaymentOptions.vue";
import CustomerPayments from "@/views/accounts/CustomerPayments.vue";
import CustomerPaymentsReport from "@/views/accounts/CustomerPaymentsReport.vue";

export default {
  name: "CustomerPaymentsPage",
  components: {
    CustomerPaymentOptions,
    CustomerPayments,
    CustomerPaymentsReport,
  },
  data() {
    return {
      pageName: "CustomerPaymentsPage",
      page: 2,
    };
  },
  mounted() {},
};
</script>
