<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100`
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex">
        <select name="selectedJobVacancy" v-model="selectedJobVacancy" id="" class="border-0
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                ease-linear
                transition-all
                duration-150
                mr-5">
          <option></option>
          <option v-for="(v, i) in jobVacancies" :key="i" :value="v">{{ v.job_title }}</option>
        </select>
        <button @click="getJobApplications(1)" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150">
          Job Applications </button>
        <button @click="getJobApplications(2)" class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150">
          Shortlisted Applications </button>
        <!-- <button
          v-if="!isProcessing && !isGetting"
          @click="validateEntry()"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Save Previous Employee
        </button> -->
        <button v-if="isProcessing || isGetting" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          " type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2">
          <h6 class="text-blueGray-700 text-xl font-bold" v-if="page == 1">Job Applications <span
              v-if="Object.keys(selectedJobVacancy).length > 0">{{
                `For ${selectedJobVacancy.job_title}` }}</span>
          </h6>
          <h6 class="text-blueGray-700 text-xl font-bold" v-if="page == 2">Shortlisted Applications <span
              v-if="Object.keys(selectedJobVacancy).length > 0">{{ `For ${selectedJobVacancy.job_title}` }}</span>
          </h6>
        </div>
        <div class="w-full px-4 text-center mt-2 overflow-scroll">

          <button @click="shortListSelectedApplications" v-if="shortListArray.length > 0" class="
            bg-purple-500
            text-white
            active:bg-purple-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150">
            Shortlist Selected Applicants</button>
          <CardJobApplicationsTable :color="'light'" @shortlistArray="alterShortListArray" :list="page"
            :jobApplications="jobApplications" :isProcessing="isProcessing" :selectedJobApplicationsProp="shortListArray" />
          <serverside-pager :data="paginateRecords" @page-clicked="getJobApplications" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardJobApplicationsTable from "@/components/Tables/CardJobApplicationsTable.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ServersidePager from "../../components/ServersidePager.vue";
export default {
  name: "JobApplications",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      page: 1,
      prevEmp: {
        emp_id: "",
        prev_emp_name: "",
        date_employed: "",
        employee_id: "",
        job_title: "",
        employment_status: "",
        annual_salary: "",
        date_of_separation: "",
        reason_for_separation: "",
      },
      jobApplications: [],
      shortlist: [],
      departments: [],
      paginateRecords: {},
      shortListArray: [],
      jobVacancies: [],
      selectedJobVacancy: {}
    };
  },
  validations() {
    return {
      prevEmp: {
        emp_id: { required },
        prev_emp_name: { required },
        date_employed: { required },
        employee_id: { required },
        job_title: { required },
        employment_status: { required },
        annual_salary: { required },
        date_of_separation: { required },
        reason_for_separation: { required },
      },
    };
  },
  components: {
    CardJobApplicationsTable,
    ServersidePager
  },
  created() { },
  mounted() {
    this.getJobVacancies();
  },
  watch: {
    selectedJobVacancy() {
      if (Object.keys(this.selectedJobVacancy).length > 0 && this.selectedJobVacancy != null) {
        this.getJobApplications();
      }
    }
  },
  methods: {
    shortListSelectedApplications() {
      let _ = this;
      this.isProcessing = true;
      let shortlist = [];
      this.shortListArray.forEach(element => {
        shortlist.push(parseInt(element))
      });
      let obj = {
        application_id: shortlist
      }
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("shortlist-applicants", obj)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.shortListArray = []
            _.emitter.emit("clearSelectedApplicants");
            _.getJobApplications();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    alterShortListArray(newList) {
      this.shortListArray = []
      this.shortListArray = newList
    },
    printReport() {
      this.$refs.enquiryReport.printReport();
    },
    assignCustomer(client) {
      this.property.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.savePrevEmp();
      }
    },
    getJobVacancies() {
      let _ = this
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("job-vacancy")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.jobVacancies = res.data.data
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getJobApplications(page) {
      let _ = this;
      let pageUrl = "";
      switch (page) {
        case 1:
          pageUrl = "job-applications/" + this.selectedJobVacancy.id
          this.page = 1
          this.shortListArray = []
          break;

        case 2:
          pageUrl = "shortlisted/" + this.selectedJobVacancy.id
          this.page = 2
          this.shortListArray = []
          break;

        default:
          pageUrl = "job-applications/" + this.selectedJobVacancy.id
          this.page = 1
          this.shortListArray = []
          break;
      }
      this.jobApplications = []
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get(pageUrl)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.jobApplications = res.data.data.data;
            _.paginateRecords = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getShortlist() {
      let _ = this;
      this.page = 2
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("job-application-shortlist")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.jobApplications = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    deletePrevEmp(prevEmp) {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("prev-emp-delete/" + prevEmp.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getJobApplications();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    savePrevEmp() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("prev-emp-store", this.prevEmp)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getJobApplications();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    updatePrevEmp() { },
    emptyEntry() {
      this.prevEmp = {
        emp_id: "",
        prev_emp_name: "",
        date_employed: "",
        employee_id: "",
        job_title: "",
        employment_status: "",
        annual_salary: "",
        date_of_separation: "",
        reason_for_separation: "",
      };
    },
  },
};
</script>
