<template>
  <div class="
                relative
                flex flex-col
                min-w-0
                break-words
                bg-white
                w-full
                mb-6
                shadow-xl
                rounded-lg
                bg-blueGray-100
              ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Land Demarcations</h6>
        <button v-if="!isProcessing && !isGetting" @click="validateEntry()" class="
                      bg-lightBlue-500
                      text-white
                      active:bg-lightBlue-600
                      font-bold
                      uppercase
                      text-xs
                      px-4
                      py-2
                      rounded
                      shadow
                      hover:shadow-md
                      outline-none
                      focus:outline-none
                      mr-1
                      ease-linear
                      transition-all
                      duration-150
                    " type="button">
          Save Land Demarcation
        </button>
        <button v-if="isProcessing || isGetting" class="
                      bg-emerald-500
                      text-white
                      active:bg-emerald-600
                      font-bold
                      uppercase
                      text-small
                      px-4
                      py-2
                      rounded
                      shadow
                      hover:shadow-md
                      outline-none
                      focus:outline-none
                      mr-1
                      mb-1
                      ease-linear
                      transition-all
                      duration-150
                    " type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Demarcation Name
            </label>
            <input v-model="demarcation.demarcation_name" type="text" class="
                          border-0
                          px-3
                          py-3
                          placeholder-blueGray-300
                          text-blueGray-600
                          bg-white
                          rounded
                          text-sm
                          shadow
                          focus:outline-none focus:ring
                          w-full
                          ease-linear
                          transition-all
                          duration-150
                        " placeholder="eg: 100 X 100" />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Conversion To Acre
            </label>
            <input v-model="demarcation.acre" type="number" class="
                          border-0
                          px-3
                          py-3
                          placeholder-blueGray-300
                          text-blueGray-600
                          bg-white
                          rounded
                          text-sm
                          shadow
                          focus:outline-none focus:ring
                          w-full
                          ease-linear
                          transition-all
                          duration-150
                        " />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2">
          <CardLandDemarcationsTable :color="light" :demarcations="demarcations" :isProcessing="isProcessing"
            @delete-demarcation="deleteDemarcation" @update-demarcation="updateDemarcation" />

          <serverside-pager :data="paginateRecords" @page-clicked="getDemarcations" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardLandDemarcationsTable from "@/components/Tables/CardLandDemarcationsTable";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ServersidePager from "@/components/ServersidePager.vue";
export default {
  name: "CardLandDemarcations",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      demarcation: {
        demarcation_name: "",
        acre: "",
      },
      branches: [],
      demarcations: [],
      paginateRecords: {}
    };
  },
  validations() {
    return {
      demarcation: {
        demarcation_name: { required },
        acre: { required },
      },
    };
  },
  components: {
    CardLandDemarcationsTable,
    ServersidePager,
  },
  created() { },
  mounted() {
    // this.getBranches();
    this.getDemarcations();
  },
  methods: {
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveDemarcation();
      }
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getDemarcations(pageUrl) {
      let _ = this;
      _.demarcations = []
      pageUrl = pageUrl || "land-demarcation/paginate/yes";
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get(pageUrl)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.demarcations = res.data.data.data;
            _.paginateRecords = res.data.data
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    saveDemarcation() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("land-demarcation-store", this.demarcation)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getDemarcations();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    deleteDemarcation(site) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("land-demarcation-delete/" + site.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getDemarcations();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updateDemarcation(site) {
      this.demarcation = site;
      this.isEdit = true;
    },
    emptyEntry() {
      this.demarcation = {
        demarcation_name: "",
        acre: "",
      };
    },
  },
};
</script>
