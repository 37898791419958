<template>
  <div class="container">
    <!-- <navbar /> -->
    <main class="invoice">
      <div class="content">
        <p class="c36">
          <img :src="bg" class="height: 50px" alt="" /><span class="c34"
            >PAYMENT RECEIPT</span
          >
        </p>
        <p class="c21">
          <span class="c27">Ref: </span><span class="c11">{{ values.id }}</span>
        </p>
        <a id="t.38c8eb0d761b628fbdc578005f8319bf570fabe8"></a><a id="t.0"></a>
        <table class="c47">
          <tr class="c45">
            <td class="c39" colspan="6" rowspan="1">
              <p class="c49">
                <span class="c4">Amount: </span
                ><span class="c44">{{ values.currency }}</span
                ><span class="c4">&nbsp;</span
                ><span class="c11">{{ values.amount }}</span>
              </p>
            </td>
            <td class="c17" colspan="1" rowspan="1">
              <p class="c52">
                <span class="c4">Date: </span
                ><span class="c11">{{ values.date }}</span>
              </p>
            </td>
          </tr>
          <tr class="c32">
            <td class="c12" colspan="1" rowspan="1">
              <p class="c23"><span class="c11">Method of payment</span></p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c33"><span class="c28"></span></p>
            </td>
            <td class="c31" colspan="1" rowspan="1">
              <p class="c18"><span class="c4"></span></p>
              <p class="c18">
                <span class="c11">{{ values.paymentMode }}</span>
              </p>
            </td>
            <td class="c16" colspan="1" rowspan="1">
              <p class="c38"><span class="c28"></span></p>
            </td>
            <td class="c53" colspan="1" rowspan="1">
              <p class="c41"><span class="c4"></span></p>
              <p class="c41">
                <span class="c11"></span>
              </p>
            </td>
            <td class="c40" colspan="1" rowspan="1">
              <p class="c2"><span class="c5"></span></p>
            </td>
            <td class="c17" colspan="1" rowspan="1">
              <p class="c51">
                <span class="c4">Payment Reference: </span
                ><span class="c11">{{ values.paymentReference }}</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c7" colspan="7" rowspan="1">
              <p class="c23">
                <span class="c4">The sum of: </span
                ><span class="c11">{{ values.amount_in_words }}</span>
              </p>
            </td>
          </tr>
          <tr class="c42">
            <td class="c7" colspan="7" rowspan="1">
              <p class="c35">
                <span class="c4">Being: </span
                ><span class="c11">{{ values.payment_reason }}</span>
              </p>
            </td>
          </tr>
          <tr class="c0">
            <td class="c7" colspan="7" rowspan="1">
              <p class="c9">
                <span class="c4">Approved By:</span
                ><span class="c11">&nbsp;{{ values.user_name }}</span>
              </p>
            </td>
          </tr>
          <tr class="c32">
            <td class="c10" colspan="5" rowspan="1">
              <p class="c1">
                <span class="c4">Received By: </span
                ><span class="c44">{{ values.customer_name }}</span>
              </p>
            </td>
            <td class="c22" colspan="2" rowspan="1">
              <p class="c37"><span class="c4">Signature:</span></p>
            </td>
          </tr>
        </table>
      </div>
    </main>
    <!-- <footer-component /> -->
  </div>
</template>
<script>
// import Navbar from "@/components/Navbars/AuthNavbar.vue";
// import FooterComponent from "@/components/Footers/Footer.vue";

import bg from "@/assets/img/em_logo.png";

export default {
  data() {
    return {
      bg,
      values: {},
    };
  },
  components: {
    // Navbar,
    // FooterComponent,
  },
  created() {
    // this.emitter.on("emit-receipt-values", (savedData) => {
    //   this.prepareReceipt(savedData);
    // });
    // this.emitter.on("emit-voucher-values", (savedData) => {
    //   this.prepareVoucher(savedData);
    // });
  },
  mounted() {
    let query = this.$route.query;
    console.log(query);
    // let printType = query.printType;
    // switch (printType) {
    //   case "receipt":
    //     this.prepareReceipt(query);
    //     break;
    //   case "voucher":
    //     this.prepareVoucher(query);
    //     break;
    // }
    this.preparePrint(query);
  },
  methods: {
    preparePrint(query) {
      this.values = query;

      setTimeout(() => {
        window.print();
      }, 500);
    },
    prepareReceipt(savedData) {
      let cashData = "";

      let momoData = "";

      let chequeNumber = "";

      let amountInWords = this.amountToWords(savedData.initial_deposit);

      let paymentReason =
        "Initial Payment For Land With Range From " +
        savedData.land_range_from +
        " To " +
        savedData.land_range_to +
        " Which Costs " +
        savedData.property_cost;

      this.values = {
        id: savedData.id,
        amount: savedData.initial_deposit,
        date: savedData.created_at,
        cash: cashData,
        momo: momoData,
        cheque_number: chequeNumber,
        amount_in_words: amountInWords,
        payment_reason: paymentReason,
        user_name: savedData.created_by,
        customer_name: savedData.name,
      };
      window.print();
    },
    prepareVoucher(savedData) {
      let cashData = "";

      let momoData = "";

      let chequeNumber = "";

      let amountInWords = this.amountToWords(savedData.expense_amount);

      this.values = {
        id: savedData.reference_num,
        amount: savedData.expense_amount,
        date: savedData.created_at,
        cash: cashData,
        momo: momoData,
        cheque_number: chequeNumber,
        amount_in_words: amountInWords,
        payment_reason: savedData.expense_description,
        user_name: savedData.paid_by,
        customer_name: savedData.name,
      };
      window.print();
    },
    amountToWords(amount) {
      if (!amount) return "";
      var converter = require("number-to-words");
      return this.captializeText(converter.toWords(amount));
    },
    captializeText(value) {
      if (!value) return "";
      // value = value.toString()
      // return value.charAt(0).toUpperCase() + value.slice(1)

      let capitalized = [];
      value.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
  },
};
</script>

<style scoped>
img {
  height: 75px;
  width: auto;
}
.content {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c50 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 53.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c17 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 220.4pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c53 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: transparent;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 48.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c25 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 17.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c40 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 17.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c22 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 237.7pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c7 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 460.5pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c39 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 240.1pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c3 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 5.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c10 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 222.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c6 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 48.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c16 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 5.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c31 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 53.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c20 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 48.8pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c24 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 0pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 5.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c14 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 53.2pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c12 {
  border-right-style: solid;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 0pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 110.3pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}

.c5 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c30 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Arial";
  font-style: normal;
}

.c29 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}

.c4 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c54 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 7pt;
  font-family: "Arial";
  font-style: normal;
}

.c11 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}

.c28 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-family: "Arial";
  font-style: normal;
}

.c13 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12.5pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c33 {
  padding-top: 11.1pt;
  padding-bottom: 0pt;
  line-height: 1.2916666666666667;
  text-align: right;
  margin-right: 4.8pt;
  height: 11pt;
}

.c34 {
  -webkit-text-decoration-skip: none;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 16pt;
  font-family: "Calibri";
}

.c35 {
  margin-left: 6.1pt;
  padding-top: 3.4pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c21 {
  margin-left: 10.3pt;
  padding-top: 4.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c19 {
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left;
  height: 11pt;
}

.c38 {
  padding-top: 11.1pt;
  padding-bottom: 0pt;
  line-height: 1.2916666666666667;
  text-align: left;
  height: 11pt;
}

.c51 {
  margin-left: 4.6pt;
  padding-top: 3.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c41 {
  margin-left: 5.2pt;
  padding-top: 3.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c18 {
  margin-left: 5.3pt;
  padding-top: 3.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c48 {
  padding-top: 8.4pt;
  padding-bottom: 0pt;
  line-height: 0.85;
  text-align: left;
  height: 11pt;
}

.c46 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-style: normal;
}

.c52 {
  margin-left: 4.6pt;
  padding-top: 3.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c26 {
  padding-top: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}

.c49 {
  margin-left: 6.1pt;
  padding-top: 3.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c9 {
  margin-left: 6.1pt;
  padding-top: 2.5pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c37 {
  margin-left: 16.8pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c15 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  text-align: left;
  height: 11pt;
}

.c23 {
  margin-left: 6.1pt;
  padding-top: 3.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c36 {
  margin-left: 11pt;
  padding-top: 1.9pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c1 {
  margin-left: 6.1pt;
  padding-top: 2.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c2 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 11pt;
}

.c47 {
  margin-left: 10.8pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

.c8 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}

.c43 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}

.c27 {
  font-size: 11pt;
  font-family: "Calibri";
  font-weight: 400;
}

.c44 {
  font-weight: 700;
  font-family: "Calibri";
}

.c42 {
  height: 91.5pt;
}

.c32 {
  height: 27.9pt;
}

.c45 {
  height: 27.8pt;
}

.c0 {
  height: 28.1pt;
}

.title {
  padding-top: 0pt;
  color: #000000;
  font-weight: 700;
  font-size: 33.5pt;
  padding-bottom: 0pt;
  font-family: "Courier New";
  line-height: 1;
  text-align: left;
}

.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}

h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}
</style>