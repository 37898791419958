<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      mt-6
    ">
    <div v-if="!isGetting" class="px-4 mb-4">
      <div class="flex flex-wrap justify-center">
        <div class="w-full px-4 text-center mt-18">
          <div class="flex justify-center py-4 lg:pt-4 pt-4">
            <div class="mr-4 p-3 pb-0 text-center">
              <span class="text-xl text-black-400 font-bold">{{
                `${applicant.full_name}`
              }}</span>
            </div>
          </div>
          <div class="mr-4 text-center">
            <table class="items-center w-full bg-transparent border-collapse">
              <tbody>
                <tr>
                  <td class="
              px-6
              align-middle
              py-3
              text-xs
              
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            ">
                    <span class="applicant-data-field">Full Name </span><br>
                    <span class="applicant-data">{{ applicant.full_name }}</span>
                  </td>
                  <td class="
              px-6
              align-middle
              py-3
              text-xs
              
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            ">
                    <span class="applicant-data-field">Date Of Birth </span><br>
                    <span class="applicant-data">{{ $filters.cleanDate(applicant.dob) }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="
              px-6
              align-middle
              py-3
              text-xs
              
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            ">
                    <span class="applicant-data-field">Date Of Birth </span><br>
                    <span class="applicant-data">{{ $filters.cleanDate(applicant.dob) }}</span>
                  </td>
                  <td class="
              px-6
              align-middle
              py-3
              text-xs
              
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "><span class="applicant-data-field">Email Address </span><br>
                    <span class="applicant-data">{{ applicant.email_address }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="
              px-6
              align-middle
              py-3
              text-xs
              
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            ">
                    <span class="applicant-data-field">Applied On </span><br>
                    <span class="applicant-data">{{ $filters.cleanDate(applicant.created_at) }}</span>
                  </td>
                  <td class="
              px-6
              align-middle
              py-3
              text-xs
              
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "><span class="applicant-data-field">Years Of Experience </span><br>
                    <span class="applicant-data">{{ applicant.years_of_experience }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="
              px-6
              align-middle
              py-3
              text-xs
              
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            ">
                    <span class="applicant-data-field">Highest Education </span><br>
                    <span class="applicant-data">{{ applicant.highest_education }}</span>
                  </td>
                  <td class="
              px-6
              align-middle
              py-3
              text-xs
              
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            ">
                    <span class="applicant-data-field">Other Qualifications</span> <br>
                    <span class="applicant-data">{{ applicant.other_qualification }}</span>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="py-4">
            <!-- <div v-if="applicant.work_experience_details.length > 0" class="table-wrapper">
                <table class="fl-table">
                  <tdead>
                    <tr>
                      <td>Company</td>
                      <td>Position</td>
                      <td>Years of Employment</td>
                      <td>Summary of Responsibilities</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(exp, i) in applicant.work_experience_details" :key="i">
                      <td>{{ exp.name_of_company }}</td>
                      <td>{{ exp.years_of_employment }}</td>
                      <td>{{ exp.position }}</td>
                      <td>{{ exp.summary }}</td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="isGetting" class="px-8 justify-center">
      <button class="
          bg-lightBlue-500
          text-white
          active:bg-lightBlue-600
          font-bold
          
          text-small
          px-4
          py-2
          rounded
          shadow
          hover:shadow-md
          outline-none
          focus:outline-none
          mr-1
          mb-1
          ease-linear
          transition-all
          duration-150
        " type="button">
        <i class="fas fa-spinner"></i>
      </button>
    </div>
  </div>
</template>
<script>
import team2 from "@/assets/img/team-2-800x800.jpg";

export default {
  name: "CardJobApplicantBio",
  props: {
    applicant: {
      type: Object,
      default: () => ({}),
    },
    bio: {
      type: Object,
    },
    isGetting: {
      type: Boolean,
    },
    isProcessing: {
      type: Boolean,
    },
  },
  data() {
    return {
      team2,
    };
  },
};
</script>

<style scoped>
.applicant-data-field {
  text-transform: uppercase;
  font-size: 11px;
}

.applicant-data {
  font-size: 15px;
}

header .wrapper {
  display: flex;
  place-items: flex-start;
  flex-wrap: wrap;
}

.table-wrapper {
  margin: auto;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
}

.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
  table-layout: fixed;
  width: 100%;
}

.fl-table td,
.fl-table th {
  text-align: center;
  padding: 8px;
}

.fl-table td {
  border-right: 1px solid #f8f8f8;
  color: #0f172a;
  font-size: 12px;
  max-width: 150px;
  word-break: break-all;
  white-space: normal;
}

.fl-table thead th {
  color: #ffffff;
  background: #4FC3A1;
}


.fl-table thead th:nth-child(odd) {
  color: #ffffff;
  background: #324960;
}

.fl-table tr:nth-child(even) {
  background: #F8F8F8;
}

/* Responsive */

@media (max-width: 767px) {
  .fl-table {
    display: block;
    width: 100%;
  }

  .table-wrapper:before {
    content: "Scroll horizontally >";
    display: block;
    text-align: right;
    font-size: 11px;
    color: white;
    padding: 0 0 10px;
  }

  .fl-table thead,
  .fl-table tbody,
  .fl-table thead th {
    display: block;
  }

  .fl-table thead th:last-child {
    border-bottom: none;
  }

  .fl-table thead {
    float: left;
  }

  .fl-table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }

  .fl-table td,
  .fl-table th {
    padding: 20px .625em .625em .625em;
    height: 60px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 120px;
    font-size: 13px;
    text-overflow: ellipsis;
  }

  .fl-table thead th {
    text-align: left;
    border-bottom: 1px solid #f7f7f9;
  }

  .fl-table tbody tr {
    display: table-cell;
  }

  .fl-table tbody tr:nth-child(odd) {
    background: none;
  }

  .fl-table tr:nth-child(even) {
    background: transparent;
  }

  .fl-table tr td:nth-child(odd) {
    background: #F8F8F8;
    border-right: 1px solid #E6E4E4;
  }

  .fl-table tr td:nth-child(even) {
    border-right: 1px solid #E6E4E4;
  }

  .fl-table tbody td {
    display: block;
    text-align: center;
  }
}
</style>
