<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <UnitOfMeasurement />
    </div>
  </div>
</template>
<script>
import UnitOfMeasurement from "@/components/Cards/CardUnitOfMeasurement.vue";

export default {
  components: {
    UnitOfMeasurement,
  },
  data() {
    return {
      pageName: "Unit Of Measurement",
    };
  },
};
</script>
