<template>
  <div class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
              bg-blueGray-100
            ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          Customers Due For Documentation
        </h6>
        <button v-if="!isProcessing && !isGetting && list.length > 0" @click="printReport" class="
                    bg-indigo-500
                    text-white
                    active:bg-indigo-600
                    font-bold
                    uppercase
                    text-xs
                    px-4
                    py-2
                    rounded
                    shadow
                    hover:shadow-md
                    outline-none
                    focus:outline-none
                    mr-1
                    ease-linear
                    transition-all
                    duration-150
                  " type="button">
          Print
        </button>
        <button v-if="isProcessing || isGetting" class="
                    bg-lightBlue-500
                    text-white
                    active:bg-lightBlue-600
                    font-bold
                    uppercase
                    text-small
                    px-4
                    py-2
                    rounded
                    shadow
                    hover:shadow-md
                    outline-none
                    focus:outline-none
                    mr-1
                    mb-1
                    ease-linear
                    transition-all
                    duration-150
                  " type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardDocumentationDueTable ref="documentationDue" :color="'light'" :list="list" :isProcessing="isProcessing" />
          <serverside-pager :data="paginateRecords" @page-clicked="getDemarcations" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CustomerSearch from "@/components/Search/CustomerSearch.vue";
import CardDocumentationDueTable from "@/components/Tables/CardDocumentationDueTable.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ServersidePager from "@/components/ServersidePager.vue";

export default {
  name: "DocumentationDue",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      queryString: {
        from: "",
        to: "",
        customer_id: "",
        reg_id: "",
      },
      expenseTypes: [],
      payments: {
        sum: "",
        transaction: [],
      },
      list: [],
      landRegistrations: [],
      paginateRecords: {}
    };
  },
  validations() {
    return {
      queryString: {
        from: { required },
        to: { required },
        customer_id: {},
        reg_id: {},
      },
    };
  },
  components: {
    // CustomerSearch,
    CardDocumentationDueTable,
    ServersidePager
  },
  created() { },
  mounted() {
    this.getList();
  },
  methods: {
    printReport() {
      this.$refs.documentationDue.printReport();
    },
    assignCustomer(client) {
      this.queryString.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.getList();
      }
    },
    getList(pageUrl) {
      let _ = this;
      pageUrl = pageUrl || "due-for-documentation/paginate/yes"
      this.list = []
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get(pageUrl)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.list = res.data.data.data;
            _.paginateRecords = res.data.data
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getLandRegistrationsReport() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("land-registration-report", this.queryString)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.landRegistrations = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
  },
};
</script>
