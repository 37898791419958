<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
      bg-white
      px-4
      py-4
      transition-all
      duration-150
    "
  >
    <form class="flex flex-wrap px-3 py-3">
      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Customer Name
        </label>
        <CustomerSearch @select-client="assignCustomer" />
      </div>

      <div class="w-full lg:w-3/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Department
          </label>
          <select
            name=""
            id=""
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
            v-model="enquiry.department_to"
          >
            <option v-for="(b, i) in departments" :key="i" :value="b.id">
              {{ b.department_name }}
            </option>
          </select>
        </div>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Complaint Type
        </label>
        <select
          name=""
          id=""
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
          v-model="enquiry.enquiry_complaint_type"
        >
          <option v-for="(b, i) in enquiryTypes" :key="i" :value="b.id">
            {{ b.enquiry_type }}
          </option>
        </select>
      </div>

      <div class="relative w-full lg:w-3/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Complaint Status
        </label>
        <select
          name=""
          id=""
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
          v-model="enquiry.enquiry_complaint_status"
        >
          <option v-for="(b, i) in enquiryStatus" :key="i" :value="b.id">
            {{ b.enquiry_status }}
          </option>
        </select>
      </div>

      <div class="relative w-full lg:w-12/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Complaint
        </label>
        <textarea
          name=""
          v-model="enquiry.enquiry_complaint"
          id=""
          cols="30"
          rows="5"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          "
        ></textarea>
      </div>

      <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
        <button
          @click="cancelClose()"
          class="
            bg-blueGray-800
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Cancel &amp; Close
        </button>
      </div>

      <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
        <button
          v-if="!isEdit"
          @click="validateEntry()"
          class="
            bg-lightBlue-500
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Save Complaint
        </button>
        <button
          v-if="isEdit"
          @click="validateEntry()"
          class="
            bg-purple-500
            text-white
            active:bg-purple-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Update Complaint
        </button>
      </div>

      <div v-if="isProcessing" class="relative w-full lg:w-12/12 px-3 mt-3">
        <button
          class="
            bg-emerald-500
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import CustomerSearch from "@/components/Search/CustomerSearch.vue";

export default {
  name: "CardEnquiry",
  props: {
    isEdit: {
      type: Boolean,
    },
    enquiryToUpdate: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      enquiry: {
        customer_id: "",
        department_to: "",
        enquiry_complaint: "",
        enquiry_complaint_type: "",
        enquiry_complaint_status: "",
      },
      departments: [],
      enquiryTypes: [],
      enquiryStatus: [],
    };
  },
  validations() {
    return {
      enquiry: {
        customer_id: { required },
        department_to: { required },
        enquiry_complaint: { required },
        enquiry_complaint_type: { required },
        enquiry_complaint_status: { required },
      },
    };
  },
  components: {
    CustomerSearch,
  },
  watch: {
    enquiryToUpdate() {
      if (!Object.keys(this.enquiryToUpdate).length) {
        return;
      } else {
        this.enquiry = this.enquiryToUpdate;
      }
    },
  },
  created() {},
  mounted() {
    this.emitter.on("emitEnquiry", (enquiry) => {
      this.enquiry = enquiry;
    });
    this.company = this.$store.getters.getCompanyInfo;
    this.getDepartments();
    this.getEnquiryStatus();
    this.getEnquiryTypes();
    if (!Object.keys(this.enquiryToUpdate).length) {
      return;
    } else {
      this.enquiry = this.enquiryToUpdate;
    }
  },
  methods: {
    assignCustomer(customer) {
      this.enquiry.customer_id = customer.id;
    },
    cancelClose() {
      this.enquiry = {
        customer_id: "",
        department_to: "",
        enquiry_complaint: "",
        enquiry_complaint_type: "",
        enquiry_complaint_status: "",
      };
      this.$emit("clearData");
      this.toggleModal();
    },
    toggleModal() {
      this.$emit("toggle-modal");
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        if (!this.isEdit) {
          this.saveEnquiry();
        } else {
          this.postUpdateEnquiry();
        }
      }
    },
    getDepartments() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("department")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.departments = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    postUpdateEnquiry() {
      let _ = this;
      this.enquiry.enquiry_id = this.enquiry.id;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("enquiry-complaint-update", this.enquiry)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.cancelClose();
            _.$emit("get-enquiries");
            _.$wkToast("Saved!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    saveEnquiry() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("enquiry-complaint-store", this.enquiry)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.cancelClose();
            _.$emit("get-enquiries");
            _.$wkToast("Saved!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getEnquiryTypes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("enquiry-types")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.enquiryTypes = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getEnquiryStatus() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("enquiry-status")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.enquiryStatus = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
};
</script>
