<template>
  <div>
    <VueMultiselect
      v-model="selectedCustomer"
      @select="emitData"
      :multiple="false"
      :internal-search="false"
      :options="customers"
      :track-by="customers.id"
      open-direction="bottom"
      :loading="isLoading"
      @search-change="searchAsync"
      placeholder="Type To Search"
      :max-height="600"
      :hide-selected="true"
      :options-limit="300"
      :limit="3"
      :custom-label="customLabel"
    >
      <!-- <span slot="noResult">
        Oops! No elements found. Consider changing the search query.
      </span> -->
    </VueMultiselect>
  </div>
</template>
<script>
import VueMultiselect from "vue-multiselect";
// import lodash from "lodash";

export default {
  props: {
    parsedCustomer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedCustomer: {},
      customers: [],
      keyword: "",
      isLoading: false,
    };
  },
  components: {
    VueMultiselect,
  },
  created() {
    // Requiring lodash library
    const _ = require("lodash");

    this.debouncedSearchClient = _.debounce(this.searchClient, 300);
  },
  methods: {
    toggleModal() {
      this.$emit("toggle-modal");
    },
    searchClient() {
      let _ = this;
      this.customers = [];
      this.isLoading = false;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customers/" + this.keyword)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.customers = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isLoading = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isLoading = false;
        });
    },
    emitData() {
      this.$emit("select-client", this.selectedCustomer);
    },
    searchAsync(query) {
      this.keyword = query;
      if (query != null && query != "") {
        this.debouncedSearchClient();
      }
    },
    customLabel({ actual_buyer_name , name }) {
      if (actual_buyer_name || name) {
        return `${actual_buyer_name} [${name}]`;
      } else {
        return "Search Customer";
      }
    },
  },
  watch: {
    selectedCustomer() {
      if (Object.keys(this.selectedCustomer).length > 0) {
        this.emitData();
      } else {
        this.selectedCustomer = [];
      }
    },
    parsedCustomer() {
      if (Object.keys(this.parsedCustomer).length > 0) {
        this.selectedCustomer = this.parsedCustomer;
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
