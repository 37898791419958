import { createStore } from 'vuex'

export default createStore({
    state: {
        isLoggedIn: true,
        storagePath: '/',
        company: {
            name_of_company: '',
            phone: '',
            email: '',
            address: '',
            location: '',
            logo: '',
        },
        storeDepartments: {
            superAdmin: 0,
            admin: 4,
            projects: 5,
            hr: 6,
            accounts: 7,
            marketing: 8
        },
        department: 0,
    },
    getters: {
        getLoggedInStatus: state => state.isLoggedIn,
        getStoragePath: state => state.storagePath,
        getCompanyInfo: state => state.company,
        getStoreDepartments: state => state.storeDepartments,
    },
    mutations: {
        setCompanyInfo(state, value) {
            state.company = value
        },
        setDepartment(state, value) {
            state.department = value
        }
    },
    actions: {
        setCompanyInfo(context) {
            if (localStorage.getItem("companyInfo") !== null && localStorage !== undefined) {
                const value = JSON.parse(localStorage.getItem('companyInfo'))
                context.commit('setCompanyInfo', value)
            }
        },
        setDepartment(context) {
            if (localStorage.getItem("department") !== null && localStorage !== undefined) {
                const value = JSON.parse(localStorage.getItem('department'))
                context.commit('setDepartment', value)
            }
        }
    },
    modules: {
    }
})
