<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg bg-blueGray-100">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          Land Range Allocations
        </h6>
        <button v-if="!isProcessing && !isGetting" @click="validateEntry()"
          class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button">
          Save Range Allocation
        </button>
        <button v-if="isProcessing || isGetting"
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-small px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Land Site
            </label>
            <select name="" id=""
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              v-model="range.land_site_id">
              <option v-for="(s, i) in sites" :key="i" :value="s.id">
                {{ s.site_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Plot Demarcation
            </label>
            <select name="" id=""
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              v-model="range.land_demarcation_id">
              <option v-for="(b, i) in demarcations" :key="i" :value="b.id">
                {{ b.demarcation_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Plot Usage
            </label>
            <select name="" id=""
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              v-model="range.land_usage_id">
              <option v-for="(b, i) in purchaseUsages" :key="i" :value="b.id">
                {{ b.purchase_usage }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Land Range From
            </label>
            <input v-model="range.land_range_from" type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Land Range To
            </label>
            <input v-model="range.land_range_to" type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Number of Plots
            </label>
            <input v-model="range.demarcation_plot_qty" type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardLandRangingTable :color="'light'" :ranges="ranging" :currencies="currencies" :isProcessing="isProcessing"
            @delete-ranges="deleteRanges" @update-ranges="updateRanges" />
          <serverside-pager :data="paginateRecords" @page-clicked="getRanges" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardLandRangingTable from "@/components/Tables/CardLandRangingTable";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ServersidePager from "@/components/ServersidePager.vue";
export default {
  name: "CardLandRanging",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      range: {
        land_site_id: "",
        land_demarcation_id: "",
        land_range_from: "",
        land_range_to: "",
        demarcation_plot_qty: "",
        land_usage_id: "",
      },
      branches: [],
      pricing: [],
      sites: [],
      currencies: [],
      demarcations: [],
      purchaseUsages: [],
      demarcation_plot_qty: "",
      paginateRecords: {}
    };
  },
  validations() {
    return {
      range: {
        land_site_id: { required },
        land_demarcation_id: { required },
        land_range_from: { required },
        land_range_to: { required },
        demarcation_plot_qty: { required },
        land_usage_id: { required },
      },
    };
  },
  components: {
    CardLandRangingTable,
    ServersidePager,
  },
  created() { },
  mounted() {
    this.getRanges();
    this.getSites();
    this.getDemarcations();
    this.getPurchaseUsages();
  },
  methods: {
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveRanging();
      }
    },
    getPurchaseUsages() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("purchase-usage")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.purchaseUsages = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getDemarcations() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-demarcation/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.demarcations = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getSites() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-sites")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.sites = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getRanges(pageUrl) {
      let _ = this;
      this.ranging = []
      pageUrl = pageUrl || "land-ranging/paginate/yes";
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get(pageUrl)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.ranging = res.data.data.data;
            _.paginateRecords = res.data.data
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCurrencies() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("currencies")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.currencies = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    saveRanging() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("land-ranging-store", this.range)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getRanges();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    deleteRanges(range) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("land-ranging-delete/" + range.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getRanges();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updateRanges(range) {
      this.range = range;
      this.demarcation_plot_qty = this.range.demarcation_plot_qty;
      this.isEdit = true;
    },
    emptyEntry() {
      this.range = {
        land_site_id: "",
        land_demarcation_id: "",
        land_range_from: "",
        land_range_to: "",
        demarcation_plot_qty: "",
        land_usage_id: "",
      };
    },
  },
  computed: {},
  watch: {},
};
</script>
