<template>
  <div class="
            relative
            flex flex-col
            min-w-0
            break-words
            bg-white
            w-full
            mb-6
            shadow-xl
            rounded-lg
            bg-blueGray-100
          ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Customer Balances</h6>
        <!-- <button
          v-if="!isProcessing && !isGetting"
          @click="validateEntry()"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Get Expense Payments
        </button> -->
        <button v-if="isProcessing || isGetting" class="
                  bg-lightBlue-500
                  text-white
                  active:bg-lightBlue-600
                  font-bold
                  uppercase
                  text-small
                  px-4
                  py-2
                  rounded
                  shadow
                  hover:shadow-md
                  outline-none
                  focus:outline-none
                  mr-1
                  mb-1
                  ease-linear
                  transition-all
                  duration-150
                " type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <!-- <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Date From
            </label>
            <input
              v-model="queryString.from"
              type="date"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Date To
            </label>
            <input
              v-model="queryString.to"
              type="date"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Type of Expense
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="queryString.type_id"
            >
              <option v-for="(b, i) in expenseTypes" :key="i" :value="b.id">
                {{ b.type_name }}
              </option>
            </select>
          </div>
        </div>
      </div> -->
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-auto">
          <CardCustomerBalancesTable :color="'light'" :balances="balances" :isProcessing="isProcessing" />
          <serverside-pager :data="paginateRecords" @page-clicked="getBalances" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardCustomerBalancesTable from "@/components/Tables/CardCustomerBalancesTable.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ServersidePager from "@/components/ServersidePager.vue";
export default {
  name: "CustomerBalances",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      queryString: {
        from: "",
        to: "",
        type_id: "",
      },
      expenseTypes: [],
      payments: [],
      balances: [],
      paginateRecords: {}
    };
  },
  validations() {
    return {
      queryString: {
        from: { required },
        to: { required },
        type_id: {},
      },
    };
  },
  components: {
    CardCustomerBalancesTable,
    ServersidePager,
  },
  created() { },
  mounted() {
    this.getBalances();
  },
  methods: {
    assignCustomer(client) {
      console.log(client);
      this.property.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.getExpensePayments();
      }
    },
    getBalances(pageUrl) {
      let _ = this;
      this.isGetting = true;
      this.balances = [];
      pageUrl = pageUrl || "customer-balances/paginate/yes"
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get(pageUrl)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.balances = res.data.data.data;
            _.paginateRecords = res.data.data
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getExpensePayments() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("expense-payment-report", this.queryString)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.payments = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
  },
};
</script>
