<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold mr-4" v-if="view">Vacancy Details</h6>
        <h6 class="text-blueGray-700 text-xl font-bold mr-4" v-if="!view">Job Vacancies</h6>
        <span>
          <button v-if="!isProcessing && !isGetting && !form && !view" @click="toggleForm()" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          " type="button">
            Create Vacancy
          </button>
          <button v-if="!isProcessing && !isGetting && form" @click="validateEntry()" class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
            float-right
          " type="button">
            Save Previous Employee
          </button>
          <button v-if="!isProcessing && !isGetting && view" @click="resetInterface(true)" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
            float-right
          " type="button">
            Duplicate This Vacancy
          </button>
          <button v-if="!isProcessing && !isGetting && view" @click="resetInterface()" class="
            bg-black
            text-white
            active:bg-black
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
            float-right
          " type="button">
            Go Back
          </button>
          <button v-if="isProcessing || isGetting" class="
            bg-purple-500
            text-white
            active:bg-purple-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
            float-right
          " type="button">
            <i class="fas fa-spinner"></i>
          </button>
        </span>
      </div>
    </div>
    <div class="py-2 px-2">
      <div v-if="form || view" class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Job Title
            </label>
            <input v-model="vacancy.job_title" type="text" :disabled="view" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Deadline
          </label>
          <input v-model="vacancy.deadline" type="date" :disabled="view" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Other Info
          </label>
          <input v-model="vacancy.other_info" type="text" :disabled="view" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " />
        </div>


        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Job Description
            </label>
            <ckeditor :editor="editor" v-model="vacancy.job_description" :disabled="view" :config="editorConfig">
            </ckeditor>
          </div>
        </div>

        <div class="relative w-full lg:w-6/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Job Requirement
          </label>
          <ckeditor :editor="editor" v-model="vacancy.job_requirement" :disabled="view" :config="editorConfig"></ckeditor>
        </div>
      </div>
      <div class="flex flex-wrap" v-if="!view">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardVacancyTable :color="'light'" :vacancies="vacancies" :isProcessing="isProcessing"
            @delete-vacancy="deleteVacancy" @view-vacancy="viewVacancy" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardVacancyTable from "@/components/Tables/CardVacancyTable.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
export default {
  name: "Vacancy",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      form: false,
      view: false,
      vacancy: {
        job_title: "",
        job_description: "",
        job_requirement: "",
        deadline: "",
        other_info: "",
      },
      vacancies: [],
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      }
    };
  },
  validations() {
    return {
      vacancy: {
        job_title: { required },
        job_description: { required },
        job_requirement: { required },
        deadline: { required },
        other_info: { required },
      },
    };
  },
  components: {
    CardVacancyTable,
  },
  created() { },
  mounted() {
    this.getVacancies();
  },
  methods: {
    toggleForm() {
      this.form = !this.form
    },
    printReport() {
      this.$refs.enquiryReport.printReport();
    },
    assignCustomer(client) {
      console.log(client);
      this.property.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveVacancy();
      }
    },
    getVacancies() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("job-vacancy")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.vacancies = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    viewVacancy(vacancy) {
      this.form = false
      this.view = !this.view
      this.vacancy = vacancy
    },
    deleteVacancy(vacancy) {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("vacancy-delete/" + vacancy.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getVacancies();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    saveVacancy() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("create-vacancy", this.vacancy)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.toggleForm();
            _.getVacancies();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    resetInterface(duplicate) {
      if (duplicate) {
        delete this.vacancy.id
        this.form = true
      } else {
        this.form = false
        this.emptyEntry()
      }
      this.view = false
    },
    updateGuardian() { },
    emptyEntry() {
      this.vacancy = {
        job_title: "",
        job_description: "",
        job_requirement: "",
        deadline: "",
        other_info: "",
      };
    },
  },
};
</script>
