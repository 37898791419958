<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white px-4 py-4 transition-all duration-150"
  >
    <form class="flex flex-wrap px-3 py-3">
      <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Name
        </label>
        <input
          v-model="v$.user.name.$model"
          type="email"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Name"
        />
      </div>

      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Department
          </label>
          <select
            name=""
            id=""
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            v-model="user.department_id"
          >
            <option v-for="(b, i) in departments" :key="i" :value="b.id">
              {{ b.department_name }}
            </option>
            <option value="0">App Admin</option>
          </select>
        </div>
      </div>

      <div class="w-full lg:w-4/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Is this user a Manager?
          </label>
          <select
            name=""
            id=""
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            v-model="user.is_manager"
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
      </div>

      <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Email
        </label>
        <input
          v-model="user.email"
          type="email"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Email"
        />
      </div>

      <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Password
        </label>
        <input
          v-model="user.password"
          type="password"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Password"
        />
      </div>

      <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Confirm Password
        </label>
        <input
          v-model="user.password_confirmation"
          type="password"
          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Password"
        />
        <label
          v-for="(e, i) in v$.user.password_confirmation.$error"
          :key="i"
          for=""
          class="text-red-500"
          >{{ e.$message }}</label
        >
      </div>

      <div class="relative w-full lg:w-6/12 px-3 mt-3"></div>

      <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
        <button
          @click="cancelClose()"
          class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
          type="button"
        >
          Cancel &amp; Close
        </button>
      </div>

      <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
        <button
          @click="validateEntry()"
          class="bg-lightBlue-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
          type="button"
        >
          Register
        </button>
      </div>

      <div v-if="isProcessing" class="relative w-full lg:w-12/12 px-3 mt-3">
        <button
          class="bg-emerald-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";
export default {
  name: "CardUserRegistration",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      user: {
        name: "",
        type: "1",
        email: "",
        password: "",
        department_id: "",
        is_manager: 0,
        is_admin: 0,
        password_confirmation: "",
      },
      departments: [],
    };
  },
  validations() {
    return {
      user: {
        name: { required },
        email: {
          required,
          email,
        },
        password: {
          required,
        },
        department_id: { required },
        is_manager: { required },
        is_admin: {},
        password_confirmation: {
          required,
          sameAsPassword: sameAs(this.user.password),
        },
      },
    };
  },
  components: {},
  created() {},
  mounted() {
    this.company = this.$store.getters.getCompanyInfo;
    this.getDepartments();
  },
  methods: {
    cancelClose() {
      this.emptyEntry();
      this.toggleModal(false);
    },
    emptyEntry() {
      this.user = {
        name: "",
        type: "1",
        email: "",
        password: "",
        department_id: "",
        is_manager: 0,
        is_admin: 0,
        password_confirmation: "",
      };
    },
    toggleModal(toggle) {
      this.$emit("toggle-modal", toggle);
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.register();
      }
    },
    getDepartments() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("department")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.departments = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    register() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("register", this.user)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.emptyEntry();
            _.$emit("get-users");
            _.$wkToast("Great! This user can now login", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
};
</script>
