<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-4/12">
            <CustomerSearch @select-client="assignCustomer" />
          </div>
          <div class="w-full lg:w-8/12">
            <button
              v-if="
                Object.keys(selectedCustomer).length > 0 && !isGettingCustomer
              "
              @click="getCustomerReport()"
              class="
                bg-emerald-500
                text-white
                active:bg-emerald-600
                font-bold
                uppercase
                text-small
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-2
                mb-1
                ml-3
              "
              type="button"
            >
              <i class="fas fa-search"></i>
              Generate
            </button>
            <button
              v-if="isGettingCustomer"
              class="
                bg-lightBlue-500
                text-white
                active:bg-emerald-600
                font-bold
                uppercase
                text-small
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                mb-1
                ml-2
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              <i class="fas fa-spinner"></i>
            </button>
            <button
              v-if="
                page > 0 &&
                Object.keys(selectedCustomer).length > 0 &&
                Object.keys(bio).length > 0
              "
              @click="page = 0"
              class="
                bg-black
                text-white
                active:bg-slate-100
                font-bold
                uppercase
                text-small
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              <i class="fas fa-arrow-left"></i>
              Go Back
            </button>
          </div>
        </div>
        <div v-if="page == 0" class="flex flex-wrap">
          <button
            v-if="
              page == 0 &&
              Object.keys(selectedCustomer).length > 0 &&
              Object.keys(bio).length > 0
            "
            @click="page = 1"
            class="
              bg-lightBlue-500
              text-white
              active:bg-lightBlue-600
              font-bold
              uppercase
              text-small
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-2
              mb-1
            "
            type="button"
          >
            <i class="fas fa-file"></i>
            Land Documentation
          </button>
          <button
            v-if="
              page == 0 &&
              Object.keys(selectedCustomer).length > 0 &&
              Object.keys(bio).length > 0
            "
            @click="page = 2"
            class="
              bg-indigo-500
              text-white
              active:bg-indigo-600
              font-bold
              uppercase
              text-small
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-2
              mb-1
            "
            type="button"
          >
            <i class="fas fa-file"></i>
            Land Registration
          </button>
          <button
            v-if="
              page == 0 &&
              Object.keys(selectedCustomer).length > 0 &&
              Object.keys(bio).length > 0
            "
            @click="page = 3"
            class="
              bg-purple-500
              text-white
              active:bg-purple-600
              font-bold
              uppercase
              text-small
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-2
              mb-1
            "
            type="button"
          >
            <i class="fas fa-receipt"></i>
            Payment
          </button>
          <button
            v-if="
              page == 0 &&
              Object.keys(selectedCustomer).length > 0 &&
              Object.keys(bio).length > 0
            "
            @click="page = 4"
            class="
              bg-orange-500
              text-white
              active:bg-orange-600
              font-bold
              uppercase
              text-small
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-2
              mb-1
            "
            type="button"
          >
            <i class="fas fa-user"></i>
            Next of Kin
          </button>
          <button
            v-if="
              page == 0 &&
              Object.keys(selectedCustomer).length > 0 &&
              Object.keys(bio).length > 0
            "
            @click="page = 5"
            class="
              bg-pink-500
              text-white
              active:bg-pink-600
              font-bold
              uppercase
              text-small
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-2
              mb-1
            "
            type="button"
          >
            <i class="fas fa-home"></i>
            Property
          </button>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap" v-show="page == 0">
      <div class="w-full lg:w-8/12">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4 mt-6">
            <CardProperties
              :properties="properties"
              :isProcessing="isProcessing"
              :isGetting="isGetting"
            />
          </div>
          <div class="w-full lg:w-12/12 px-4">
            <CardTransactions
              :transactions="transactions"
              :isProcessing="isProcessing"
              :isGetting="isGetting"
              :sum="transactionsSum"
            />
          </div>
        </div>
      </div>
      <div class="w-full lg:w-4/12" v-show="bio.id">
        <CardBio
          :bio="bio"
          :customer="selectedCustomer"
          :isProcessing="isProcessing"
          :isGetting="isGetting"
        />
      </div>
    </div>
    <div class="flex flex-wrap" v-if="page == 1">
      <div class="w-full lg:w-12/12 mt-6">
        <CardLandDocumentation
          :customerId="selectedCustomer.id"
          :isProcessing="isProcessing"
          :isGetting="isGetting"
        />
      </div>
    </div>
    <div class="flex flex-wrap" v-if="page == 2">
      <div class="w-full lg:w-12/12 px-8 mt-6">
        <CardLandRegistration
          :customerId="selectedCustomer.id"
          :isProcessing="isProcessing"
          :isGetting="isGetting"
        />
      </div>
    </div>
    <div class="flex flex-wrap" v-if="page == 3">
      <div class="w-full lg:w-12/12 px-8 mt-6">
        <CustomerPayments
          :parsedSelectedCustomer="selectedCustomer"
          :isProcessing="isProcessing"
          :isGetting="isGetting"
        />
      </div>
    </div>
    <div class="flex flex-wrap" v-if="page == 4">
      <div class="w-full lg:w-12/12 mt-6">
        <CardCustomerNextOfKin :parsedSelectedCustomer="selectedCustomer" />
      </div>
    </div>
    <div class="flex flex-wrap" v-if="page == 5">
      <div class="w-full lg:w-12/12 mt-6">
        <CustomerProperty :parsedSelectedCustomer="selectedCustomer" />
      </div>
    </div>
  </div>
</template>
<script>
import CustomerSearch from "@/components/Search/CustomerSearch.vue";
import CardBio from "@/components/Cards/CardCustomerCentreBio.vue";
import CardProperties from "@/components/Cards/CardCustomerCentreProperties.vue";
import CardTransactions from "@/components/Cards/CardCustomerCentreTransactions.vue";
import CardLandDocumentation from "@/components/Cards/CardLandDocumentation.vue";
import CardLandRegistration from "@/components/Cards/CardLandRegistration.vue";
import CustomerPayments from "@/views/accounts/CustomerPayments.vue";
import CardCustomerNextOfKin from "@/components/Cards/CardCustomerNextOfKin.vue";
import CustomerProperty from "@/views/marketing/CustomerProperty.vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: "Customers",
  components: {
    CustomerSearch,
    CardBio,
    CardProperties,
    CardTransactions,
    CardLandRegistration,
    CardLandDocumentation,
    CustomerPayments,
    CardCustomerNextOfKin,
    CustomerProperty,
  },
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      isGetting: false,
      customers: [],
      isEdit: false,
      isGettingCustomer: false,
      page: 0,
      user: {
        name: "",
        type: "1",
        email: "",
        password: "",
        password_confirmation: "",
      },
      showModal: false,
      countries: [],
      identifications: [],
      branches: [],
      referrals: [],
      selectedCustomer: {},
      transactions: [],
      bio: {},
      properties: [],
      transactionsSum: "",
    };
  },
  created() {
    // this.emitter.on("view-customer-details", (data) => {
    //   this.selectedCustomer = data;
    //   this.showModal = true;
    // });
  },
  mounted() {},
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    clearSelectedCustomer() {
      this.selectedCustomer = {};
      this.isEdit = false;
    },
    viewSelectedCustomer(data) {
      this.selectedCustomer = data;
      this.isEdit = true;
      this.showModal = true;
    },
    assignCustomer(client) {
      this.selectedCustomer = client;
    },
    getReferrals() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("referrals")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.referrals = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCustomerReport() {
      if (this.isGettingCustomer) {
        return;
      }
      this.page = 0;
      this.isGettingCustomer = true;
      let _ = this;
      this.emptyData();
      let obj = {};
      obj.customer_id = this.selectedCustomer.id;
      if (!Object.keys(obj).length) {
        this.isGettingCustomer = false;
        this.getCustomerReport();
      }
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-report", obj)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.properties = res.data.data.property;
            _.transactions = res.data.data.transaction;
            let bioRes = res.data.data.customer_account;
            if (bioRes == null) {
              _.bio = {};
            } else {
              _.bio = res.data.data.customer_account;
            }
            _.transactionsSum = res.data.data.sum;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGettingCustomer = false;
        })
        .catch((err) => {
          _.isGettingCustomer = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getCountries() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("countries")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.countries = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getIdentifications() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("identifications")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.identifications = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    emptyData() {
      this.transactions = -[];
      this.bio = {};
      this.properties = [];
      this.transactionsSum = "";
    },
    saveDocumentation() {},
  },
};
</script>
