<template>
  <div>
    <div class="rounded-t bg-white mb-0 px-6 py-6 text-center flex justify-between">
      <div class="w-full lg:w-6/12 px-4 text-left">
        <h6 class="text-blueGray-700 text-xl font-bold">Customer Bio Data</h6>
      </div>
    </div>
    <div class="
        relative
        flex flex-col
        min-w-0
        break-words
        w-full
        mb-6
        shadow-lg
        rounded
        bg-white
        px-4
        py-4
        transition-all
        duration-150
      ">
      <!-- CONVERSION FORM -->
      <form v-if="isConvert" class="flex flex-wrap px-3 py-3">
        <!-- <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label
          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          Client Type
        </label>
        <select
          v-model="customerToConvert.customer_type_id"
          class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
            disabled
          "
        >
          <option v-for="(id, i) in customerTypes" :key="i" :value="id.id">
            {{ id.customer_type }}
          </option>
        </select>
      </div> -->
        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Name
          </label>
          <input v-model="customerToConvert.name" type="email" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Name" />
        </div>
        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Actual Buyer Name
          </label>
          <input v-model="customerToConvert.actual_buyer_name" type="email" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Actual Buyer Name" />
        </div>
        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Witness
          </label>
          <input v-model="customerToConvert.witness" type="email" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Name" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Date of birth
          </label>
          <input v-model="customerToConvert.dob" type="date" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Phone number
          </label>
          <div class="flex">
            <CountryCode :savedCountryCode="customerToConvert.country_code" @select-country="selectCountryCode" />
            <input v-model="customerToConvert.phone_number" type="text" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                lg:w-8/12
                ease-linear
                transition-all
                duration-150
              " placeholder="Phone Number" />
          </div>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Address
          </label>
          <input v-model="customerToConvert.address" type="email" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Address" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            TIN
          </label>
          <input v-model="customerToConvert.tin" type="text" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="TIN" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            ID type
          </label>
          <select v-model="customerToConvert.id_type" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(id, i) in identifications" :key="i" :value="id.id">
              {{ id.id_name }}
            </option>
          </select>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            ID number
          </label>
          <input v-model="customerToConvert.id_number" type="text" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="ID number" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Country
          </label>
          <select v-model="customerToConvert.country_id" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(c, i) in countries" :key="i" :value="c.id">
              {{ c.country_name }}
            </option>
          </select>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Email
          </label>
          <input v-model="customerToConvert.email" type="email" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Email" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Digital address
          </label>
          <input v-model="customerToConvert.digital_address" type="text" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Digital address" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Residential permit
          </label>
          <input v-model="customerToConvert.residential_permit" type="text" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Residential permit" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Branch
          </label>
          <select v-model="customerToConvert.branch_id" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(b, i) in branches" :key="i" :value="b.id">
              {{ b.branch_name }}
            </option>
          </select>
        </div>

        <div v-if="1 < 0" class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Sales Rep
          </label>
          <select v-model="customerToConvert.sales_rep" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(b, i) in users" :key="i" :value="b.id">
              {{ b.name }}
            </option>
          </select>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Preferred Land Site
          </label>
          <select v-model="customerToConvert.preferred_site_id" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(r, i) in sites" :key="i" :value="r.id">
              {{ r.site_name }}
            </option>
          </select>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Source of contact
          </label>
          <select v-model="customerToConvert.source_of_contact" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(r, i) in referrals" :key="i" :value="r.id">
              {{ r.source }}
            </option>
          </select>
        </div>
        <!-- <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
          <button
            @click="cancelClose()"
            class="
              bg-blueGray-800
              text-white
              active:bg-blueGray-600
              text-sm
              font-bold
              uppercase
              px-6
              py-3
              rounded
              shadow
              hover:shadow-lg
              outline-none
              focus:outline-none
              mr-1
              mb-1
              w-full
              ease-linear
              transition-all
              duration-150
            "
            type="button"
          >
            Cancel &amp; Close
          </button>
        </div>

        <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
          <button
            @click="validateConversion()"
            class="
              bg-lightBlue-500
              text-white
              active:bg-blueGray-600
              text-sm
              font-bold
              uppercase
              px-6
              py-3
              rounded
              shadow
              hover:shadow-lg
              outline-none
              focus:outline-none
              mr-1
              mb-1
              w-full
              ease-linear
              transition-all
              duration-150
            "
            type="button"
          >
            Save &amp; Convert
          </button>
        </div>

        <div v-if="isProcessing" class="relative w-full lg:w-4/12 px-3 mt-3">
          <button
            class="
              bg-emerald-500
              text-white
              active:bg-blueGray-600
              text-sm
              font-bold
              uppercase
              px-6
              py-3
              rounded
              shadow
              hover:shadow-lg
              outline-none
              focus:outline-none
              mr-1
              mb-1
              w-full
              ease-linear
              transition-all
              duration-150
            "
            type="button"
          >
            <i class="fas fa-spinner"></i>
          </button>
        </div> -->
      </form>
      <!-- END OF CONVERSION FORM -->

      <!-- EDIT FORM -->
      <form v-if="isEdit" class="flex flex-wrap px-3 py-3">
        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Name
          </label>
          <input v-model="v$.customerToEdit.name.$model" type="email" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Name" />
        </div>
        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Actual Buyer Name
          </label>
          <input v-model="v$.customerToEdit.actual_buyer_name.$model" type="email" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Actual Buyer Name" />
        </div>
        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Witness
          </label>
          <input v-model="v$.customerToEdit.witness.$model" type="email" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Name" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Date of birth
          </label>
          <input v-model="customerToEdit.dob" type="date" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Phone number
          </label>
          <div class="flex">
            <CountryCode :savedCountryCode="customerToEdit.country_code" @select-country="selectCountryCode" />
            <input v-model="customerToEdit.phone_number" type="text" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                lg:w-8/12
                ease-linear
                transition-all
                duration-150
              " placeholder="Phone Number" />
          </div>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Address
          </label>
          <input v-model="customerToEdit.address" type="email" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Address" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            TIN
          </label>
          <input v-model="customerToEdit.tin" type="text" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="TIN" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            ID type
          </label>
          <select v-model="customerToEdit.id_type" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(id, i) in identifications" :key="i" :value="id.id">
              {{ id.id_name }}
            </option>
          </select>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            ID number
          </label>
          <input v-model="customerToEdit.id_number" type="text" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="ID number" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Country
          </label>
          <select v-model="customerToEdit.country_id" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(c, i) in countries" :key="i" :value="c.id">
              {{ c.country_name }}
            </option>
          </select>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Email
          </label>
          <input v-model="customerToEdit.email" type="email" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Email" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Digital address
          </label>
          <input v-model="customerToEdit.digital_address" type="text" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Digital address" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Residential permit
          </label>
          <input v-model="customerToEdit.residential_permit" type="text" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            " placeholder="Residential permit" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Branch
          </label>
          <select v-model="customerToEdit.branch_id" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(b, i) in branches" :key="i" :value="b.id">
              {{ b.branch_name }}
            </option>
          </select>
        </div>

        <div v-if="1 < 0" class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Sales Rep
          </label>
          <select v-model="customerToEdit.sales_rep" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(b, i) in users" :key="i" :value="b.id">
              {{ b.name }}
            </option>
          </select>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Source of contact
          </label>
          <select v-model="customerToEdit.source_of_contact" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            ">
            <option v-for="(r, i) in referrals" :key="i" :value="r.id">
              {{ r.source }}
            </option>
          </select>
        </div>
        <!-- <div v-if="!isProcessing" class="relative w-full lg:w-4/12 px-3 mt-3">
          <button
            @click="cancelClose()"
            class="
              bg-blueGray-800
              text-white
              active:bg-blueGray-600
              text-sm
              font-bold
              uppercase
              px-6
              py-3
              rounded
              shadow
              hover:shadow-lg
              outline-none
              focus:outline-none
              mr-1
              mb-1
              w-full
              ease-linear
              transition-all
              duration-150
            "
            type="button"
          >
            Cancel &amp; Close
          </button>
        </div>

        <div v-if="!isProcessing" class="relative w-full lg:w-4/12 px-3 mt-3">
          <button
            @click="validateEntryToEdit()"
            class="
              bg-lightBlue-500
              text-white
              active:bg-blueGray-600
              text-sm
              font-bold
              uppercase
              px-6
              py-3
              rounded
              shadow
              hover:shadow-lg
              outline-none
              focus:outline-none
              mr-1
              mb-1
              w-full
              ease-linear
              transition-all
              duration-150
            "
            type="button"
          >
            Update
          </button>
        </div>

        <div v-if="isProcessing" class="relative w-full lg:w-12/12 px-3 mt-3">
          <button
            class="
              bg-emerald-500
              text-white
              active:bg-blueGray-600
              text-sm
              font-bold
              uppercase
              px-6
              py-3
              rounded
              shadow
              hover:shadow-lg
              outline-none
              focus:outline-none
              mr-1
              mb-1
              w-full
              ease-linear
              transition-all
              duration-150
            "
            type="button"
          >
            <i class="fas fa-spinner"></i>
          </button>
        </div> -->
      </form>
      <!-- END OF EDIT FORM -->

      <div class="
          rounded-t
          bg-white
          mb-0
          px-6
          py-6
          text-center
          flex
          justify-between
        ">
        <div class="w-full lg:w-12/12 px-4 text-right">
          <button v-if="(!isProcessing && !isGetting && isConvert) || isEdit" @click="cancelClose()" class="
              bg-black
              text-white
              active:bg-black
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            " type="button">
            Cancel &amp; Close
          </button>
          <button v-if="!isProcessing && !isGetting && isConvert" @click="validateConversion()" class="
              bg-lightBlue-500
              text-white
              active:bg-lightBlue-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            " type="button">
            Save Details To continue
          </button>
          <button v-if="!isProcessing && !isGetting && isEdit" @click="validateEntryToEdit()" class="
              bg-lightBlue-500
              text-white
              active:bg-lightBlue-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            " type="button">
            Update Details
          </button>
          <!-- <button
            v-if="!isProcessing && !isGetting && isEdit"
            @click="nextPage()"
            class="
              bg-purple-500
              text-white
              active:bg-indigo-600
              font-bold
              uppercase
              text-xs
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
          >
            To Next of Kin
          </button> -->
          <button v-if="isProcessing || isGetting" class="
              bg-emerald-500
              text-white
              active:bg-emerald-600
              font-bold
              uppercase
              text-small
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              mb-1
              ease-linear
              transition-all
              duration-150
            " type="button">
            <i class="fas fa-spinner"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import CountryCode from "@/components/Search/CountryCodes.vue";
export default {
  name: "CardCustomerRegistration",
  props: {
    countries: {
      type: Array,
    },
    identifications: {
      type: Array,
    },
    branches: {
      type: Array,
    },
    selectedCustomer: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
    },
    isConvert: {
      type: Boolean,
    },
    referrals: {
      type: Array,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      customer: {
        customer_type_id: "",
        name: "",
        actual_buyer_name: "",
        witness: "",
        preferred_site_id: "",
        dob: "",
        country_code: "",
        phone_number: "",
        address: "",
        tin: "",
        id_type: "",
        id_number: "",
        country_id: "",
        email: "",
        digital_address: "",
        residential_permit: "",
        branch_id: "",
        sales_rep: "",
        source_of_contact: "",
      },
      customerToEdit: {},
      users: [],
      customerTypes: [],
      customerTypeId: 1,
      sites: [],
      customerToConvert: {},
    };
  },
  validations() {
    return {
      customer: {
        customer_type_id: { required },
        name: { required },
        actual_buyer_name: { required },
        witness: { required },
        preferred_site_id: { required },
        email: {
          required,
        },
        dob: {},
        country_code: {
          required,
        },
        phone_number: {
          required,
        },
        id_type: {},
        id_number: {},
        country_id: {},
        branch_id: {},
        sales_rep: {},
        source_of_contact: { required },
      },
      customerToEdit: {
        customer_type_id: { required },
        name: { required },
        actual_buyer_name: { required },
        witness: { required },
        preferred_site_id: { required },
        email: {
          required,
        },
        dob: {},
        country_code: {
          required,
        },
        phone_number: {
          required,
        },
        id_type: {},
        id_number: {},
        country_id: {},
        branch_id: {},
        sales_rep: {},
        source_of_contact: { required },
      },
      customerToConvert: {
        customer_type_id: { required },
        name: { required },
        actual_buyer_name: { required },
        witness: { required },
        preferred_site_id: { required },
        email: {
          required,
        },
        dob: { required },
        country_code: { required },
        phone_number: {
          required,
        },
        id_type: { required },
        id_number: { required },
        country_id: { required },
        branch_id: { required },
        sales_rep: {},
        source_of_contact: { required },
      },
    };
  },
  components: { CountryCode },
  created() {
    // this.emitter.on("view-customer-details", (data) => {
    //   this.customer = data.customer;
    // });
  },
  mounted() {
    this.getSites();
    this.company = this.$store.getters.getCompanyInfo;
    this.getCustomerTypes();
    this.getUsers();
    if (!this.isEdit && !this.isConvert) {
      this.customer.customer_type_id = this.customerTypeId;
    }
  },
  methods: {
    selectCountryCode(code) {
      if (this.isEdit) {
        this.customerToEdit.country_code = code;
      }
      if (this.isConvert) {
        this.customerToConvert.country_code = code;
      }
      if (!this.isEdit && !this.isConvert) {
        this.customer.country_code = code;
      }
    },
    getUsers() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("users/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.users = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getCustomerTypes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customer-type")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.customerTypes = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getSites() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-sites")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.sites = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    cancelClose() {
      this.customer = {
        customer_type_id: "1",
        name: "",
        actual_buyer_name: "",
        witness: "",
        preferred_site_id: "",
        dob: "",
        country_code: "",
        phone_number: "",
        address: "",
        tin: "",
        id_type: "",
        id_number: "",
        country_id: "",
        email: "",
        digital_address: "",
        residential_permit: "",
        branch_id: "",
        sales_rep: "",
        source_of_contact: "",
      };
      this.customerToEdit = {
        customer_type_id: "1",
        name: "",
        actual_buyer_name: "",
        witness: "",
        preferred_site_id: "",
        dob: "",
        country_code: "",
        phone_number: "",
        address: "",
        tin: "",
        id_type: "",
        id_number: "",
        country_id: "",
        email: "",
        digital_address: "",
        residential_permit: "",
        branch_id: "",
        sales_rep: "",
        source_of_contact: "",
      };
      this.toggleModal();
      this.$emit("clear-selected-customer");
    },
    toggleModal() {
      this.$emit("toggle-modal");
    },
    emptyCustomer() {
      this.customer = {
        name: "",
        actual_buyer_name: "",
        witness: "",
        dob: "",
        country_code: "",
        phone_number: "",
        address: "",
        tin: "",
        id_type: "",
        id_number: "",
        country_id: "",
        email: "",
        digital_address: "",
        residential_permit: "",
        branch_id: "",
        sales_rep: "",
        source_of_contact: "",
      };
    },
    validateEntry() {
      this.v$.customer.$reset();
      this.v$.customer.$validate();
      if (this.v$.customer.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.register();
      }
    },
    validateConversion() {
      this.v$.customerToConvert.$reset();
      this.v$.customerToConvert.$validate();
      if (this.v$.customerToConvert.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.convertCustomer();
      }
    },
    validateEntryToEdit() {
      this.v$.customerToEdit.$reset();
      this.customerToEdit.customer_type_id = this.selectedCustomer.customer_type
      this.v$.customerToEdit.$validate();
      if (this.v$.customerToEdit.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.updateCustomer();
      }
    },
    register() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-store", this.customer)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$emit("get-customers");
            _.$emit("get-prospects");
            _.$wkToast("Saved! You can continue...", {
              duration: 3000,
              className: "wk-success",
            });
            this.nextPage();
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    updateCustomer() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customerupdate", this.customerToEdit)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.cancelClose();
            _.$emit("get-customers");
            _.$wkToast("Great! Customer Updated.", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    convertCustomer() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customerupdate", this.customerToConvert)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$emit("get-customers");
            _.$emit("get-prospects");
            _.$emit("parse-saved-customer", res.data.data);
            _.$wkToast("Great! Loading Next...", {
              duration: 3000,
              className: "wk-success",
            });
            setTimeout(() => {
              this.nextPage();
            }, 1000);
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
            _.isProcessing = false;
          }
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    nextPage() {
      this.$emit("next-page");
    },
  },
  watch: {
    selectedCustomer() {
      if (this.isEdit) {
        let obj = JSON.stringify(this.selectedCustomer);
        obj = JSON.parse(obj);
        this.customerToEdit = obj;
        this.customerToEdit.sales_rep = obj.sales_rep_id;
      }
      if (this.isConvert) {
        let obj = JSON.stringify(this.selectedCustomer);
        obj = JSON.parse(obj);
        this.customerToConvert = obj;
        this.customerToConvert.customer_type_id = 1;
        this.customerToConvert.sales_rep = obj.sales_rep_id;
        this.customerToConvert.country_code = obj.country_code;
      }
      // else {
      //   this.emptyCustomer();
      // }
    },
    sites() {
      if (Object.keys(this.customerToConvert).length > 0) {
        this.sites.every((e) => {
          if (e.id == this.customerToConvert.preferred_site_id) {
            this.customerToConvert.branch_id = e.branch_id;
            return false;
          }
          return true;
        });
      }
    },
    customerTypeId() {
      this.customer.customer_type_id = this.customerTypeId;
    },
  },
};
</script>
