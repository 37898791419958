<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          Employee Next of Kin
        </h6>
        <button
          v-if="!isProcessing && !isGetting"
          @click="validateEntry()"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Save Next of Kin
        </button>
        <button
          v-if="isProcessing || isGetting"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Employee
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="nok.emp_id"
            >
              <option v-for="(b, i) in employees" :key="i" :value="b.id">
                {{ b.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Next of Kin Name
            </label>
            <input
              v-model="nok.nok_name"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Next of Kin Relation
          </label>
          <input
            v-model="nok.nok_relation"
            type="text"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
          />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Next of Kin Address
          </label>
          <input
            v-model="nok.nok_address"
            type="text"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
          />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Next of Kin Email
          </label>
          <input
            v-model="nok.nok_email"
            type="email"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
          />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Next of Kin Phone
          </label>
          <input
            v-model="nok.nok_phone"
            type="text"
            class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
              ease-linear
              transition-all
              duration-150
            "
          />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardNextofKinTable
            :color="'light'"
            :noks="noks"
            :isProcessing="isProcessing"
            @delete-nok="deleteNok"
            @update-nok="updateNok"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNextofKinTable from "@/components/Tables/CardNextofKinTable.vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
export default {
  name: "Guardian",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      nok: {
        emp_id: "",
        nok_name: "",
        nok_relation: "",
        nok_address: "",
        nok_email: "",
        nok_phone: "",
      },
      noks: [],
      employees: [],
    };
  },
  validations() {
    return {
      nok: {
        emp_id: { required },
        nok_name: { required },
        nok_relation: { required },
        nok_address: { required },
        nok_email: { required, email },
        nok_phone: { required },
      },
    };
  },
  components: {
    CardNextofKinTable,
  },
  created() {},
  mounted() {
    this.getNoks();
    this.getEmployees();
  },
  methods: {
    printReport() {
      this.$refs.enquiryReport.printReport();
    },
    assignCustomer(client) {
      console.log(client);
      this.property.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveNok();
      }
    },
    getNoks() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("nok-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.guardians = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getEmployees() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("employee-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.employees = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    deleteNok(nok) {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("nok-delete/" + nok.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getNoks();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    saveNok() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("nok-store", this.nok)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getNoks();
            _.$wkToast("Success!", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    updateNok() {},
    emptyEntry() {
      this.nok = {
        emp_id: "",
        nok_name: "",
        nok_relation: "",
        nok_address: "",
        nok_email: "",
        nok_phone: "",
      };
    },
  },
};
</script>

