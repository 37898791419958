<template>
  <div class="bg-white ">
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 pb-2 px-2 py-2">
        <h6 class="pl-3 text-blueGray-700 text-xl font-bold mt-3 ml-2">Interview Evaluations</h6>
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center flex mb-4">
            <select name="selectedJobVacancy" v-model="selectedJobVacancy" id="" class="border-0
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                ease-linear
                transition-all
                duration-150
                mr-5">
              <option></option>
              <option v-for="(v, i) in jobVacancies" :key="i" :value="v">{{ v.job_title }}</option>
            </select>
            <ShortlistedApplicantsSearch @select-applicant="assignApplicant" />
            <button v-if="isProcessing || isGetting" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          " type="button">
              <i class="fas fa-spinner"></i>
            </button>
          </div>
          <div class="w-full lg:w-12/12">
            <CardJobApplicationsTable :color="'light'" @shortlistArray="alterShortListArray" :list="page"
              :jobApplications="jobApplications" :isProcessing="isProcessing" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ShortlistedApplicantsSearch from "@/components/Search/ShortlistedApplicantsSearch.vue";
import useVuelidate from "@vuelidate/core";
import CardJobApplicationsTable from "@/components/Tables/CardJobApplicationsTable.vue";

export default {
  name: "InterviewEvaluations",
  components: {
    ShortlistedApplicantsSearch,
    CardJobApplicationsTable,
  },
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      isGetting: false,
      customers: [],
      isEdit: false,
      isGettingApplicant: false,
      page: 0,
      user: {
        name: "",
        type: "1",
        email: "",
        password: "",
        password_confirmation: "",
      },
      showModal: false,
      countries: [],
      identifications: [],
      branches: [],
      referrals: [],
      selectedApplicant: {},
      transactions: [],
      bio: {},
      properties: [],
      transactionsSum: "",
      selectedJobVacancy: {},
      jobVacancies: []
    };
  },
  created() {
    // this.emitter.on("view-customer-details", (data) => {
    //   this.selectedApplicant = data;
    //   this.showModal = true;
    // });
  },
  mounted() {
    this.getJobVacancies();
  },
  watch: {
  },
  methods: {
    getJobVacancies() {
      let _ = this
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("job-vacancy")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.jobVacancies = res.data.data
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    clearSelectedApplicant() {
      this.selectedApplicant = {};
      this.isEdit = false;
    },
    viewSelectedApplicant(data) {
      this.selectedApplicant = data;
      this.isEdit = true;
      this.showModal = true;
    },
    assignApplicant(applicant) {
      this.selectedApplicant = applicant;
    },
    getReferrals() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("referrals")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.referrals = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCustomerReport() {
      if (this.isGettingApplicant) {
        return;
      }
      this.page = 0;
      this.isGettingApplicant = true;
      let _ = this;
      this.emptyData();
      let obj = {};
      obj.customer_id = this.selectedApplicant.id;
      if (!Object.keys(obj).length) {
        this.isGettingApplicant = false;
        this.getCustomerReport();
      }
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-report", obj)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.properties = res.data.data.property;
            _.transactions = res.data.data.transaction;
            let bioRes = res.data.data.customer_account;
            if (bioRes == null) {
              _.bio = {};
            } else {
              _.bio = res.data.data.customer_account;
            }
            _.transactionsSum = res.data.data.sum;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGettingApplicant = false;
        })
        .catch((err) => {
          _.isGettingApplicant = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getCountries() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("countries")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.countries = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getIdentifications() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("identifications")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.identifications = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    emptyData() {
      this.transactions = -[];
      this.bio = {};
      this.properties = [];
      this.transactionsSum = "";
    },
    saveDocumentation() { },
  },
};
</script>
