<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
      bg-white
      px-4
      py-4
      transition-all
      duration-150
    ">
    <form v-if="!isEdit && !isConvert" class="flex flex-wrap px-3 py-3">
      <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
          Name
        </label>
        <input v-model="customer.name" type="email" class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          " placeholder="Name" />
      </div>

      <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
          Phone number
        </label>
        <div class="flex">
          <CountryCode @select-country="selectCountryCode" />
          <input v-model="customer.phone_number" type="number" class="
              border-0
              px-3
              py-3
              placeholder-blueGray-300
              text-blueGray-600
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              lg:w-8/12
              ease-linear
              transition-all
              duration-150
            " placeholder="Phone Number" />
        </div>
      </div>

      <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
          Email
        </label>
        <input v-model="customer.email" type="email" class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          " placeholder="Email" />
      </div>

      <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
          Preferred Land Site
        </label>
        <select v-model="customer.preferred_site_id" class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          ">
          <option v-for="(r, i) in sites" :key="i" :value="r.id">
            {{ r.site_name }}
          </option>
        </select>
      </div>

      <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
          Sales Rep
        </label>
        <select v-model="customer.sales_rep" class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          ">
          <option v-for="(b, i) in users" :key="i" :value="b.id">
            {{ b.name }}
          </option>
        </select>
      </div>

      <div class="relative w-full lg:w-4/12 px-3 mb-3">
        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
          Source of contact
        </label>
        <select v-model="customer.source_of_contact" class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
          ">
          <option v-for="(r, i) in referrals" :key="i" :value="r.id">
            {{ r.source }}
          </option>
        </select>
      </div>
      <br />
      <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
        <button @click="cancelClose()" class="
            bg-blueGray-800
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          " type="button">
          Cancel &amp; Close
        </button>
      </div>

      <div v-if="!isProcessing" class="relative w-full lg:w-6/12 px-3 mt-3">
        <button @click="validateEntry()" class="
            bg-lightBlue-500
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          " type="button">
          <sapn>Save</sapn>
        </button>
      </div>

      <div v-if="isProcessing" class="relative w-full lg:w-12/12 px-3 mt-3">
        <button class="
            bg-emerald-500
            text-white
            active:bg-blueGray-600
            text-sm
            font-bold
            uppercase
            px-6
            py-3
            rounded
            shadow
            hover:shadow-lg
            outline-none
            focus:outline-none
            mr-1
            mb-1
            w-full
            ease-linear
            transition-all
            duration-150
          " type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import CountryCode from "@/components/Search/CountryCodes.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "CardNewProspectBooking",
  props: {
    countries: {
      type: Array,
    },
    identifications: {
      type: Array,
    },
    branches: {
      type: Array,
    },
    selectedCustomer: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
    },
    isConvert: {
      type: Boolean,
    },
    referrals: {
      type: Array,
    },
  },
  data() {
    return {
      deepprops: {
        preferredCountries: ["US", "GB"],
        placeholder: "Enter your phone",
        mode: "international",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        wrapperClasses: "country-phone-input",
      },
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      customer: {
        customer_type_id: "2",
        name: "",
        preferred_site_id: "",
        dob: "",
        country_code: "",
        phone_number: "",
        address: "",
        tin: "",
        id_type: "",
        id_number: "",
        country_id: "",
        email: "",
        digital_address: "",
        residential_permit: "",
        branch_id: "",
        sales_rep: localStorage.getItem("salesRepId"),
        source_of_contact: "",
      },
      customerToEdit: {},
      users: [],
      customerTypes: [],
      customerTypeId: 2,
      sites: [],
      customerToConvert: {},
      countryCode: "",
    };
  },
  validations() {
    return {
      customer: {
        customer_type_id: { required },
        name: { required },
        preferred_site_id: { required },
        email: {
          required,
        },
        dob: {},
        country_code: { required },
        phone_number: {
          required,
        },
        id_type: {},
        id_number: {},
        country_id: {},
        branch_id: {},
        sales_rep: {},
        source_of_contact: { required },
      },
      customerToEdit: {
        customer_type_id: { required },
        name: { required },
        preferred_site_id: { required },
        email: {
          required,
        },
        dob: {},
        phone_number: {
          required,
        },
        id_type: {},
        id_number: {},
        country_id: {},
        branch_id: {},
        sales_rep: {},
        source_of_contact: { required },
      },
      customerToConvert: {
        customer_type_id: { required },
        name: { required },
        preferred_site_id: { required },
        email: {
          required,
        },
        dob: { required },
        phone_number: {
          required,
        },
        id_type: { required },
        id_number: { required },
        country_id: { required },
        branch_id: { required },
        sales_rep: {},
        source_of_contact: { required },
      },
    };
  },
  components: {
    CountryCode,
  },
  created() {
    // this.emitter.on("view-customer-details", (data) => {
    //   this.customer = data.customer;
    // });
  },
  mounted() {
    this.company = this.$store.getters.getCompanyInfo;
    this.getCustomerTypes();
    this.getSites();
    this.getUsers();
    if (!this.isEdit && !this.isConvert) {
      this.customer.customer_type_id = this.customerTypeId;
    }
  },
  methods: {
    selectCountryCode(code) {
      this.countryCode = code;
      this.customer.country_code = code;
    },
    getUsers() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("users/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.users = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getCustomerTypes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customer-type")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.customerTypes = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getSites() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-sites")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.sites = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    cancelClose() {
      this.customer = {
        customer_type_id: "2",
        name: "",
        preferred_site_id: "",
        dob: "",
        country_code: this.countryCode,
        phone_number: "",
        address: "",
        tin: "",
        id_type: "",
        id_number: "",
        country_id: "",
        email: "",
        digital_address: "",
        residential_permit: "",
        branch_id: "",
        sales_rep: localStorage.getItem("salesRepId"),
        source_of_contact: "",
      };
      this.customerToEdit = {
        customer_type_id: "2",
        name: "",
        preferred_site_id: "",
        dob: "",
        country_code: this.countryCode,
        phone_number: "",
        address: "",
        tin: "",
        id_type: "",
        id_number: "",
        country_id: "",
        email: "",
        digital_address: "",
        residential_permit: "",
        branch_id: "",
        sales_rep: localStorage.getItem("salesRepId"),
        source_of_contact: "",
      };
      this.toggleModal();
      this.$emit("clear-selected-customer");
    },
    toggleModal() {
      this.$emit("toggle-modal");
    },
    emptyCustomer() {
      this.customer = {
        name: "",
        dob: "",
        country_code: "",
        phone_number: "",
        address: "",
        tin: "",
        id_type: "",
        id_number: "",
        country_id: "",
        email: "",
        digital_address: "",
        residential_permit: "",
        branch_id: "",
        sales_rep: localStorage.getItem("salesRepId"),
        source_of_contact: "",
      };
    },
    validateEntry() {
      this.v$.customer.$reset();
      this.v$.customer.$validate();
      if (this.v$.customer.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.register();
      }
    },
    validateConversion() {
      this.v$.customerToConvert.$reset();
      this.v$.customerToConvert.$validate();
      if (this.v$.customerToConvert.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.convertCustomer();
      }
    },
    validateEntryToEdit() {
      this.v$.customerToEdit.$reset();
      this.v$.customerToEdit.$validate();
      if (this.v$.customerToEdit.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.updateCustomer();
      }
    },
    register() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-store", this.customer)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.cancelClose();
            _.$emit("get-customers");
            _.$emit("get-prospects");
            _.$wkToast("Great! Prospect Added.", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    updateCustomer() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .put("customer-store", this.selectedCustomer)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.cancelClose();
            _.$emit("get-customers");
            _.$wkToast("Great! Customer Added.", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    convertCustomer() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customerupdate", this.customerToConvert)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.cancelClose();
            _.$emit("get-customers");
            _.$wkToast("Great! Customer Converted.", {
              duration: 3000,
              className: "wk-success",
            });
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
  watch: {
    selectedCustomer() {
      if (this.isEdit) {
        this.customerToEdit = this.selectedCustomer;
      }
      if (this.isConvert) {
        this.customerToConvert = this.selectedCustomer;
        this.customerToConvert.customer_type_id = 1;
      }
      // else {
      //   this.emptyCustomer();
      // }
    },
    customerTypeId() {
      this.customer.customer_type_id = this.customerTypeId;
    },
  },
};
</script>
