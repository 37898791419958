<template>
  <div>
    <VueMultiselect
      v-model="selectedApplicant"
      @select="emitData"
      :multiple="false"
      :internal-search="false"
      :options="applicants"
      :track-by="applicants.id"
      open-direction="bottom"
      :loading="isLoading"
      @search-change="searchAsync"
      placeholder="Type To Search"
      :max-height="600"
      :hide-selected="true"
      :options-limit="300"
      :limit="3"
      :custom-label="customLabel"
    >
      <!-- <span slot="noResult">
        Oops! No elements found. Consider changing the search query.
      </span> -->
    </VueMultiselect>
  </div>
</template>
<script>
import VueMultiselect from "vue-multiselect";
// import lodash from "lodash";

export default {
  props: {
    parsedApplicant: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedApplicant: {},
      applicants: [],
      keyword: "",
      isLoading: false,
    };
  },
  components: {
    VueMultiselect,
  },
  created() {
    // Requiring lodash library
    const _ = require("lodash");

    this.debouncedSearchApplicant = _.debounce(this.searchApplicant, 300);
  },
  methods: {
    toggleModal() {
      this.$emit("toggle-modal");
    },
    searchApplicant() {
      let _ = this;
      this.Applicants = [];
      this.isLoading = false;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("search-shortlist/" + this.keyword)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.applicants = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isLoading = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isLoading = false;
        });
    },
    emitData() {
      this.$emit("select-applicant", this.selectedApplicant);
    },
    searchAsync(query) {
      this.keyword = query;
      if (query != null && query != "") {
        this.debouncedSearchApplicant();
      }
    },
    customLabel({ full_name, email_address }) {
      if (full_name || email_address) {
        return `${full_name} - ${email_address}`;
      } else {
        return "Search Shortlisted Applicant";
      }
    },
  },
  watch: {
    selectedApplicant() {
      if (Object.keys(this.selectedApplicant).length > 0) {
        this.emitData();
      } else {
        this.selectedApplicant = [];
      }
    },
    parsedApplicant() {
      if (Object.keys(this.parsedApplicant).length > 0) {
        this.selectedApplicant = this.parsedApplicant;
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
