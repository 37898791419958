<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg bg-blueGray-100">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Land Pricing</h6>
        <button v-if="!isProcessing && !isGetting" @click="validateEntry()"
          class="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button">
          Save Land Price
        </button>
        <button v-if="isProcessing || isGetting"
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-small px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Land Range Allocated
            </label>
            <!-- <select
              name=""
              id=""
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              v-model="price.land_range_id"
            >
              <option v-for="(s, i) in ranges" :key="i" :value="s.id">
                {{
                  `${s.site_name} ${s.demarcation_name} [ FROM PLOT ${s.land_range_from} TO PLOT ${s.land_range_to} ]`
                }}
              </option>
            </select> -->
            <VueMultiselect v-model="selectedAllocation" @select="emitData" :multiple="false" :internal-search="true"
              :options="ranges" :track-by="ranges.id" open-direction="bottom" :loading="isLoading"
              placeholder="Type To Filter Range Allocation" :max-height="600" :hide-selected="true" :options-limit="300"
              :limit="3" :custom-label="customLabel">
            </VueMultiselect>
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Price
            </label>
            <input v-model="price.land_price" type="number" min="1"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Currency
            </label>
            <select name="" id=""
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              v-model="price.currency_id">
              <option v-for="(c, i) in currencies" :key="i" :value="c.id">
                {{ c.currency_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4" v-if="Object.keys(selectedAllocation).length > 0">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Land Usage
            </label>
            <p>{{ selectedAllocation.purchase_usage }}</p>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardLandPricingTable :color="'light'" :pricing="pricing" :currencies="currencies" :isProcessing="isProcessing"
            @delete-pricing="deletePricing" @update-pricing="updatePricing" />

          <serverside-pager :data="paginateRecords" @page-clicked="getPricing" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardLandPricingTable from "@/components/Tables/CardLandPricingTable";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import VueMultiselect from "vue-multiselect";
import ServersidePager from "@/components/ServersidePager.vue";
// import lodash from "lodash";

export default {
  name: "CardPricing",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      keyword: "",
      isLoading: false,
      price: {
        land_range_id: "",
        land_price: "",
        currency_id: "",
      },
      branches: [],
      pricing: [],
      ranges: [],
      currencies: [],
      demarcations: [],
      purchaseUsages: [],
      selectedAllocation: {},
      paginateRecords: {}
    };
  },
  validations() {
    return {
      price: {
        land_range_id: { required },
        land_price: { required },
        currency_id: {},
      },
    };
  },
  components: {
    CardLandPricingTable,
    ServersidePager,
    VueMultiselect,
  },
  created() {
    // Requiring lodash library
    // const _ = require("lodash");
    // this.debouncedSearchAllocation = _.debounce(this.searchClient, 300);
  },
  mounted() {
    this.getCurrencies();
    this.getRanges();
    this.getDemarcations();
    this.getPricing();
    this.getPurchaseUsages();
  },
  methods: {
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.price.demarcation_plot_qty =
          Number(this.price.land_range_to - this.price.land_range_from) + 1;
        this.savePricing();
      }
    },
    getPurchaseUsages() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("purchase-usage")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.purchaseUsages = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getDemarcations() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-demarcation/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.demarcations = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getRanges() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-ranging/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.ranges = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPricing(pageUrl) {
      let _ = this;
      this.pricing = []
      this.isGetting = true;
      pageUrl = pageUrl || "land-pricing/paginate/yes"
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get(pageUrl)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.pricing = res.data.data.data;
            _.paginateRecords = res.data.data
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCurrencies() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("currencies")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.currencies = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    savePricing() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("pricing-store", this.price)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getPricing();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    deletePricing(price) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("pricing-delete/" + price.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getPricing();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updatePricing(price) {
      this.price = price;
      this.isEdit = true;
    },
    emptyEntry() {
      this.price = {
        land_range_id: "",
        land_price: "",
        currency_id: "",
      };
    },
    emitData(emittedData) {
      this.price.land_range_id = emittedData.id;
    },
    searchAsync(query) {
      this.keyword = query;
      if (query != null && query != "") {
        this.debouncedSearchAllocation();
      }
    },
    customLabel({
      site_name,
      demarcation_name,
      land_range_from,
      land_range_to,
    }) {
      if (site_name || demarcation_name || land_range_from || land_range_to) {
        if (land_range_from != land_range_to) {
          return `${site_name} ${demarcation_name} [ FROM PLOT ${land_range_from} TO PLOT ${land_range_to} ]`;
        } else {
          return `${site_name} ${demarcation_name} [ PLOT ${land_range_from} ]`;
        }
      } else {
        return "Search Range Allocation";
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>