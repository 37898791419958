<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <CardLandDemarcations />
    </div>
  </div>
</template>
<script>
import CardLandDemarcations from "@/components/Cards/CardLandDemarcations.vue";

export default {
  name: "LandDemarcations",
  components: {
    CardLandDemarcations,
  },
  data() {
    return {
      pageName: "Land Demarcations",
    };
  },
};
</script>
