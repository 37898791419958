<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          Customer Payment Receipt
        </h6>
        <button
          v-if="isProcessing || isGetting"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardCustomerPaymentReceiptTable
            :color="'light'"
            :options="options"
            :isProcessing="isProcessing"
            @delete-option="deletePaymentOption"
            @update-option="updatePaymentOption"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardCustomerPaymentReceiptTable from "@/components/Tables/CardCustomerPaymentReceiptTable";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
export default {
  name: "CustomerPaymentOptions",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      option: {
        purchase_type_id: "",
        customer_property_id: "",
        customer_id: "",
        land_range_from: "",
        land_range_to: "",
        payment_mode_id: "",
        next_month_due: "",
        property_cost: "",
        initial_deposit: "",
        currency_id: "",
      },
      purchaseTypes: [],
      paymentModes: [],
      sites: [],
      currencies: [],
      properties: [],
      showForm: false,
      selectedProperty: {},
      options: []
    };
  },
  validations() {
    return {
      option: {
        purchase_type_id: { required },
        customer_property_id: { required },
        customer_id: { required },
        land_range_from: { required },
        land_range_to: { required },
        payment_mode_id: { required },
        next_month_due: { required },
        property_cost: { required },
        initial_deposit: { required },
        currency_id: { required },
      },
    };
  },
  components: {
    CardCustomerPaymentReceiptTable,
  },
  created() {},
  mounted() {
    this.getPurchaseTypes();
    this.getPaymentModes();
    this.getCurrencies();
    this.getProperties();
    this.getPaymentOptions();
  },
  methods: {
    assignCustomer(client) {
      this.option.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.savePaymentOptions();

        // let savedData = {
        //   id: "001",
        //   amount: this.option.initial_deposit,
        //   created_at: new Date(),
        //   created_by: "User One",
        //   name: "Mohammed",
        //   land_range_from: this.option.land_range_from,
        //   land_range_to: this.option.land_range_to,
        //   property_cost: this.option.property_cost,
        // };

        // this.renderPaymentVoucher(savedData);
      }
    },
    getProperties() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("property-details")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.properties = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPurchaseTypes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("purchase-types")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.purchaseTypes = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPaymentOptions() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("payment-options")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.options = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPaymentModes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("payment-mode")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.paymentModes = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCurrencies() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("currencies")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.currencies = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    savePaymentOptionsOld() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("payment-option-store", this.option)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved", {
              duration: 800,
              className: "wk-success",
            });
            // _.renderPaymentVoucher(res.data.data);
            _.getPaymentOptions();
            // _.emitter.emit("emit-receipt-values", res.data.data);

            let savedData = res.data.data;
            let cashData = "";

            let momoData = "";

            let chequeNumber = "";

            let amountInWords = this.amountToWords(
              savedData.payment.amount_paid
            );

            let currency_words = "";

            if (savedData.payment.currency_name.toLowerCase() == "ghs") {
              currency_words = " Cedis";
            } else {
              currency_words = " Dollars";
            }

            let paymentReason =
              "Initial Payment For Land With Range From " +
              savedData.property.land_range_from +
              " To " +
              savedData.property.land_range_to +
              " Which Costs " +
              savedData.payment.currency_name +
              " " +
              savedData.property.property_cost;

            let values = {
              id: savedData.payment.id,
              currency: savedData.payment.currency_name,
              amount: savedData.payment.amount_paid,
              date: savedData.payment.created_at,
              cash: cashData,
              momo: momoData,
              cheque_number: chequeNumber,
              amount_in_words: amountInWords + currency_words,
              payment_reason: paymentReason,
              customer_name: savedData.payment.name,
            };
            let invoiceParams = values;
            setTimeout(() => {
              _.$router.push({ path: "/invoice", query: invoiceParams });
            }, 500);
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    savePaymentOptions() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("payment-option-store", this.option)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved", {
              duration: 800,
              className: "wk-success",
            });
            _.getPaymentOptions();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    deletePaymentOption(property) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("payment-option-delete/" + property.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getPaymentOptions();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updatePaymentOption(option) {
      this.option = option;
      this.isEdit = true;
    },
    emptyEntry() {
      this.option = {
        purchase_type_id: "",
        customer_id: "",
        land_range_from: "",
        land_range_to: "",
        payment_mode_id: "",
        next_month_due: "",
        property_cost: "",
        initial_deposit: "",
        currency_id: "",
      };
    },
    renderPaymentVoucher(savedData) {
      let _ = this;
      loadFile("/payment_receipt.docx", function (error, content) {
        try {
          if (error) {
            throw error;
          }
          var zip = new PizZip(content);
          var doc = new Docxtemplater(zip);

          let cashData = "";

          let momoData = "";

          let chequeNumber = "";

          let amountInWords = _.amountToWords(savedData.initial_deposit);

          let paymentReason =
            "Initial Payment For Land With Range From " +
            savedData.land_range_from +
            " To " +
            savedData.land_range_to +
            " Which Costs " +
            savedData.property_cost;

          doc.setData({
            id: savedData.id,
            amount: savedData.initial_deposit,
            date: savedData.created_at,
            cash: cashData,
            momo: momoData,
            cheque_number: chequeNumber,
            amount_in_words: amountInWords,
            payment_reason: paymentReason,
            user_name: savedData.created_by,
            customer_name: savedData.name,
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            _.$wkToast(error, {
              duration: 3000,
              className: "wk-alert",
            });
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            /*eslint-disable*/
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function (
                  error,
                  key
                ) {
                  error[key] = value[key];
                  return error;
                },
                {});
              }
              return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));
            /*eslint-enable*/

            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join("\n");
              /*eslint-disable*/
              console.log("errorMessages", errorMessages);
              /*eslint-enable*/
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          var out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }); //Output the document using Data-URI
          let fullname = savedData.name;
          fullname = fullname.replace(/\s+/g, "_");
          // let business_name = _.approval.loan_application.operator.business_name
          // business_name = business_name.replace(/\s+/g, '_');
          saveAs(out, fullname + "_" + "Payment_Receipt.docx");
          _.$wkToast("Payment Receipt Issued!", {
            duration: 3000,
            className: "wk-success",
          });
          _.isProcessing = false;
        } catch (error) {
          /*eslint-disable*/
          console.log(error);
          /*eslint-enable*/
          _.$wkToast(error, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
          return;
        }
      });
    },
    amountToWords(amount) {
      if (!amount) return "";
      var converter = require("number-to-words");
      return this.captializeText(converter.toWords(amount));
    },
    captializeText(value) {
      if (!value) return "";
      // value = value.toString()
      // return value.charAt(0).toUpperCase() + value.slice(1)

      let capitalized = [];
      value.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
    cancelClose() {
      this.showForm = false;
      this.emptyEntry();
    },
  },
  computed: {},
  watch: {
    selectedProperty() {
      if (!Object.keys(this.selectedProperty).length) {
        return;
      } else {
        this.option.customer_property_id = this.selectedProperty.id;
        this.option.customer_id = this.selectedProperty.customer_id;
      }
    },
  },
};
</script>

<style>
.d-none {
  display: none;
}
</style>