<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded light">
    <table class="items-center w-full bg-transparent border-collapse table-fixed hover:table-fixed">
      <thead>
        <tr>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Job Title
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
            Job Description
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
            Job Requirement
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
            Deadline
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
            Other Info
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, i) in vacancies" :key="i">
          <th class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              flex
              items-center
            ">
            <span class="ml-3 font-bold" :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']">
              {{ p.job_title }}
            </span>
          </th>

          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              overflow-x-clip
            ">
            {{ p.job_description }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ p.job_requirement }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ p.deadline }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ p.other_info }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            <a href="#" @click.prevent="deleteVacancy(p)"
              v-if="!isProcessing && $store.state.department == $store.state.storeDepartments.superAdmin"
              class="text-red-500 mr-4" type="button">
              <i class="fas fa-trash"></i> Delete
            </a>
            <a href="#" @click.prevent="viewVacancy(p)" class="text-green-600 mr-4" type="button">
              <i class="fas fa-eye"></i> View
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    vacancies: {
      type: Array,
    },
    isProcessing: {
      type: Boolean,
    },
  },
  methods: {
    deleteVacancy(obj) {
      this.$emit("delete-vacancy", obj);
    },
    viewVacancy(obj) {
      this.$emit("view-vacancy", obj)
    }
  },
};
</script>

<style>
td {
  /* max-width: 20px; */
  /* text-overflow: ellipsis;
  overflow-x: hidden; */
}
</style>