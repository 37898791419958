<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <div class="w-full lg:w-6/12 px-4 text-left">
          <h6 class="text-blueGray-700 text-xl font-bold">Customer Property</h6>
        </div>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <!-- <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Branch
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="property.branch_id"
            >
              <option v-for="(b, i) in branches" :key="i" :value="b.id">
                {{ b.branch_name }}
              </option>
            </select>
          </div>
        </div> -->
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Land Site
            </label>
            <PricedLandSites @select-site="assignSelectedSites" />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4" v-if="viewSearch">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Customer
            </label>
            <CustomerSearch :parsedCustomer="parsedCustomer" @select-client="assignCustomer" />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4" v-else>
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Customer Name
            </label>
            <p>{{ parsedCustomer.actual_buyer_name }}</p>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Purchase Type
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="property.purchase_type_id">
              <option v-for="(b, i) in purchaseTypes" :key="i" :value="b.id">
                {{ b.purchase_type }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Plot Size
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="property.plot_size_id">
              <option v-for="(b, i) in demarcations" :key="i" :value="b.id">
                {{ b.demarcation_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Acreage
            </label>
            <input v-model="property.acreage" type="number" readonly class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
                readonly
              " />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Plot Usage
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="property.plot_usage_id">
              <option v-for="(b, i) in landUsages" :key="i" :value="b.id">
                {{ b.purchase_usage }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Plot Total Number
            </label>
            <input v-model="totalPlotNumber" type="number" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Plot Numbers
            </label>
            <input v-model="property.plot_numbers" type="text" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " placeholder="eg: 21,22,23,24" />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Payment Term
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="selectedPaymentTerm">
              <option v-for="(b, i) in paymentTerms" :key="i" :value="b">
                <span>{{
                  `${b.payment_terms} - min ${b.down_payment_percentage}%`
                }}</span><span v-if="b.price_point > 0">{{
  ` -> interest ${b.price_point} cedis`
}}</span>
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Property Cost
            </label>
            <input v-model="property.property_cost" type="text" readonly class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
                readonly
              " />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Initial Deposit
            </label>
            <input v-model="property.initial_deposit" min="0" type="number" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Next Due Payment
            </label>
            <input v-model="computedNextMonthDue" type="date" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Other
            </label>
            <input v-model="property.other" type="text" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3" v-if="isManager > 0">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Discount Type
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="property.discountType">
              <option value="">
                --Select--
              </option>
              <option value="percentage">
                Percentage
              </option>
              <option value="amount">
                Amount
              </option> 
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4" v-if="isManager > 0">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Discount
            </label>
            <input v-model="property.discount" type="number" min="0" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
      </div>

      <div class="mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <div class="w-full lg:w-12/12 px-4 text-right">
            <button v-if="!isProcessing && !isGetting && !viewSearch" @click="prevPage()" class="
                bg-orange-500
                text-white
                active:bg-orange-600
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                ease-linear
                transition-all
                duration-150
              " type="button">
              Go Back
            </button>
            <button v-if="!isProcessing && !isGetting" @click="validateEntry()" class="
                bg-lightBlue-500
                text-white
                active:bg-lightBlue-600
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                ease-linear
                transition-all
                duration-150
              " type="button">
              Save Property Details
            </button>
            <button v-if="isProcessing || isGetting" class="
                bg-emerald-500
                text-white
                active:bg-emerald-600
                font-bold
                uppercase
                text-small
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                mb-1
                ease-linear
                transition-all
                duration-150
              " type="button">
              <i class="fas fa-spinner"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-auto">
          <CardPropertyDetailsTable :color="'light'" :properties="properties" :isProcessing="isProcessing"
            @delete-property="deleteProperty" @update-property="updateProperty" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardPropertyDetailsTable from "@/components/Tables/CardPropertyDetailsTable";
import CustomerSearch from "@/components/Search/CustomerSearch.vue";
import PricedLandSites from "@/components/Search/PricedLandSites.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "CustomerProperty",
  props: {
    parsedSelectedCustomer: {
      type: Object,
      default: () => ({}),
    },
    viewSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      property: {
        branch_id: "",
        purchase_type_id: "",
        property_cost: "",
        initial_deposit: "",
        customer_id: "",
        land_site_id: "",
        plot_size_id: "",
        acreage: "",
        plot_usage_id: "",
        total_plot_no: "",
        plot_numbers: "",
        payment_terms_id: "",
        next_due_payment: "",
        other: "",
        discountType: "",
        discount: "",
      },
      selectedPaymentTerm: {},
      selectedSite: {},
      purchaseTypes: [],
      paymentTerms: [],
      branches: [],
      demarcations: [],
      sites: [],
      landUsages: [],
      properties: [],
      selectedDemarcation: {},
      parsedCustomer: {},
      totalPlotNumber: "",
      commaSeparatedPlotNumbers: "",
      isManager: 0
    };
  },
  validations() {
    return {
      property: {
        branch_id: {},
        purchase_type_id: { required },
        customer_id: { required },
        property_cost: { required },
        initial_deposit: { required },
        land_site_id: { required },
        plot_size_id: { required },
        acreage: { required },
        plot_usage_id: { required },
        total_plot_no: { required },
        plot_numbers: { required },
        payment_terms_id: { required },
        next_due_payment: {},
        other: {},
        discountType: {},
        discount: {}
      }
    };
  },
  components: {
    CardPropertyDetailsTable,
    CustomerSearch,
    PricedLandSites
  },
  created() { },
  mounted() {
    this.isManager = localStorage.getItem('setIsManager')
    this.getPaymentTerms();
    this.getPurchaseTypes();
    this.getBranches();
    this.getSites();
    this.getDemarcations();
    this.getLandUsages();
    if (Object.keys(this.parsedSelectedCustomer).length > 0) {
      this.parsedCustomer = this.parsedSelectedCustomer;
      this.property.customer_id = this.parsedCustomer.id;
      this.getProperties(this.parsedSelectedCustomer.id);
    } else {
      this.getPropertiesAll();
    }
  },
  methods: {
    assignCustomer(client) {
      this.property.customer_id = client.id;
    },
    validateEntry() {
      if (Object.keys(this.parsedSelectedCustomer).length > 0) {
        this.property.customer_id = this.parsedSelectedCustomer.id;
      }
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        if (this.property.discountType != "" && this.property.discount == "") {
          this.$wkToast("Check Discount Input. If no discount, keep discount type empty.", {
            duration: 3500,
            className: "wk-alert",
          });
        } else {
          if (!this.paymentNeededGreaterThanAmountPaid) {
            this.saveProperty();
          } else {
            this.$wkToast("Check initial deposit minimum percentage to be paid!", {
              duration: 5000,
              className: "wk-alert",
            });
          }
        }
      }
    },
    getPurchaseTypes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("purchase-types")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.purchaseTypes = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPaymentTerms() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("payment-terms")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.paymentTerms = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getSites() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-pricing/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.sites = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getProperties(id) {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customer/property/" + id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.properties = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPropertiesAll() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("property-details")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.properties = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getDemarcations() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-demarcation/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.demarcations = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getLandUsages() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("purchase-usage")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.landUsages = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    saveProperty() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("property-details-store", this.property)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved", {
              duration: 3000,
              className: "wk-success",
            });
            if (!Object.keys(_.parsedSelectedCustomer).length) {
              _.getProperties(_.property.customer_id);
            } else {
              _.getProperties(_.parsedSelectedCustomer.id);
            }
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    deleteProperty(property) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("property-details-delete/" + property.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getProperties(_.parsedSelectedCustomer.id);
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updateProperty(property) {
      this.property = property;
      this.isEdit = true;
    },
    emptyEntry() {
      this.property = {
        branch_id: "",
        purchase_type_id: "",
        property_cost: "",
        initial_deposit: "",
        customer_id: "",
        land_site_id: "",
        plot_size_id: "",
        acreage: "",
        plot_usage_id: "",
        total_plot_no: "",
        plot_numbers: "",
        payment_terms_id: "",
        next_due_payment: "",
        other: "",
        discountType: "",
        discount: ""
      };
      this.selectedSite = {};
      this.selctedSites = [];
      this.selectedPaymentTerm = {};
      this.totalPlotNumber = "";
    },
    prevPage() {
      this.$emit("prev-page");
    },
    assignSelectedSite(site) {
      this.selectedSite = site
    },
    assignSelectedSites(sites) {
      this.selectedSite = sites[0]
      this.selectedSites = sites
    },
    getRange(start, end) {
      if (start == end) {
        list = [start]
      } else {
        var list = [];
        for (var i = start; i <= end; i++) {
          list.push(i);
        }
      }
      return list
    }
  },
  computed: {
    computedNextMonthDue() {
      return this.$filters.nextMonthDate();
    },
    paymentNeededGreaterThanAmountPaid() {
      let percentageNeeded = this.selectedPaymentTerm.down_payment_percentage
      let percentagePaid = this.property.initial_deposit / this.property.property_cost * 100
      if (Number(percentageNeeded) > Number(percentagePaid)) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    //Old Code For Range
    // selectedSite() {
    //   if (Object.keys(this.selectedSite).length > 0) {
    //     this.property.land_site_id = this.selectedSite.land_site_id;
    //     this.property.acreage = this.selectedSite.conversion_to_acre;
    //     this.property.plot_size_id = this.selectedSite.demarcation_id;
    //     this.property.plot_usage_id = this.selectedSite.purchase_usage_id;
    //     this.property.branch_id = this.selectedSite.branch_id;
    //     this.property.property_cost =
    //       Number(this.selectedSite.land_price) * Number(this.totalPlotNumber);
    //     let arr = this.getRange(this.selectedSite.land_range_from, this.selectedSite.land_range_to)
    //     this.commaSeparatedPlotNumbers = arr.toString();
    //     this.property.plot_numbers = this.commaSeparatedPlotNumbers
    //   }
    // },
    selectedSite() {
      if (Object.keys(this.selectedSite).length > 0) {
        this.property.plot_size_id = this.selectedSite.demarcation_id;
        this.property.plot_usage_id = this.selectedSite.purchase_usage_id;
        this.property.branch_id = this.selectedSite.branch_id;
        let cost = 0;
        let acreage = 0;
        let IDs = [];
        this.selectedSites.forEach(element => {
          cost += Number(element.land_price)
          acreage += Number(element.conversion_to_acre)
          IDs.push(element.land_site_id)
        });
        this.property.land_site_id = IDs
        this.property.property_cost = cost
        this.property.acreage = acreage;
        let arr = [];
        this.selectedSites.forEach(element => {
          arr.push(element.land_range_from);
        });
        this.commaSeparatedPlotNumbers = arr.toString();
        this.property.plot_numbers = this.commaSeparatedPlotNumbers
      }
    },
    commaSeparatedPlotNumbers() {
      if (
        this.commaSeparatedPlotNumbers != null &&
        this.commaSeparatedPlotNumbers != ""
      ) {
        let plotNumbersWithoutSpace = this.commaSeparatedPlotNumbers.replace(
          /\s/g,
          ""
        );
        let arrayOfPlotNumbers = plotNumbersWithoutSpace.split(",");
        this.totalPlotNumber = arrayOfPlotNumbers.length;
      }
    },
    selectedPaymentTerm() {
      if (Object.keys(this.selectedPaymentTerm).length > 0) {
        this.property.payment_terms_id = this.selectedPaymentTerm.id;
        this.property.next_due_payment = this.computedNextMonthDue;
        // this.property.initial_deposit = Math.round(
        //   this.selectedSite.land_price *
        //     Number(this.selectedPaymentTerm.down_payment_percentage / 100) +
        //     Number(this.selectedPaymentTerm.price_point)
        // );

        // let mult =
        //   Number(this.selectedSite.land_price) *
        //   Number(this.selectedPaymentTerm.down_payment_percentage / 100);

        // let plus = Number(mult) + Number(this.selectedPaymentTerm.price_point);

        // let res = plus * this.totalPlotNumber;
        // this.property.initial_deposit = res;
        let originalCost = 0;
        this.selectedSites.forEach(element => {
          originalCost += Number(element.land_price)
        });
        this.property.property_cost = originalCost

        if (this.selectedPaymentTerm.price_point > 0) {
          this.property.property_cost = originalCost + this.selectedPaymentTerm.price_point * this.property.total_plot_no
        } else {
          this.property.property_cost = originalCost
        }
      }
    },
    selectedDemarcation() {
      if (Object.keys(this.selectedDemarcation).length > 0) {
        this.property.plot_size_id = this.selectedDemarcation.id;
        this.property.acreage = this.selectedDemarcation.conversion_to_acre;
      }
    },
    parsedSelectedCustomer() {
      if (Object.keys(this.parsedSelectedCustomer).length > 0) {
        this.parsedCustomer = this.parsedSelectedCustomer;
        this.property.customer_id = this.parsedCustomer.id;
        this.getProperties(this.parsedSelectedCustomer.id);
      }
    },
    totalPlotNumber() {
      if (this.totalPlotNumber != null) {
        this.property.property_cost =
          Number(this.selectedSite.land_price) * Number(this.totalPlotNumber);
        this.property.total_plot_no = this.totalPlotNumber;

        let mult =
          Number(this.selectedSite.land_price) *
          Number(this.selectedPaymentTerm.down_payment_percentage / 100);

        let plus = Number(mult) + Number(this.selectedPaymentTerm.price_point);

        let res = plus * this.totalPlotNumber;
        this.property.initial_deposit = res;
      }
    },
  },
};
</script>
