<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded light"
    id="stockInventoryReport"
  >
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Product Name
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-center
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Total Stock
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-center
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Last Stock Quantity
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-center
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Date
          </th>
          <!-- <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Department To
            </th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, i) in inventory.transaction" :key="i">
          <th
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              flex
              items-center
            "
          >
            <!-- <img
                :src="bootstrap"
                class="h-12 w-12 bg-white rounded-full border"
                alt="..."
              /> -->
            <span
              class="ml-3 font-bold"
              :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
            >
              {{ p.product_name }}
            </span>
          </th>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
            "
          >
            {{ p.total_stock }}
          </td>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
            "
          >
            {{ p.last_stock_qty }}
          </td>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
            "
          >
            {{ $filters.cleanDate(p.stocking_date) }}
          </td>
        </tr>
      </tbody>
      <!-- <tfoot>
        <tr>
          <td
            colspan="8"
            class="
              font-bold
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-center
            "
          >
            SUM: {{ sales.sum }}
          </td>
        </tr>
      </tfoot> -->
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    inventory: {
      type: Object,
      default: () => ({}),
    },
    isProcessing: {
      type: Boolean,
    },
  },
  methods: {
    printReport() {
      this.$htmlToPaper("stockInventoryReport");
    },
  },
};
</script>
