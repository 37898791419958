<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded light">
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Actual Customer [Prospect]
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Total Plots
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Property Cost
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Discount
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Status
          </th>
          <th class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            " :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, i) in customers" :key="i">
          <th class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              flex
              items-center
            ">
            <span class="ml-3 font-bold" :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']">
              {{ `${p.actual_buyer_name} [${p.name}]` }}
            </span>
          </th>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ p.total_plot_no }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            {{ p.property_cost }}
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            <span>{{ p.discount }}</span>
            <span v-if="p.discountType == 'percentage'">%</span>
            <span v-else>.00</span>
          </td>
          <td class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            <span v-if="p.discountStatus == 'pending'" class=" bg-blueGray-600
                text-white px-2 py-1">{{ p.discountStatus }}</span>
            <span v-if="p.discountStatus == 'memo_sent'" class=" bg-lightBlue-600
                text-white px-2 py-1">{{ p.discountStatus.split("_").join(" ") }}</span>
            &nbsp;<span v-if="p.discountApprovedBy != null && p.discountApprovedBy != undefined" class=" bg-emerald-500
                text-white px-2 py-1">Approved</span>
          </td>
          <td v-if="approvalLevel > 1" class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            ">
            <button @click.prevent="approveDiscount(p.id, 'approve')" v-if="!isProcessing && p.discountApprovedBy == null"
              class="
                bg-purple-500
                text-white
                active:bg-purple-600
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150 mr-2
              " type="button">
              Approve
            </button>
            <button @click.prevent="approveDiscount(p.id, 'request_approval')"
              v-if="!isProcessing && p.discountStatus == 'pending'" class="
                bg-lightBlue-500
                text-white
                active:bg-lightBlue-600
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              " type="button">
              Request Approval
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      approvalLevel: 1,
    };
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    customers: {
      type: Array,
    },
    isProcessing: {
      type: Boolean,
    },
  },
  mounted() {
    this.approvalLevel = localStorage.getItem('storeApprovalLevel')
    this.department = localStorage.getItem("department");
  },
  methods: {
    goToPayments(obj) {
      this.$router.push({
        path: "/accounts/customers/payment/options",
        query: {
          record: JSON.stringify(obj),
        },
      });
    },
    approveDiscount(prop, action) {
      let revProps = {
        propId: prop,
        action: action
      }
      this.$emit('approveDiscount', revProps);
    },
  },
  computed: {
    storeDepartments() {
      return this.$store.getters.getStoreDepartments;
    },
  },
};
</script>
