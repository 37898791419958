<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Site Visits</h6>
        <button v-if="!isProcessing && !isGetting && !isEdit" @click="validateEntry()" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          " type="button">
          Save Visit
        </button>
        <button v-if="!isProcessing && !isGetting && isEdit" @click="validateEntry()" class="
            bg-purple-500
            text-white
            active:bg-purple-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          " type="button">
          Update Visit
        </button>
        <button v-if="isProcessing || isGetting" class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          " type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Visitor Name
            </label>
            <p v-if="Object.keys(visitor).length > 0">{{ visitor.name }}</p>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Preferred Visit Date
            </label>
            <input v-model="visit.preferred_visit_date" type="date" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4" v-if="!isEdit">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Preferred Branch
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="visit.preferred_visit_branch">
              <option v-for="(c, i) in branches" :key="i" :value="c.id">
                {{ c.branch_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Description
            </label>
            <textarea v-model="visit.description" name="" id="" cols="30" rows="2" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "></textarea>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Preferred Land Site
            </label>
            <select v-show="visit.preferred_visit_branch != ''" name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " @change="selectSite" v-model="siteInput">
              <option v-for="(s, i) in sites" :key="i" :value="s">
                {{ `[${s.branch_name}] - ${s.site_name}` }}
              </option>
            </select>
            <p v-if="visit.preferred_visit_branch == ''">
              Please Select Branch
            </p>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4" v-if="!isEdit">
          <div class="relative w-full mb-3 light">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Selected Sites
            </label>
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th class="
                      px-3
                      align-middle
                      border border-solid
                      py-1
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    " :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]">
                    Site
                  </th>
                  <th class="
                      px-3
                      align-middle
                      border border-solid
                      py-1
                      text-xs
                      uppercase
                      border-l-0 border-r-0
                      whitespace-nowrap
                      font-semibold
                      text-left
                    " :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(p, i) in selectedSites" :key="i">
                  <th class="
                      border-t-0
                      px-3
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-1
                      text-left
                      flex
                      items-center
                    ">
                    <span class="ml-3 font-bold text-blueGray-600">
                      {{ p.site_name }}
                    </span>
                  </th>
                  <td class="
                      border-t-0
                      px-3
                      align-middle
                      border-l-0 border-r-0
                      text-xs
                      whitespace-nowrap
                      p-1
                      text-left
                    ">
                    <a href="#" @click.prevent="removeVisit(p)" v-if="!isProcessing && p.visit_status != 'visited'"
                      class="text-red-500 mr-4" type="button">
                      <i class="fas fa-trash"></i> Remove
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4" v-if="isEdit">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Visit Status
            </label>
            <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="visit.visit_status">
              <option value="Visited">Visited</option>
              <option value="Not Visited">Not Visited</option>
            </select>
          </div>
        </div>
        <!-- <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Country
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="visit.currency_id"
            >
              <option v-for="(c, i) in countries" :key="i" :value="c.id">
                {{ c.country_name }}
              </option>
            </select>
          </div>
        </div> -->
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-auto">
          <CardSiteVisitsTable :color="'light'" :visits="visits" :isProcessing="isProcessing" @delete-visit="deleteVisit"
            @update-visit="updateVisit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardSiteVisitsTable from "@/components/Tables/CardSiteVisitsTable";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "CardSiteVisits",
  props: {
    visitor: {
      type: Object,
      default: () => ({}),
    },
    visitorId: {
      type: String,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      visit: {
        customer_id: "",
        land_site_id: [],
        description: "",
        preferred_visit_date: "",
        preferred_visit_branch: "",
      },
      branches: [],
      pricing: [],
      sites: [],
      currencies: [],
      countries: [],
      selectedSites: [],
      siteInput: {},
    };
  },
  validations() {
    return {
      visit: {
        customer_id: { required },
        land_site_id: { required },
        description: { required },
        preferred_visit_date: { required },
        preferred_visit_branch: {},
      },
    };
  },
  components: {
    CardSiteVisitsTable,
  },
  created() { },
  mounted() {
    this.getBranches();
    this.getSites();
    setTimeout(() => {
      this.visit.customer_id = this.visitorId;
      if (!this.visit.land_site_id.includes(this.visitor.preferred_site_id)) {
        this.visit.land_site_id.push(this.visitor.preferred_site_id);
      }
      this.sites.every((e) => {
        if (e.id == this.visitor.preferred_site_id) {
          this.visit.preferred_visit_branch = e.branch_id;
          if (e.id == this.visit.land_site_id) {
            this.siteInput = e
            this.selectedSites.push(e)
          }
          return false;
        }
        return true;
      });
      this.getVisits();
    }, 1000);
  },
  methods: {
    selectSite() {
      const found = this.containsObject(this.siteInput, this.selectedSites);
      if (found == false) {
        this.selectedSites.push(this.siteInput);
        if (!this.visit.land_site_id.includes(this.siteInput.id)) {
          this.visit.land_site_id.push(this.siteInput.id)
        }
      }
    },
    removeVisit(site) {
      this.selectedSites.splice(this.selectedSites.indexOf(site), 1);
    },
    containsObject(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }

      return false;
    },
    validateEntry() {
      this.visit.customer_id = this.visitorId;
      if (this.selectedSites.length > 0) {
        this.selectedSites.forEach((e) => {
          if (!this.visit.land_site_id.includes(e.id)) {
            this.visit.land_site_id.push(e.id)
          }
        })
      }
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        if (this.isEdit) {
          this.postUpdateVisit();
        } else {
          this.saveVisits();
        }
      }
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getSites() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-sites")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.sites = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCountries() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("countries")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.countries = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getVisits() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customer-visit-details/" + this.visitorId)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.visits = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    saveVisits() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-visit-store", this.visit)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Success", {
              duration: 3000,
              className: "wk-success",
            });
            _.getVisits();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    postUpdateVisit() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-visit-details-update", this.visit)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Success", {
              duration: 3000,
              className: "wk-success",
            });
            _.getVisits();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    deleteVisit(visit) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("pricing-delete/" + visit.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getPricing();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updateVisit(visit) {
      this.visit = visit;
      this.visit.preferred_visit_date = this.$filters.parseRawDate(
        this.preferred_visit_date
      );
      this.visit.site_visit_id = visit.id
      this.sites.every((e) => {
        if (e.id == this.visit.land_site_id) {
          this.siteInput = e
          this.visit.preferred_visit_branch = e.branch_id
          return false;
        }
        return true;
      });
      this.isEdit = true;
    },
    emptyEntry() {
      this.visit = {
        customer_id: "",
        land_site_id: [],
        description: "",
        preferred_visit_date: "",
        preferred_visit_branch: "",
      };
      this.selectedSites = [];
      this.siteInput = {};
    },
  },
  watch: {
    selectedSites() {
      if (this.selectedSites.length > 0) {
        this.selectedSites.forEach((e) => {
          if (this.visit.land_site_id.includes(e.id)) {
            this.visit.land_site_id.push(e.id)
          }
        })
      }
    }
  },
};
</script>
