<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-4">
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button" v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')">
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <router-link
        class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        to="/">
        <!-- {{ company.name_of_company }} -->
        <img :src="$store.getters.getStoragePath + company.logo" alt="logo"
          class="rounded w-auto align-middle border-none" style="max-height: 40px" />
      </router-link>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <notification-dropdown />
        </li>
        <li class="inline-block relative">
          <user-dropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="collapseShow">
        <!-- Collapse header -->
        <div class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/">
                {{ company.name_of_company }}
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Divider -->
        <hr class="mob-hide my-4 md:min-w-full" v-if="department == storeDepartments.admin ||
          department == storeDepartments.superAdmin
          " />
        <!-- Navigation -->

        <ul class="flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center" v-if="department == storeDepartments.superAdmin &&
            menuPage > 1 &&
            dropdownMenu > storeDepartments.superAdmin
            ">
            <a @click.prevent="goBackToMenuHeaders()" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              &larr; Menu Headers
            </a>
          </li>
          <li class="items-center" v-if="department == storeDepartments.superAdmin && menuPage == 1">
            <a @click.prevent="openDropdown(storeDepartments.admin)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Administration &rarr;
            </a>
          </li>
          <li class="items-center" v-if="department == storeDepartments.superAdmin && menuPage == 1">
            <a @click.prevent="openDropdown(storeDepartments.hr)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Human Resource &rarr;
            </a>
            <a @click.prevent="openDropdown(storeDepartments.projects)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Projects &rarr;
            </a>
            <a @click.prevent="openDropdown(storeDepartments.marketing)" href="#" target="_blank"
              rel="noopener noreferrer" class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Marketing &rarr;
            </a>
            <a @click.prevent="openDropdown(storeDepartments.accounts)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Accounts &rarr;
            </a>
            <a @click.prevent="openDropdown(222)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Approval Centre &rarr;
            </a>
            <a @click.prevent="openDropdown(223)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Commissions Settings &rarr;
            </a>
          </li>

          <li class="items-center" v-if="(department == storeDepartments.admin ||
            department == storeDepartments.superAdmin) &&
            menuPage > 1 &&
            dropdownMenu == storeDepartments.admin
            ">
            <a @click.prevent="openDropdown(storeDepartments.admin)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Administration
              <i class="fas fa-caret-down ml-2 text-sm"></i>
            </a>
            <ul v-show="dropdownMenu == storeDepartments.admin"
              class="flex-col md:min-w-full flex flex col list-none pl-2 ml-2 ease-linear transition-all duration-150">
              <li class="items-center">
                <router-link to="/admin/dashboard" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-tv mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Dashboard
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/admin/user-registration" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    User MGMT
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/admin/branches" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-home mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Branches
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/admin/departments" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-home mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Departments
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/admin/customer/convo" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-comment mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Follow-Up
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/admin/customer/convo/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-comment mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Follow-Up Report
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/admin/enquiries" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-comment mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Complaints
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/admin/enquiries/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Complaints Report
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/admin/products/page" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-tag mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Products
                  </a>
                </router-link>
              </li>

              <!-- <li class="items-center">
                <router-link
                  to="/admin/products/stock"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-tag mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Product Stock
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link
                  to="/admin/stock/disbursal"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-tag mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Stock Disbursal
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link
                  to="/admin/stock/inventory/report"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-table mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Stock Inventory Report
                  </a>
                </router-link>
              </li> -->

              <li class="items-center">
                <router-link to="/admin/unit-of-measurement" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-tools mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Unit Of Measurement
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/admin/settings" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-tools mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Settings
                  </a>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Divider -->
          <hr class="my-4 md:min-w-full" v-if="(department == storeDepartments.hr ||
            department == storeDepartments.superAdmin) &&
            menuPage > 1 &&
            dropdownMenu == storeDepartments.hr
            " />
          <li class="items-center" v-if="(department == storeDepartments.hr ||
            department == storeDepartments.superAdmin) &&
            menuPage > 1 &&
            dropdownMenu == storeDepartments.hr
            ">
            <a @click.prevent="openDropdown(storeDepartments.hr)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Human Resource
              <i class="fas fa-caret-down ml-2 text-sm"></i>
            </a>
            <ul v-show="dropdownMenu == storeDepartments.hr"
              class="flex-col md:min-w-full flex flex col list-none pl-2 ml-2 ease-linear transition-all duration-150">
              <li class="items-center">
                <router-link to="/admin/dashboard" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-tv mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Dashboard
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/designation" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-sitemap mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Designation
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/job-vacancies" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Job Vacancies
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/job-applications" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Job Applications
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/evaluate-applicant" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Evaluate Applicant
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/interview-evaluations" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Interview Evaluations
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/employee-registration" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Employee Registration
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/guardian" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-user mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Guardian
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/leave-category" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-file-image mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Leave Category
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/next-of-kin" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Next of kin
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/previous-employee" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-user mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Previous Employee
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/human-resource/working-days" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-clock mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Working Day
                  </a>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Divider -->
          <hr class="my-4 md:min-w-full" v-if="(department == storeDepartments.projects ||
            department == storeDepartments.superAdmin) &&
            menuPage > 1 &&
            dropdownMenu == storeDepartments.projects
            " />

          <li class="items-center" v-if="(department == storeDepartments.projects ||
            department == storeDepartments.superAdmin) &&
            menuPage > 1 &&
            dropdownMenu == storeDepartments.projects
            ">
            <a @click.prevent="openDropdown(storeDepartments.projects)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Projects
              <i class="fas fa-caret-down ml-2 text-sm"></i>
            </a>
            <ul v-show="dropdownMenu == storeDepartments.projects"
              class="flex-col md:min-w-full flex flex col list-none pl-2 ml-2 ease-linear transition-all duration-150">
              <li class="items-center">
                <router-link to="/admin/dashboard" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-tv mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Dashboard
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/projects/sites" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-map-pin mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Land Sites
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/projects/landdemarcations" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-ruler mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Land Demarcations
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/projects/land_ranging" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-ruler mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Land Range Allocation
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/projects/customer/centre" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-user-clock mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Customer Centre
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/projects/customers/balances" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Customer Balances
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/projects/due_for_documentations" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Due For DOCS
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/projects/documentations/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Land DOCS Report
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/projects/registrations/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Land Registration Report
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/projects/land/inventory/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Land Inventory Report
                  </a>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Divider -->
          <hr class="my-4 md:min-w-full" v-if="(department == storeDepartments.marketing ||
            department == storeDepartments.superAdmin) &&
            menuPage > 1 &&
            dropdownMenu == storeDepartments.marketing
            " />

          <li class="items-center" v-if="(department == storeDepartments.marketing ||
            department == storeDepartments.superAdmin) &&
            menuPage > 1 &&
            dropdownMenu == storeDepartments.marketing
            ">
            <a @click.prevent="openDropdown(storeDepartments.marketing)" href="#" target="_blank"
              rel="noopener noreferrer" class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Marketing
              <i class="fas fa-caret-down ml-2 text-sm"></i>
            </a>
            <ul v-show="dropdownMenu == storeDepartments.marketing"
              class="flex-col md:min-w-full flex flex col list-none pl-2 ml-2 ease-linear transition-all duration-150">
              <li class="items-center">
                <router-link to="/admin/dashboard" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-tv mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Dashboard
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/marketing/newbooking" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-map mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    New Booking
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/marketing/prospects/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Prospects Report
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/marketing/customers/page" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Customers
                  </a>
                </router-link>
              </li>

              <!-- <li class="items-center">
                <router-link
                  to="/marketing/customers/transfer"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-users mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Clients Transfer
                  </a>
                </router-link>
              </li> -->

              <!-- <li class="items-center">
                <router-link
                  to="/marketing/customers/nextofkin"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-users mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Customers Next Of Kin
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link
                  to="/marketing/customers/property"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-users mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Customer Property
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link
                  to="/marketing/customers/balances"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-users mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Customer Balances
                  </a>
                </router-link>
              </li> -->

              <li class="items-center">
                <router-link to="/marketing/site/visits/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Site Visits Report
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/marketing/land/inventory/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Land Inventory Report
                  </a>
                </router-link>
              </li>

              <!-- <li class="items-center">
                <router-link
                  to="/marketing/enquiries"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-comment mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Complaints
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link
                  to="/marketing/enquiries/report"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-table mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Complaints Report
                  </a>
                </router-link>
              </li> -->

              <li class="items-center">
                <router-link to="/marketing/periodic/sales/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Periodic Sales Report
                  </a>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Divider -->
          <hr class="my-4 md:min-w-full" v-if="(department == storeDepartments.accounts ||
            department == storeDepartments.superAdmin) &&
            menuPage > 1 &&
            dropdownMenu == storeDepartments.accounts
            " />

          <li class="items-center" v-if="(department == storeDepartments.accounts ||
            department == storeDepartments.superAdmin) &&
            menuPage > 1 &&
            dropdownMenu == storeDepartments.accounts
            ">
            <a @click.prevent="openDropdown(storeDepartments.accounts)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Accounts
              <i class="fas fa-caret-down ml-2 text-sm"></i>
            </a>
            <ul v-if="dropdownMenu == storeDepartments.accounts"
              class="flex-col md:min-w-full flex flex col list-none pl-2 ml-2 ease-linear transition-all duration-150">
              <li class="items-center">
                <router-link to="/admin/dashboard" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-tv mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Dashboard
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/accounts/customers" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Customers
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/accounts/customers/ready-to-pay" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Ready To Pay
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/accounts/customer/centre" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-user-clock mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Customer Centre
                  </a>
                </router-link>
              </li>

              <!-- <li class="items-center">
                <router-link
                  to="/accounts/customers/balances"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-users mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Customer Balances
                  </a>
                </router-link>
              </li> -->

              <li class="items-center">
                <router-link to="/accounts/customers/payment/page" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-coins mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Customer Payments
                  </a>
                </router-link>
              </li>

              <!-- <li class="items-center">
                <router-link
                  to="/accounts/customers/payment/receipt"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-print mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Customer Payment Receipt
                  </a>
                </router-link>
              </li> -->

              <!-- <li class="items-center">
                <router-link
                  to="/accounts/customers/payments"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-users mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Customer Payments
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link
                  to="/accounts/customers/payments/report"
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="text-xs uppercase py-3 font-bold block"
                    :class="[
                      isActive
                        ? 'text-emerald-500 hover:text-emerald-600'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    <i
                      class="fas fa-table mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                    ></i>
                    Customer Payment Report
                  </a>
                </router-link>
              </li> -->

              <li class="items-center">
                <router-link to="/accounts/landpricing" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-coins mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Land Pricing
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/accounts/expense/payment" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-credit-card mr-2 text-sm"
                      :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Expenses
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/accounts/land/inventory/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Land Inventory Report
                  </a>
                </router-link>
              </li>

              <li class="items-center">
                <router-link to="/accounts/landowner" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Land Owner MGMT
                  </a>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Divider -->
          <hr class="my-4 md:min-w-full" v-if="approvalLevel > 1 || (department == storeDepartments.accounts && setManager > 0)
            " />

          <li class="items-center" v-if="approvalLevel > 1 &&
            menuPage > 1 &&
            dropdownMenu == 222 || (department == storeDepartments.accounts && setManager > 0)
            ">
            <a @click.prevent="openDropdown(222)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Approvals Centre
              <i class="fas fa-caret-down ml-2 text-sm"></i>
            </a>
            <ul v-show="dropdownMenu == 222"
              class="flex-col md:min-w-full flex flex col list-none pl-2 ml-2 ease-linear transition-all duration-150">
              <li class="items-center">
                <router-link to="/approvals/discounts" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-coins mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Discounts
                  </a>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Divider -->
          <hr class="my-4 md:min-w-full" v-if="approvalLevel > 1 || (department == storeDepartments.admin && setManager > 0)
            " />

          <li class="items-center" v-if="approvalLevel > 1 &&
            menuPage > 1 &&
            dropdownMenu == 223 || (department == storeDepartments.accounts && setManager > 0)
            ">
            <a @click.prevent="openDropdown(223)" href="#" target="_blank" rel="noopener noreferrer"
              class="text-xs uppercase py-3 font-bold block" :class="[
                isActive
                  ? 'text-emerald-500 hover:text-emerald-600'
                  : 'text-blueGray-700 hover:text-blueGray-500',
              ]">
              Commissions Settings
              <i class="fas fa-caret-down ml-2 text-sm"></i>
            </a>
            <ul v-show="dropdownMenu == 223"
              class="flex-col md:min-w-full flex flex col list-none pl-2 ml-2 ease-linear transition-all duration-150">
              <li class="items-center">
                <router-link to="/commissions/marketing" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-coins mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Marketing Department
                  </a>
                </router-link>
              </li>
              <li class="items-center">
                <router-link to="/commissions/other" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-coins mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Other Internal Comms
                  </a>
                </router-link>
              </li>
              <li class="items-center">
                <router-link to="/commissions/report" v-slot="{ href, navigate, isActive }">
                  <a :href="href" @click="navigate" class="text-xs uppercase py-3 font-bold block" :class="[
                    isActive
                      ? 'text-emerald-500 hover:text-emerald-600'
                      : 'text-blueGray-700 hover:text-blueGray-500',
                  ]">
                    <i class="fas fa-coins mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                    Commissions Report
                  </a>
                </router-link>
              </li>
            </ul>
          </li>

          <!-- Divider -->
          <hr class="my-4 md:min-w-full" v-if="(department == storeDepartments.accounts ||
            department == storeDepartments.superAdmin) && approvalLevel == 1
            " />
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import NotificationDropdown from "@/components/Dropdowns/NotificationDropdown.vue";
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";

export default {
  data() {
    return {
      collapseShow: "hidden",
      dropdownMenu: "",
      menuPage: 1,
      company: {
        name_of_company: "",
        phone: "",
        email: "",
        address: "",
        location: "",
        logo: "",
      },
      isActive: false,
      department: "",
      approvalLevel: 1,
      setManager: 0,
    };
  },
  created() { },
  mounted() {
    this.company = JSON.parse(localStorage.getItem("companyInfo"));
    this.department = localStorage.getItem("department");
    this.dropdownMenu = this.department;
    if (this.department != this.storeDepartments.superAdmin) {
      this.menuPage = 2;
    }
    this.approvalLevel = localStorage.getItem('storeApprovalLevel');
    this.setManager = localStorage.getItem('setIsManager');

  },
  methods: {
    goBackToMenuHeaders() {
      this.menuPage = 1;
    },
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
    },
    openDropdown: function (menuNumber) {
      this.dropdownMenu = menuNumber;
      this.menuPage = 2;
    },
  },
  components: {
    NotificationDropdown,
    UserDropdown,
  },
  computed: {
    storeDepartments() {
      return this.$store.getters.getStoreDepartments;
    },
    storeApprovalLevel() {
      return this.$store.getters.getstoreApprovalLevel;
    }
  },
  watch: {
    department() {
      if (this.department != this.storeDepartments.superAdmin) {
        this.menuPage = 2;
      }
    },
  },
};
</script>
