<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      mt-6
    "
  >
    <div v-if="!isProcessing || !isGetting" class="px-4 mb-4">
      <div class="flex flex-wrap justify-center">
        <div class="w-full px-4 text-center mt-18">
          <div class="flex justify-center py-4 lg:pt-4 pt-4">
            <div class="mr-4 p-3 text-center">
              <span class="text-xl text-black-400 font-bold">{{
                `${customer.name}'s Summary`
              }}</span>
            </div>
          </div>
          <div
            class="
              flex
              justify-center
              border-b border-blueGray-200
              py-4
              lg:pt-4
              pt-8
            "
          >
            <div class="mr-4 p-3 text-center">
              <span
                class="
                  text-5xl
                  font-bold
                  block
                  uppercase
                  tracking-wide
                  text-red-600
                "
              >
                {{ bio.total_debt }}
              </span>
              <span class="text-xl text-blueGray-400 font-bold"
                >Total Debt</span
              >
            </div>
          </div>
          <div
            class="
              flex
              justify-center
              border-b border-blueGray-200
              py-4
              lg:pt-4
              pt-8
            "
          >
            <div class="mr-4 p-3 text-center">
              <span
                class="
                  text-5xl
                  font-bold
                  block
                  uppercase
                  tracking-wide
                  text-red-600
                "
              >
                {{ bio.total_payment }}
              </span>
              <span class="text-xl text-blueGray-400 font-bold"
                >Total Payment</span
              >
            </div>
          </div>
          <!-- <div
            class="
              flex
              items-center
              border-b border-blueGray-200
              py-5 py-4
              lg:pt-4
              pt-8
            "
          >
            <span class="mr-2">90%</span>
            <div class="relative w-full">
              <div class="overflow-hidden h-2 text-xs flex rounded bg-teal-200">
                <div
                  class="
                    shadow-none
                    flex flex-col
                    text-center
                    whitespace-nowrap
                    text-white
                    justify-center
                    bg-teal-500
                  "
                  style="width: 90%"
                ></div>
              </div>
            </div>
          </div>
          <div
            class="
              flex
              items-center
              border-b border-blueGray-200
              py-5 py-4
              lg:pt-4
              pt-8
              justify-center
            "
          >
            <div
              class="
                text-white
                px-6
                py-4
                border-0
                rounded
                relative
                mb-4
                bg-lightBlue-500
              "
            >
              <span class="text-xl inline-block mr-5 align-middle">
                <i class="fas fa-info"></i>
              </span>
              <span class="inline-block align-middle pl-4 mr-8">
                <b class="capitalize">Site Development</b>
              </span>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="text-center mt-12">
        <h3
          class="
            text-xl
            font-semibold
            leading-normal
            mb-2
            text-blueGray-700
            mb-2
          "
        >
          Jenna Stones
        </h3>
        <div
          class="
            text-sm
            leading-normal
            mt-0
            mb-2
            text-blueGray-400
            font-bold
            uppercase
          "
        >
          <i class="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
          Los Angeles, California
        </div>
        <div class="mb-2 text-blueGray-600 mt-10">
          <i class="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
          Solution Manager - Creative Tim Officer
        </div>
        <div class="mb-2 text-blueGray-600">
          <i class="fas fa-university mr-2 text-lg text-blueGray-400"></i>
          University of Computer Science
        </div>
      </div>
      <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
        <div class="flex flex-wrap justify-center">
          <div class="w-full lg:w-9/12 px-4">
            <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
              An artist of considerable range, Jenna the name taken by
              Melbourne-raised, Brooklyn-based Nick Murphy writes, performs and
              records all of his own music, giving it a warm, intimate feel with
              a solid groove structure. An artist of considerable range.
            </p>
            <a href="javascript:void(0);" class="font-normal text-emerald-500">
              Show more
            </a>
          </div>
        </div>
      </div> -->
    </div>
    <div v-if="isProcessing || isGetting" class="px-8 justify-center">
      <button
        class="
          bg-lightBlue-500
          text-white
          active:bg-lightBlue-600
          font-bold
          uppercase
          text-small
          px-4
          py-2
          rounded
          shadow
          hover:shadow-md
          outline-none
          focus:outline-none
          mr-1
          mb-1
          ease-linear
          transition-all
          duration-150
        "
        type="button"
      >
        <i class="fas fa-spinner"></i>
      </button>
    </div>
  </div>
</template>
<script>
import team2 from "@/assets/img/team-2-800x800.jpg";

export default {
  name: "CardCustomerCentreBio",
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
    bio: {
      type: Object,
    },
    isGetting: {
      type: Boolean,
    },
    isProcessing: {
      type: Boolean,
    },
  },
  data() {
    return {
      team2,
    };
  },
};
</script>
