<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <CardCustomerNextOfKin />
    </div>
  </div>
</template>
<script>
import CardCustomerNextOfKin from "@/components/Cards/CardCustomerNextOfKin.vue";

export default {
  name: "CustomerNextOfKin",
  components: {
    CardCustomerNextOfKin,
  },
  data() {
    return {
      pageName: "Customer Next Of Kin",
    };
  },
};
</script>
