<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-lg
      rounded
    "
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Branches List
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            v-if="isProcessing"
            class="
              bg-indigo-500
              text-white
              active:bg-indigo-600
              text-xs
              font-bold
              uppercase
              px-3
              py-1
              rounded
              outline-none
              focus:outline-none
              mr-1
              mb-1
              ease-linear
              transition-all
              duration-150
            "
            type="button"
          >
            <i class="fas fa-spinner"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <!-- <thead class="thead-light">
          <tr>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Branch Name
            </th>
          </tr>
        </thead> -->
        <tbody>
          <tr v-for="(b, i) in branches" :key="i">
            <th
              class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
                flex
                items-center
              "
            >
              <span
                class="ml-3 font-bold"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]"
              >
                {{ b.branch_name }}
              </span>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      finalSites: [],
    };
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    branches: {
      type: Array,
    },
    isProcessing: {
      type: Boolean,
    },
  },
  mounted() {},
  methods: {},
  watch: {
    sites() {
      if (this.sites.length > 0) {
        this.$emit("setisProcessing", true);
        this.sites.forEach((element) => {
          let percent = (element.plot_qty - element.total_plot_left) * 100;
          let percentColor = "";
          if (percent < 5) {
            percentColor = "bg-red";
          } else if (percent >= 5 && percent <= 45) {
            percentColor = "bg-purple";
          } else if (percent > 45 && percent <= 60) {
            percentColor = "bg-purple";
          } else if (percent > 60) {
            percentColor = "bg-emerald";
          }

          element.percentSold = percent;
          element.percentColor = percentColor;
          this.finalSites.push(element);
        });
        this.$emit("setIsProcessing", false);
      }
    },
  },
};
</script>