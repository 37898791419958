<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded light"
  >
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Actual Customer [Prospect]
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Phone Number
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Total Plots
          </th>
          <th
            v-if="
              department == storeDepartments.accounts ||
              department == storeDepartments.superAdmin
            "
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Pay
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, i) in balances" :key="i">
          <th
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              flex
              items-center
            "
          >
            <span
              class="ml-3 font-bold"
              :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
            >
              {{ `${p.actual_buyer_name} [${p.name}]` }}
            </span>
          </th>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ `${p.country_code} ${p.phone_number}` }}
            <!-- {{ ` ${p.phone_number}` }} -->
          </td>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ p.total_plot_no }}
          </td>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
            v-if="
              department == storeDepartments.accounts ||
              department == storeDepartments.superAdmin
            "
          >
            <button
              @click.prevent="goToPayments(p)"
              v-if="!isProcessing"
              class="
                bg-purple-500
                text-white
                active:bg-purple-600
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Pay
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    balances: {
      type: Array,
    },
    isProcessing: {
      type: Boolean,
    },
  },
  mounted() {
    this.department = localStorage.getItem("department");
  },
  methods: {
    goToPayments(obj) {
      this.$router.push({
        path: "/accounts/customers/payment/options",
        query: {
          record: JSON.stringify(obj),
        },
      });
    },
  },
  computed: {
    storeDepartments() {
      return this.$store.getters.getStoreDepartments;
    },
  },
};
</script>
