<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4 mb-3">
      <div class="flex flex-wrap">
        <button
          @click="page = 0"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-tag"></i>
          Products
        </button>
        <button
          @click="page = 2"
          class="
            bg-indigo-500
            text-white
            active:bg-indigo-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-tag"></i>
          Product Stocks
        </button>
        <button
          @click="page = 3"
          class="
            bg-purple-500
            text-white
            active:bg-purple-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-shopping-cart"></i>
          Stock Disbursal
        </button>
        <button
          @click="page = 4"
          class="
            bg-orange-500
            text-white
            active:bg-orange-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-table"></i>
          Inventory Report
        </button>
      </div>
      <!-- <div v-if="page > 0">
        <button
          @click="page = 0"
          class="
            bg-black
            text-white
            active:bg-slate-100
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-arrow-left"></i>
          Go Back
        </button>
      </div> -->
    </div>
    <div v-if="page == 0" class="w-full lg:w-12/12 px-4">
      <Products />
    </div>
    <div v-if="page == 2" class="w-full lg:w-12/12 px-4">
      <ProductStock />
    </div>
    <div v-if="page == 3" class="w-full lg:w-12/12 px-4">
      <StockDisbursals />
    </div>
    <div v-if="page == 4" class="w-full lg:w-12/12 px-4">
      <StockInventoryReport />
    </div>
  </div>
</template>
<script>
import Products from "@/views/admin/Products.vue";
import ProductStock from "@/views/admin/ProductStocks.vue";
import StockDisbursals from "@/views/admin/StockDisbursals.vue";
import StockInventoryReport from "@/views/admin/StockInventoryReport.vue";

export default {
  name: "ProductsPage",
  components: {
    Products,
    ProductStock,
    StockDisbursals,
    StockInventoryReport,
  },
  data() {
    return {
      pageName: "ProductsPage",
      page: 0,
    };
  },
  mounted() {},
};
</script>
