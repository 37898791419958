<template>
  <div>
    <CardEmployeeRegistration
      v-show="showModal"
      @toggle-modal="toggleModal"
      @get-employees="getEmployees"
      :countries="countries"
      :identifications="identifications"
      :genders="genderList"
      :titles="titleList"
      :maritalStatus="maritalStatusList"
      :religions="religionList"
      :designations="designations"
      :selectedEmployee="selectedEmployee"
      @clear-selected-employee="clearSelectedEmployee"
      :isEdit="isEdit"
    />
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4 overflow-auto">
        <CardEmployeesTable
          :employees="employees"
          :isProcessing="isProcessing"
          :isGetting="isGetting"
          @toggleModal="toggleModal"
          @view-employee-details="viewSelectedEmployee"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CardEmployeesTable from "@/components/Tables/CardEmployeesTable.vue";
import useVuelidate from "@vuelidate/core";
import CardEmployeeRegistration from "@/components/Cards/CardEmployeeRegistration.vue";
import { required } from "@vuelidate/validators";

export default {
  name: "Employees",
  components: {
    CardEmployeesTable,
    CardEmployeeRegistration,
  },
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      isGetting: false,
      employees: [],
      isEdit: false,
      selectedEmployee: {
        employee_title_id: "",
        designation_id: "",
        surname: "",
        other_names: "",
        gender_id: "",
        dob: "",
        place_of_birth: "",
        country_id: "",
        id_type: "",
        id_number: "",
        religion_id: "",
        residential_address: "",
        town: "",
        land_mark: "",
        contact_phone: "",
        email: "",
        illness_disability: "",
        illness_note: "",
        marital_status_id: "",
        name_of_spouse: "",
        no_of_children: "",
        address: "",
        father_name: "",
        father_address: "",
        father_alive: "",
        father_phone: "",
        mother_name: "",
        mother_address: "",
        mother_alive: "",
        mother_phone: "",
        prof_body: "",
        prof_body_name: "",
        prof_body_contact: "",
        emergency_contact_name: "",
        relation_to_emergency_contact: "",
        emergency_contact_tel: "",
        emergency_contact_email: "",
      },
      showModal: false,
      countries: [],
      identifications: [],
      branches: [],
      referrals: [],
      titleList: [],
      maritalStatusList: [],
      religionList: [],
      genderList: [],
      designations: [],
      page: 1,
    };
  },
  validations() {
    return {
      employee: {
        employee_title_id: { required },
        designation_id: { required },
        surname: { required },
        other_names: { required },
        gender_id: { required },
        dob: { required },
        place_of_birth: { required },
        country_id: { required },
        id_type: { required },
        id_number: { required },
        religion_id: { required },
        residential_address: { required },
        town: {},
        land_mark: {},
        contact_phone: { required },
        email: { required },
        illness_disability: { required },
        illness_note: {},
        marital_status_id: { required },
        name_of_spouse: {},
        no_of_children: {},
        address: {},
        father_name: { required },
        father_address: {},
        father_alive: { required },
        father_phone: {},
        mother_name: { required },
        mother_address: {},
        mother_alive: { required },
        mother_phone: {},
        prof_body: { required },
        prof_body_name: {},
        prof_body_contact: {},
        emergency_contact_name: { required },
        relation_to_emergency_contact: { required },
        emergency_contact_tel: { required },
        emergency_contact_email: {},
      },
    };
  },
  created() {
    // this.emitter.on("view-customer-details", (data) => {
    //   this.selectedEmployee = data;
    //   this.showModal = true;
    // });
  },
  mounted() {
    this.getIdentifications();
    this.getCountries();
    this.getGenders();
    this.getTitles();
    this.getMaritalStatus();
    this.getReligions();
    this.getDesignations();
    this.getEmployees();
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    goBack() {
      this.page = 1;
    },
    clearSelectedEmployee() {
      this.selectedEmployee = {};
      this.isEdit = false;
      this.page = 1;
    },
    viewSelectedEmployee(data) {
      this.selectedEmployee = data;
      this.isEdit = true;
      this.showModal = true;
    },
    editSelectedEmployee(data) {
      this.selectedEmployee = data;
      this.isEdit = true;
      this.showModal = true;
    },
    getTitles() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("name-title-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.titleList = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getMaritalStatus() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("marital-status-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.maritalStatusList = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getGenders() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("gender-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.genderList = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getReligions() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("religion-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.religionList = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getDesignations() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("designation-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.designations = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getEmployees() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("employee-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.employees = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getCountries() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("countries")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.countries = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getIdentifications() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("identifications")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.identifications = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
};
</script>
