<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <CardSiteVisits :visitor="visitor" :visitorId="visitorId" />
    </div>
  </div>
</template>
<script>
import CardSiteVisits from "@/components/Cards/CardSiteVisits.vue";

export default {
  name: "SiteVisits",
  components: {
    CardSiteVisits,
  },
  data() {
    return {
      pageName: "Site Visits",
      visitor: {},
      visitorId: "",
    };
  },
  mounted() {
    if (!Object.keys(this.$route.query).length) {
      return;
    } else {
      this.visitor = JSON.parse(this.$route.query.visitor);
      this.visitorId = this.visitor.id;
    }
  },
};
</script>
