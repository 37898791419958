<template>
  <div>
    <!-- <div class="flex flex-wrap">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-line-chart />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart />
      </div>
    </div> -->
    <div class="flex flex-wrap mt-4">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <CardComplaintsDashboard
          :complaints="complaints"
          @setIsProcessing="setIsProcessing"
        />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <CardLandSitesSoldDashboard
          :sites="sites"
          @setIsProcessing="setIsProcessing"
        />
      </div>
    </div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <CardCustomersDashboard
          :customers="customers"
          @setIsProcessing="setIsProcessing"
        />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <CardBranchesDashboard
          :branches="branches"
          @setIsProcessing="setIsProcessing"
        />
      </div>
    </div>
  </div>
</template>
<script>
// import CardLineChart from "@/components/Cards/CardLineChart.vue";
// import CardBarChart from "@/components/Cards/CardBarChart.vue";
import CardCustomersDashboard from "@/components/Cards/CardCustomersDashboard.vue";
import CardBranchesDashboard from "@/components/Cards/CardBranchesDashboard.vue";
import CardComplaintsDashboard from "@/components/Cards/CardComplaintsDashboard.vue";
import CardLandSitesSoldDashboard from "@/components/Cards/CardLandSitesSoldDashboard.vue";
export default {
  name: "dashboard-page",
  components: {
    // CardLineChart,
    // CardBarChart,
    CardCustomersDashboard,
    CardBranchesDashboard,
    CardComplaintsDashboard,
    CardLandSitesSoldDashboard,
  },
  data() {
    return {
      sites: [],
      complaints: [],
      branches: [],
      customers: [],
    };
  },
  mounted() {
    this.getSites();
    this.getComplaints();
    this.getCustomers();
    this.getBranches();
  },
  methods: {
    setIsProcessing(key) {
      this.isProcessing = key;
    },
    getSites() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-sites")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.sites = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getComplaints() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("enquiry-complaints")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.complaints = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getBranches() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCustomers() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customers/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.customers = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
};
</script>
