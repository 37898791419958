<template>
    <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      overflow-scroll
    ">
        <div class="px-4 mb-4">
            <div class="flex flex-wrap justify-center">
                <div class="w-full px-4 text-center mt-18">
                    <div class="flex justify-center py-4 lg:pt-4 pt-4">
                        <div class="mr-4 p-3 pb-0 text-center">
                            <div><span class="text-xl text-black-400 font-bold mr-2">{{
                                `Applicant Total Score:`
                            }}</span>
                                <button class="
                            text-white
                            font-bold
                            text-sm
                            px-4
                            py-2
                            rounded
                            shadow
                            hover:shadow-md
                            outline-none
                            focus:outline-none
                            mx-2
                            ease-linear
                            transition-all
                            duration-150"
                                    :class="{ 'bg-red-500': score < 50, 'bg-yellow-500': score >= 50 && score < 70, 'bg-emerald-800': score >= 70 }">{{
                                        score }}</button>
                            </div>
                            <div v-if="!isSaving && !error && score > 0">
                                <button @click="saveEvaluation" class="text-white
                                        bg-lightBlue-500
                            font-bold
                            text-sm
                            px-4
                            py-2
                            rounded
                            shadow
                            hover:shadow-md
                            outline-none
                            focus:outline-none
                            mx-2
                            ease-linear
                            transition-all
                            duration-150">Save Evaluation</button>
                            </div>
                            <div v-if="isSaving" class="px-8 justify-center">
                                <button class="
          bg-lightBlue-500
          text-white
          active:bg-lightBlue-600
          font-bold
          
          text-small
          px-4
          py-2
          rounded
          shadow
          hover:shadow-md
          outline-none
          focus:outline-none
          mr-1
          mb-1
          ease-linear
          transition-all
          duration-150
        " type="button">
                                    <i class="fas fa-spinner"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        <table class="items-center w-full bg-transparent border">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th class="border">Max Score</th>
                                    <th class="border">Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="
                                                px-6
                                                align-middle
                                                py-3
                                                text-xs
                                                border
                                                whitespace-nowrap
                                                font-semibold
                                                text-left
                                                ">
                                        <span class="applicant-data-field">A. Appearance</span> <br>
                                        Dress, Comportment, Manners
                                    </td>
                                    <td class="
                                                px-6
                                                align-middle
                                                py-3
                                                text-xs
                                                border
                                                whitespace-nowrap
                                                font-semibold
                                                text-left
                                                ">
                                        5
                                    </td>
                                    <td class="
                                            px-6
                                            align-middle
                                            py-3
                                            text-xs
                                            border
                                            whitespace-nowrap
                                            font-semibold
                                            text-left
                                            ">
                                        <input class="
                                                border-0
                                                px-3
                                                py-3
                                                placeholder-blueGray-300
                                                text-blueGray-600
                                                bg-white
                                                rounded
                                                text-sm
                                                shadow
                                                focus:outline-none focus:ring
                                                w-full
                                                ease-linear
                                                transition-all
                                                duration-150
                                            " type="number" min="0" v-model="evaluation.appearance"
                                            :blocked="isProcessing || isSaving">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="
                                            px-6
                                            align-middle
                                            py-3
                                            text-xs
                                            border
                                            whitespace-nowrap
                                            font-semibold
                                            text-left
                                            ">
                                        <span class="applicant-data-field">B. Personal Attributes</span> <br>
                                        <ol>
                                            <li>i. Self-Expression, Maturity, Emotional Stability, <br> Self
                                                Confidence
                                                - 10
                                                marks</li>
                                            <li>ii. Mental Alertness, Temperament, Confidentiality, <br> Attitude
                                                and
                                                Passion -
                                                10 marks</li>
                                        </ol>
                                    </td>
                                    <td class="
                                            px-6
                                            align-middle
                                            py-3
                                            text-xs
                                            border
                                            whitespace-nowrap
                                            font-semibold
                                            text-left
                                            ">
                                        20
                                    </td>
                                    <td class="
                                            px-6
                                            align-middle
                                            py-3
                                            text-xs
                                            border
                                            whitespace-nowrap
                                            font-semibold
                                            text-left
                                            ">
                                        <input class="
                                                border-0
                                                px-3
                                                py-3
                                                placeholder-blueGray-300
                                                text-blueGray-600
                                                bg-white
                                                rounded
                                                text-sm
                                                shadow
                                                focus:outline-none focus:ring
                                                w-full
                                                ease-linear
                                                transition-all
                                                duration-150
                                            " type="number" min="0" v-model="evaluation.personalAttributes"
                                            :blocked="isProcessing || isSaving">
                                    </td>
                                </tr>

                                <tr>
                                    <td class="
                                            px-6
                                            align-middle
                                            py-3
                                            text-xs
                                            border
                                            whitespace-nowrap
                                            font-semibold
                                            text-left
                                            ">
                                        <span class="applicant-data-field">C. Education</span> <br>
                                        Academic Qualifications; Professional Training
                                    </td>
                                    <td class="
                                            px-6
                                            align-middle
                                            py-3
                                            text-xs
                                            border
                                            whitespace-nowrap
                                            font-semibold
                                            text-left
                                            ">
                                        25
                                    </td>
                                    <td class="
                                            px-6
                                            align-middle
                                            py-3
                                            text-xs
                                            border
                                            whitespace-nowrap
                                            font-semibold
                                            text-left
                                            ">
                                        <input class="
                                                border-0
                                                px-3
                                                py-3
                                                placeholder-blueGray-300
                                                text-blueGray-600
                                                bg-white
                                                rounded
                                                text-sm
                                                shadow
                                                focus:outline-none focus:ring
                                                w-full
                                                ease-linear
                                                transition-all
                                                duration-150
                                            " type="number" min="0" v-model="evaluation.education"
                                            :blocked="isProcessing || isSaving">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="
        px-6
        align-middle
        py-3
        text-xs
        border
        whitespace-nowrap
        font-semibold
        text-left
        ">
                                        <span class="applicant-data-field">D. Experience/Position Knowledge/Practical
                                            Skills</span> <br>
                                        Achievements; Adjustment; Problems Solving
                                    </td>
                                    <td class="
        px-6
        align-middle
        py-3
        text-xs
        border
        whitespace-nowrap
        font-semibold
        text-left
        ">
                                        40
                                    </td>
                                    <td class="
        px-6
        align-middle
        py-3
        text-xs
        border
        whitespace-nowrap
        font-semibold
        text-left
        ">
                                        <input class="
            border-0
            px-3
            py-3
            placeholder-blueGray-300
            text-blueGray-600
            bg-white
            rounded
            text-sm
            shadow
            focus:outline-none focus:ring
            w-full
            ease-linear
            transition-all
            duration-150
            " type="number" min="0" v-model="evaluation.experience" :blocked="isProcessing || isSaving">
                                    </td>
                                </tr>

                                <tr>
                                    <td class="
px-6
align-middle
py-3
text-xs
border
whitespace-nowrap
font-semibold
text-left
">
                                        <span class="applicant-data-field">E. Industry Knowledge & Current Trends</span>
                                        <br>
                                    </td>
                                    <td class="
px-6
align-middle
py-3
text-xs
border
whitespace-nowrap
font-semibold
text-left
">
                                        5
                                    </td>
                                    <td class="
px-6
align-middle
py-3
text-xs
border
whitespace-nowrap
font-semibold
text-left
">
                                        <input class="
border-0
px-3
py-3
placeholder-blueGray-300
text-blueGray-600
bg-white
rounded
text-sm
shadow
focus:outline-none focus:ring
w-full
ease-linear
transition-all
duration-150
" type="number" min="0" v-model="evaluation.industry" :blocked="isProcessing || isSaving">
                                    </td>
                                </tr>

                                <tr>
                                    <td class="
px-6
align-middle
py-3
text-xs
border
whitespace-nowrap
font-semibold
text-left
">
                                        <span class="applicant-data-field">F. Interests</span>
                                        <br>
                                        Job and Career interests, Prospects of Career Realization
                                    </td>
                                    <td class="
px-6
align-middle
py-3
text-xs
border
whitespace-nowrap
font-semibold
text-left
">
                                        5
                                    </td>
                                    <td class="
px-6
align-middle
py-3
text-xs
border
whitespace-nowrap
font-semibold
text-left
">
                                        <input class="
border-0
px-3
py-3
placeholder-blueGray-300
text-blueGray-600
bg-white
rounded
text-sm
shadow
focus:outline-none focus:ring
w-full
ease-linear
transition-all
duration-150
" type="number" min="0" v-model="evaluation.interests" :blocked="isProcessing || isSaving">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="py-4">
                        <table class="items-center w-full bg-transparent border">
                            <thead>
                                <tr>
                                    <th>Additional Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="
                                                px-6
                                                align-middle
                                                py-3
                                                text-xs
                                                border
                                                whitespace-nowrap
                                                font-semibold
                                                text-left
                                                ">
                                        Salary Expectation
                                    </td>
                                    <td class="
                                            px-8
                                            align-middle
                                            py-3
                                            text-xs
                                            border
                                            whitespace-nowrap
                                            font-semibold
                                            text-left
                                            ">
                                        <input class="
                                                border-0
                                                px-3
                                                py-3
                                                placeholder-blueGray-300
                                                text-blueGray-600
                                                bg-white
                                                rounded
                                                text-sm
                                                shadow
                                                focus:outline-none focus:ring
                                                w-full
                                                ease-linear
                                                transition-all
                                                duration-150
                                            " type="number"
                                            v-model="evaluation.additionalInformation.salary_expectation"
                                            :blocked="isProcessing || isSaving">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="
                                                px-6
                                                align-middle
                                                py-3
                                                text-xs
                                                border
                                                whitespace-nowrap
                                                font-semibold
                                                text-left
                                                ">
                                        Reporting Time
                                    </td>
                                    <td class="
                                            px-8
                                            align-middle
                                            py-3
                                            text-xs
                                            border
                                            whitespace-nowrap
                                            font-semibold
                                            text-left
                                            ">
                                        <input class="
                                                border-0
                                                px-3
                                                py-3
                                                placeholder-blueGray-300
                                                text-blueGray-600
                                                bg-white
                                                rounded
                                                text-sm
                                                shadow
                                                focus:outline-none focus:ring
                                                w-full
                                                ease-linear
                                                transition-all
                                                duration-150
                                            " type="date" v-model="evaluation.additionalInformation.reporting_time"
                                            :blocked="isProcessing || isSaving">
                                    </td>
                                </tr>
                                <tr>
                                    <td class="
                                                px-6
                                                align-middle
                                                py-3
                                                text-xs
                                                border
                                                whitespace-nowrap
                                                font-semibold
                                                text-left
                                                ">
                                        Other Expectations
                                    </td>
                                    <td class="
                                            px-8
                                            align-middle
                                            py-3
                                            text-xs
                                            border
                                            whitespace-nowrap
                                            font-semibold
                                            text-left
                                            ">
                                        <input class="
                                                border-0
                                                px-3
                                                py-3
                                                placeholder-blueGray-300
                                                text-blueGray-600
                                                bg-white
                                                rounded
                                                text-sm
                                                shadow
                                                focus:outline-none focus:ring
                                                w-full
                                                ease-linear
                                                transition-all
                                                duration-150
                                            " type="text" v-model="evaluation.additionalInformation.other_expectations"
                                            :blocked="isProcessing || isSaving">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import team2 from "@/assets/img/team-2-800x800.jpg";

export default {
    name: "CardInterviewEvaluationForm",
    props: {
        applicant: {
            type: Object,
            default: () => ({}),
        },
        bio: {
            type: Object,
        },
        isGetting: {
            type: Boolean,
        },
        isProcessing: {
            type: Boolean,
        },
    },
    data() {
        return {
            team2,
            evaluation: {
                appearance: 0,
                personalAttributes: 0,
                education: 0,
                experience: 0,
                industry: 0,
                interests: 0,
                additionalInformation: {
                    salary_expectation: '',
                    reporting_time: '',
                    other_expectations: ''
                }
            },
            error: false,
            isSaving: false
        };
    },
    computed: {
        score() {
            let res = Number(this.evaluation.appearance) + Number(this.evaluation.personalAttributes) + Number(this.evaluation.education) +
                Number(this.evaluation.experience) + Number(this.evaluation.industry) + Number(this.evaluation.interests)
            if (res > 100) {
                this.$wkToast("Score Cannot Exceed 100", {
                    duration: 3000,
                    className: "wk-alert",
                });
            }
            return res
        }
    },
    watch: {
        appearance() {
            if (this.appearance > 5) {
                this.$wkToast("Appearance Score Cannot Exceed 5", {
                    duration: 3000,
                    className: "wk-alert",
                });
                this.error = true;
            } else {
                this.error = false;
            }
        },
        personalAttributes() {
            if (this.personalAttributes > 20) {
                this.$wkToast("Personal Attributes Score Cannot Exceed 20", {
                    duration: 3000,
                    className: "wk-alert",
                });
                this.error = true;
            } else {
                this.error = false;
            }
        },
        education() {
            if (this.education > 25) {
                this.$wkToast("Education Score Cannot Exceed 25", {
                    duration: 3000,
                    className: "wk-alert",
                });
                this.error = true;
            } else {
                this.error = false;
            }
        },
        experience() {
            if (this.experience > 40) {
                this.$wkToast("Experience Score Cannot Exceed 40", {
                    duration: 3000,
                    className: "wk-alert",
                });
                this.error = true;
            } else {
                this.error = false;
            }
        },
        industry() {
            if (this.industry > 5) {
                this.$wkToast("Industry Score Cannot Exceed 5", {
                    duration: 3000,
                    className: "wk-alert",
                });
                this.error = true;
            } else {
                this.error = false;
            }
        },
        interests() {
            if (this.interests > 5) {
                this.$wkToast("Interest Score Cannot Exceed 5", {
                    duration: 3000,
                    className: "wk-alert",
                });
                this.error = true;
            } else {
                this.error = false;
            }
        }
    },
    methods: {
        saveEvaluation() {
            this.isSaving = true;
            let _ = this;
            let obj = this.evaluation
            obj.application_id = this.applicant.id
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("accessToken");
            this.axios
                .post("evaluate-applicant", obj)
                .then((res) => {
                    if (res.data.status == 201 || res.data.status == 200) {
                        _.applicant.is_evaluated = true
                        _.$wkToast("Success!", {
                            duration: 3000,
                            className: "wk-success",
                        });
                    } else {
                        _.$wkToast(res.data.message, {
                            duration: 3000,
                            className: "wk-alert",
                        });
                    }
                    _.isSaving = false;
                })
                .catch((err) => {
                    _.isSaving = false;
                    _.$wkToast(err.response.data.message, {
                        duration: 3000,
                        className: "wk-alert",
                    });
                });
        },
        emptyEntry() {
        }
    }
};
</script>

<style scoped>
.applicant-data-field {
    text-transform: uppercase;
    font-size: 11px;
}

.applicant-data {
    font-size: 15px;
}

header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
}

.table-wrapper {
    margin: auto;
    box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
}

.fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
    table-layout: fixed;
    width: 100%;
}

.fl-table td,
.fl-table th {
    text-align: center;
    padding: 8px;
}

.fl-table td {
    border-right: 1px solid #f8f8f8;
    color: #0f172a;
    font-size: 12px;
    max-width: 150px;
    word-break: break-all;
    white-space: normal;
}

.fl-table thead th {
    color: #ffffff;
    background: #4FC3A1;
}


.fl-table thead th:nth-child(odd) {
    color: #ffffff;
    background: #324960;
}

.fl-table tr:nth-child(even) {
    background: #F8F8F8;
}

/* Responsive */

@media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }

    .table-wrapper:before {
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }

    .fl-table thead,
    .fl-table tbody,
    .fl-table thead th {
        display: block;
    }

    .fl-table thead th:last-child {
        border-bottom: none;
    }

    .fl-table thead {
        float: left;
    }

    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }

    .fl-table td,
    .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }

    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }

    .fl-table tbody tr {
        display: table-cell;
    }

    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }

    .fl-table tr:nth-child(even) {
        background: transparent;
    }

    .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }

    .fl-table tbody td {
        display: block;
        text-align: center;
    }
}
</style>