<template>
  <div>
    <CardCustomerRegistration v-show="showModal" @toggle-modal="toggleModal" @get-customers="getCustomers"
      @get-prospects="getProspects" :countries="countries" :identifications="identifications" :branches="branches"
      :referrals="referrals" :selectedCustomer="selectedCustomer" @clear-selected-customer="clearSelectedCustomer"
      :isEdit="isEdit" :isConvert="isConvert" @nextPage="nextPage" />
    <div v-if="openTransferForm" class="
                    relative
                    flex flex-col
                    min-w-0
                    break-words
                    w-full
                    mb-6
                    shadow-lg
                    rounded
                    bg-white
                    px-4
                    py-4
                    transition-all
                    duration-150
                  ">
      <div class="w-full lg:w-6/12 px-4 text-left">
        <h6 class="text-blueGray-700 text-xl font-bold">Customer Transfer</h6>
      </div>
      <!-- TRANSFER FORM -->
      <form class="flex flex-wrap px-3 py-3">
        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Customer Name
          </label>
          <p>{{ customerToTransfer.name }}</p>
        </div>
        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Reason
          </label>
          <input v-model="customerToTransfer.reason_for_transfer" type="text" class="
                          border-0
                          px-3
                          py-3
                          placeholder-blueGray-300
                          text-blueGray-600
                          bg-white
                          rounded
                          text-sm
                          shadow
                          focus:outline-none focus:ring
                          w-full
                          ease-linear
                          transition-all
                          duration-150
                        " placeholder="Reason" />
        </div>

        <div class="relative w-full lg:w-4/12 px-3 mb-3">
          <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
            Transfer To
          </label>
          <select v-model="customerToTransfer.sales_rep" class="
                          border-0
                          px-3
                          py-3
                          placeholder-blueGray-300
                          text-blueGray-600
                          bg-white
                          rounded
                          text-sm
                          shadow
                          focus:outline-none focus:ring
                          w-full
                          ease-linear
                          transition-all
                          duration-150
                        ">
            <option v-for="(b, i) in users" :key="i" :value="b.id">
              {{ b.name }}
            </option>
          </select>
        </div>
        <div class="w-full lg:w-12/12 px-4 text-right">
          <button v-if="!isProcessing && !isGetting" @click="closeTransferForm()" class="
                          bg-black
                          text-white
                          active:bg-black
                          font-bold
                          uppercase
                          text-xs
                          px-4
                          py-2
                          rounded
                          shadow
                          hover:shadow-md
                          outline-none
                          focus:outline-none
                          mr-1
                          ease-linear
                          transition-all
                          duration-150
                        " type="button">
            Cancel &amp; Close
          </button>
          <button v-if="!isProcessing && !isGetting" @click="validateCustomerToTransfer()" class="
                          bg-lightBlue-500
                          text-white
                          active:bg-lightBlue-600
                          font-bold
                          uppercase
                          text-xs
                          px-4
                          py-2
                          rounded
                          shadow
                          hover:shadow-md
                          outline-none
                          focus:outline-none
                          mr-1
                          ease-linear
                          transition-all
                          duration-150
                        " type="button">
            Transfer Customer
          </button>
          <button v-if="isProcessing || isGetting" class="
                          bg-emerald-500
                          text-white
                          active:bg-emerald-600
                          font-bold
                          uppercase
                          text-small
                          px-4
                          py-2
                          rounded
                          shadow
                          hover:shadow-md
                          outline-none
                          focus:outline-none
                          mr-1
                          mb-1
                          ease-linear
                          transition-all
                          duration-150
                        " type="button">
            <i class="fas fa-spinner"></i>
          </button>
        </div>
      </form>
      <!-- END OF TRANSFER FORM -->
    </div>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4 overflow-auto">
        <CardCustomersTable :customers="customers" :prospects="prospects" :isProcessing="isProcessing"
          :isGetting="isGetting" @toggleModal="toggleModal" @view-customer-details="viewSelectedCustomer"
          @convert-prospect="convertProspect" @transfer-customer="transferCustomer" />
        <serverside-pager :data="paginateRecords" @page-clicked="getCustomers" />
      </div>
    </div>
  </div>
</template>
<script>
import CardCustomersTable from "@/components/Tables/CardCustomersTable.vue";
import useVuelidate from "@vuelidate/core";
import CardCustomerRegistration from "@/components/Cards/CardCustomerRegistration.vue";
import { required } from "@vuelidate/validators";
import ServersidePager from "@/components/ServersidePager.vue";

export default {
  name: "Customers",
  components: {
    CardCustomersTable,
    CardCustomerRegistration,
    ServersidePager,
  },
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      isGetting: false,
      customers: [],
      isEdit: false,
      isConvert: false,
      user: {
        name: "",
        type: "1",
        email: "",
        password: "",
        password_confirmation: "",
      },
      showModal: false,
      countries: [],
      identifications: [],
      branches: [],
      referrals: [],
      selectedCustomer: {},
      prospects: [],
      openTransferForm: false,
      customerToTransfer: {
        name: "",
        customer_id: "",
        reason_for_transfer: "",
        sales_rep: "",
      },
      users: [],
      paginateRecords: {}
    };
  },
  validations() {
    return {
      customerToTransfer: {
        customer_id: { required },
        reason_for_transfer: { required },
        sales_rep: { required },
      },
    };
  },
  created() {
    // this.emitter.on("view-customer-details", (data) => {
    //   this.selectedCustomer = data;
    //   this.showModal = true;
    // });
  },
  mounted() {
    this.getUsers();
    this.getCountries();
    this.getIdentifications();
    this.getBranches();
    this.getReferrals();
    this.getProspects();
    this.getCustomers();
    if (!Object.keys(this.$route.query).length) {
      return;
    } else {
      this.selectedCustomer = JSON.parse(this.$route.query.customerToConvert);
      this.isConvert = this.$route.query.isConvert;
      this.showModal = this.$route.query.openModal;
    }
  },
  methods: {
    closeTransferForm() {
      this.openTransferForm = false;
      this.customerToTransfer = {
        name: "",
        customer_id: "",
        reason_for_transfer: "",
        sales_rep: "",
      };
    },
    transferCustomer(customer) {
      window.scrollTo(0, 0);
      this.customerToTransfer.customer_id = customer.id;
      this.customerToTransfer.name =
        customer.actual_buyer_name == null
          ? customer.name
          : customer.actual_buyer_name;
      this.openTransferForm = true;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    clearSelectedCustomer() {
      this.selectedCustomer = {};
      this.isEdit = false;
      this.isConvert = false;
      this.openTransferForm = false;
    },
    viewSelectedCustomer(data) {
      this.selectedCustomer = data;
      this.isEdit = true;
      this.isConvert = false;
      this.showModal = true;
      this.openTransferForm = false;
    },
    convertProspect(data) {
      this.selectedCustomer = data;
      this.isConvert = true;
      this.isEdit = false;
      this.showModal = true;
      this.openTransferForm = false;
    },
    validateCustomerToTransfer() {
      this.v$.customerToTransfer.$reset();
      this.v$.customerToTransfer.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.completeCustomerTransfer();
      }
    },
    completeCustomerTransfer() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("customer-transfer", this.customerToTransfer)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Customer Transferred", {
              duration: 3000,
              className: "wk-success",
            });
            _.closeTransferForm();
            _.getCustomers();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    getProspects() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("prospects/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.prospects = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getReferrals() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("referrals")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.referrals = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCustomers(pageUrl) {
      let _ = this;
      this.isGetting = true;
      this.customers = []
      pageUrl = pageUrl || "customers/paginate/yes"
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get(pageUrl)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.customers = res.data.data.data;
            _.paginateRecords = res.data.data
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getCountries() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("countries")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.countries = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getUsers() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("users/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.users = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getIdentifications() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("identifications")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.identifications = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
};
</script>
