<template>
  <div>
    <CardUserRegistration v-if="showModal" @toggle-modal="toggleModal" @get-users="getUsers" />
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4 overflow-auto">
        <CardUsersTable :users="users" :isProcessing="isProcessing" :isGetting="isGetting" @toggleModal="toggleModal" />
        <serverside-pager :data="paginateRecords" @page-clicked="getUsers" />
      </div>
    </div>
  </div>
</template>
<script>
import CardUsersTable from "@/components/Tables/CardUsersTable.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";
import CardUserRegistration from "@/components/Cards/CardUserRegistration.vue";
import ServersidePager from "@/components/ServersidePager.vue";

export default {
  name: "UserRegistration",
  components: {
    CardUsersTable,
    CardUserRegistration,
    ServersidePager
  },
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      isGetting: false,
      users: [],
      user: {
        name: "",
        type: "1",
        email: "",
        password: "",
        password_confirmation: "",
      },
      showModal: false,
      paginateRecords: {}
    };
  },
  validations() {
    return {
      user: {
        name: { required },
        email: {
          required,
          email,
        },
        password: {
          required,
        },
        password_confirmation: {
          required,
          sameAsPassword: sameAs(this.user.password),
        },
      },
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    toggleModal(toggle) {
      this.showModal = toggle;
    },
    validateEntry() {
      this.v$.$validate();
      if (this.v$.error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.register();
      }
    },
    register() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("register", this.user)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Great! This user can now login", {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getUsers(pageUrl) {
      let _ = this;
      this.isGetting = true;
      this.users = []
      pageUrl = pageUrl || "users/paginate/yes"
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get(pageUrl, this.user)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.users = res.data.data.data;
            _.paginateRecords = res.data.data
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
};
</script>
