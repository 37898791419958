<template>
  <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
    " :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="text-center flex justify-between">
        <h3 class="font-semibold text-lg" :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']">
          New Bookings
        </h3>
        <button v-if="!isProcessing && !isGetting" @click="toggleModal()" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          " type="button">
          Add
        </button>
        <button v-if="isProcessing || isGetting" class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          " type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->

      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-emerald-800
                text-emerald-300
                border-emerald-700
              ">
              Prospect Name
            </th>
            <th class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-emerald-800
                text-emerald-300
                border-emerald-700
              ">
              Phone
            </th>
            <th class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-emerald-800
                text-emerald-300
                border-emerald-700
              ">
              Email
            </th>
            <th class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-emerald-800
                text-emerald-300
                border-emerald-700
              ">
              Sales Rep
            </th>
            <th class="
                px-3
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-emerald-800
                text-emerald-300
                border-emerald-700
              ">
              Site Visit
            </th>
            <th class="
                px-3
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-emerald-800
                text-emerald-300
                border-emerald-700
              ">
              Convert
            </th>
            <th class="
                px-3
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
                bg-emerald-800
                text-emerald-300
                border-emerald-700
              " v-if="(isAdmin == 1 || isManager == 1)">
              Transfer
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(c, i) in prospects" :key="i">
            <td class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              ">
              {{ c.name }}
            </td>
            <td class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              ">
              {{ c.phone_number }}
            </td>
            <td class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              ">
              {{ c.email }}
            </td>
            <td class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              ">
              {{ c.sales_rep }}
            </td>
            <td class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              ">
              <button @click.prevent="goToSite(c)" v-if="!isProcessing" class="
                  bg-lightBlue-500
                  text-white
                  active:bg-lightBlue-600
                  font-bold
                  uppercase
                  text-xs
                  px-2
                  py-2
                  rounded
                  shadow
                  hover:shadow-md
                  outline-none
                  focus:outline-none
                  ease-linear
                  transition-all
                  duration-150
                " type="button">
                Site Visit
              </button>
            </td>
            <td class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              ">
              <button @click.prevent="convertCustomer(c)" v-if="!isProcessing" class="
                  bg-purple-500
                  text-white
                  active:bg-purple-600
                  font-bold
                  uppercase
                  text-xs
                  px-2
                  py-2
                  rounded
                  shadow
                  hover:shadow-md
                  outline-none
                  focus:outline-none
                  ease-linear
                  transition-all
                  duration-150
                " type="button">
                Convert
              </button>
            </td>
            <td class="
                border-t-0
                px-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
              ">
              <button @click.prevent="transferCustomer(c)" v-if="!isProcessing && (isAdmin == 1 || isManager == 1)" class="
                  bg-teal-500
                  text-white
                  active:bg-teal-600
                  font-bold
                  uppercase
                  text-xs
                  px-2
                  py-2
                  rounded
                  shadow
                  hover:shadow-md
                  outline-none
                  focus:outline-none
                  ease-linear
                  transition-all
                  duration-150
                " type="button">
                Transfer
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import bootstrap from "@/assets/img/bootstrap.jpg";
import angular from "@/assets/img/angular.jpg";
import sketch from "@/assets/img/sketch.jpg";
import react from "@/assets/img/react.jpg";
import vue from "@/assets/img/react.jpg";

import team1 from "@/assets/img/team-1-800x800.jpg";
import team2 from "@/assets/img/team-2-800x800.jpg";
import team3 from "@/assets/img/team-3-800x800.jpg";
import team4 from "@/assets/img/team-4-470x470.png";

export default {
  data() {
    return {
      bootstrap,
      angular,
      sketch,
      react,
      vue,
      team1,
      team2,
      team3,
      team4,
      tablePage: 1,
      isAdmin: false,
      isManager: false,
    };
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    customers: {
      type: Array,
    },
    prospects: {
      type: Array,
    },
    isProcessing: {
      type: Boolean,
    },
    isGetting: {
      type: Boolean,
    },
  },
  mounted() {
    this.isAdmin = localStorage.getItem('setIsAdmin')
    this.isManager = localStorage.getItem('setIsManager')
  },
  methods: {
    goToSite(obj) {
      this.$router.push({
        path: "/marketing/site/visits",
        query: { visitor: JSON.stringify(obj) },
      });
    },
    toggleModal() {
      this.$emit("toggle-modal");
    },
    updateCustomer(obj) {
      this.$emit("view-customer-details", obj);
    },
    convertCustomer(obj) {
      this.$router.push({
        path: "/marketing/customerswizard",
        query: {
          customerToConvert: JSON.stringify(obj),
          isConvert: true,
          openModal: true,
        },
      });
    },
    transferCustomer(obj) {
      this.$emit("transfer-prospect", obj);
    },
  },
  computed: {
    tableName() {
      let tName = "";
      switch (this.tablePage) {
        case 1:
          tName = "Customers";
          break;
        case 2:
          tName = "Prospects";
          break;

        default:
          break;
      }
      return tName;
    },
  },
};
</script>
