<template>
  <div>
    <CardEnquiry
      v-if="showModal"
      :isEdit="isEdit"
      :enquiryToUpdate="enquiryToUpdate"
      @toggle-modal="toggleModal"
      @get-enquiries="getEnquiries"
      @clearData="clearData"
    />
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4 overflow-auto">
        <CardEnquiriesTable
          :enquiries="enquiries"
          :isProcessing="isProcessing"
          :isGetting="isGetting"
          @toggleModal="toggleModal"
          @deleteEnquiry="deleteEnquiry"
          @updateEnquiry="updateEnquiry"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CardEnquiriesTable from "@/components/Tables/CardEnquiriesTable.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import CardEnquiry from "@/components/Cards/CardEnquiry.vue";

export default {
  name: "EnquiriesComplaints",
  components: {
    CardEnquiriesTable,
    CardEnquiry,
  },
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      isGetting: false,
      isEdit: false,
      enquiries: [],
      enquiry: {
        customer_id: "",
        department_to: "",
        enquiry_complaint: "",
        enquiry_complaint_type: "",
        enquiry_complaint_status: "",
      },
      enquiryToUpdate: {},
      enquiryTypes: [],
      enquiryStatus: [],
      showModal: false,
    };
  },
  validations() {
    return {
      enquiry: {
        customer_id: { required },
        department_to: { required },
        enquiry_complaint: { required },
        enquiry_complaint_type: { required },
        enquiry_complaint_status: { required },
      },
    };
  },
  mounted() {
    this.getEnquiries();
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    validateEntry() {
      this.v$.$validate();
      if (this.v$.error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveEnquiry();
      }
    },
    saveEnquiry() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("enquiry-complaint-store", this.enquiry)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved!", {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
            _.clearData();
            _.showModal = false;
            _.getEnquiries();
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getEnquiries() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("enquiry-complaints")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.enquiries = res.data.data;
            _.enquiryToUpdate = {};
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    deleteEnquiry(enquiry) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("enquiry-complaint-delete/" + enquiry.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getEnquiries();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updateEnquiry(enquiry) {
      this.enquiryToUpdate = enquiry;
      // this.emitter.emit("emitEnquiry", enquiry);
      this.showModal = true;
      this.isEdit = true;
    },
    clearData() {
      this.enquiry = {
        customer_id: "",
        department_to: "",
        enquiry_complaint: "",
        enquiry_complaint_type: "",
        enquiry_complaint_status: "",
      };
      this.enquiryToUpdate = {};
      this.isEdit = false;
    },
  },
};
</script>
