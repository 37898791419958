<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          Land Owner Management
        </h6>
        <button
          v-if="!isProcessing && !isGetting && !modalVisible"
          @click="toggleModal()"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Add Details
        </button>
        <button
          v-if="!isProcessing && !isGetting && modalVisible"
          @click="cancelClose()"
          class="
            bg-black
            text-white
            active:bg-black
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Clear &amp; Cancel
        </button>
        <button
          v-if="!isProcessing && !isGetting && modalVisible"
          @click="validateEntry()"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Save
        </button>
        <button
          v-if="isProcessing || isGetting"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap" v-show="modalVisible">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Land Owner Name
            </label>
            <input
              v-model="owner.land_owner_name"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Land Owner Phone
            </label>
            <input
              v-model="owner.land_owner_phone"
              type="number"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Land Owner Email
            </label>
            <input
              v-model="owner.land_owner_email"
              type="email"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Location
            </label>
            <input
              v-model="owner.location"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Land Site
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="owner.land_site_id"
            >
              <option v-for="(s, i) in sites" :key="i" :value="s.id">
                {{ s.site_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Land Unit
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="owner.land_unit_id"
            >
              <option v-for="(b, i) in units" :key="i" :value="b.id">
                {{ b.unit_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Branch
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="owner.branch_id"
            >
              <option v-for="(b, i) in branches" :key="i" :value="b.id">
                {{ b.branch_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Currency
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="owner.currency_id"
            >
              <option v-for="(c, i) in currencies" :key="i" :value="c.id">
                {{ c.currency_name }}
              </option>
            </select>
          </div>
        </div>
        <!-- <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Customer
            </label>
            <CustomerSearch @select-client="assignCustomer" />
          </div>
        </div> -->
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Plot Total Number
            </label>
            <input
              v-model="owner.total_plot_no"
              type="number"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Estimated Cost
            </label>
            <input
              v-model="owner.estimated_cost"
              type="number"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Actual Cost
            </label>
            <input
              v-model="owner.actual_cost"
              type="number"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-auto">
          <CardLandOwnerTable
            :color="'light'"
            :owners="owners"
            :isProcessing="isProcessing"
            @delete-property="deleteOwner"
            @update-property="updateOwner"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardLandOwnerTable from "@/components/Tables/CardLandOwnerTable";
// import CustomerSearch from "@/components/Search/CustomerSearch.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "LandOwner",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      modalVisible: false,
      owner: {
        land_owner_name: "",
        land_owner_phone: "",
        land_owner_email: "",
        location: "",
        land_site_id: "",
        land_unit_id: "",
        branch_id: "",
        currency_id: "",
        total_plot_no: "",
        estimated_cost: "",
        actual_cost: "",
      },
      branches: [],
      demarcations: [],
      sites: [],
      landUsages: [],
      properties: [],
      currencies: [],
      owners: [],
      units: [],
    };
  },
  validations() {
    return {
      owner: {
        land_owner_name: { required },
        land_owner_phone: { required },
        land_owner_email: { required },
        location: { required },
        land_site_id: { required },
        land_unit_id: {},
        branch_id: { required },
        currency_id: {},
        total_plot_no: {},
        estimated_cost: {},
        actual_cost: { required },
      },
    };
  },
  components: {
    CardLandOwnerTable,
    // CustomerSearch,
  },
  created() {},
  mounted() {
    this.getBranches();
    this.getSites();
    this.getLandOwners();
    this.getUnits();
    this.getCurrencies();
    // this.getDemarcations();
    // this.getLandUsages();
  },
  methods: {
    toggleModal() {
      this.modalVisible = !this.modalVisible;
    },
    assignCustomer(client) {
      console.log(client);
      this.owner.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveLandOwner();
      }
    },
    cancelClose() {
      this.emptyEntry();
      this.toggleModal();
    },
    getSites() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-sites")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.sites = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getUnits() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("uom")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.units = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getProperties() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("property-details")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.properties = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getDemarcations() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-demarcation/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.demarcations = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getLandOwners() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("land-owner-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.owners = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    saveLandOwner() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("land-owner-store", this.owner)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved", {
              duration: 3000,
              className: "wk-success",
            });
            _.getLandOwners();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    deleteOwner(owner) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("land-owner-delete/" + owner.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getLandOwners();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    getCurrencies() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("currencies")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.currencies = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    updateOwner(property) {
      this.property = property;
      this.isEdit = true;
    },
    emptyEntry() {
      this.owner = {
        and_owner_name: "",
        land_owner_phone: "",
        land_owner_email: "",
        location: "",
        land_site_id: "",
        land_unit_id: "",
        branch_id: "",
        currency_id: "",
        total_plot_no: "",
        estimated_cost: "",
        actual_cost: "",
      };
    },
  },
};
</script>
