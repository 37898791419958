<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded light"
  >
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <!-- <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Purchase Type
          </th> -->
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Customer
          </th>
          <!-- <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Land Range From
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Land Range To
          </th> -->
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Payment Mode
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Next Month Due
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Property Cost
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Initial Deposit
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Currency
          </th>
          <th
            class="
              px-6
              align-middle
              border border-solid
              py-3
              text-xs
              uppercase
              border-l-0 border-r-0
              whitespace-nowrap
              font-semibold
              text-left
            "
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, i) in options" :key="i">
          <!-- <th
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
              flex
              items-center
            "
          >
            <span
              class="ml-3 font-bold"
              :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
            >
              {{ o.purchase_type }}
            </span>
          </th> -->
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ o.name }}
          </td>
          <!-- <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ o.land_range_from }}
          </td>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ o.land_range_to }}
          </td> -->
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ o.mode_name }}
          </td>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ $filters.cleanDate(o.next_month_due) }}
          </td>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ o.property_cost }}
          </td>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ o.initial_deposit }}
          </td>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            {{ o.currency_name }}
          </td>
          <td
            class="
              border-t-0
              px-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <a
              href="#"
              @click.prevent="deletePaymentOption(o)"
              v-if="
                !isProcessing &&
                $store.state.department ==
                  $store.state.storeDepartments.superAdmin
              "
              class="text-red-500 mr-4"
              type="button"
            >
              <i class="fas fa-trash"></i> Delete
            </a>
            <a
              href="#"
              @click.prevent="updatePaymentOption(o)"
              v-if="!isProcessing"
              class="text-lightBlue-400"
              type="button"
            >
              <i class="fas fa-edit"></i> Edit
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    options: {
      type: Array,
    },
    isProcessing: {
      type: Boolean,
    },
  },
  methods: {
    deletePaymentOption(obj) {
      this.$emit("delete-option", obj);
    },
    updatePaymentOption(obj) {
      this.$emit("update-option", obj);
    },
  },
};
</script>
