<template>
  <div class="
              relative
              flex flex-col
              min-w-0
              break-words
              bg-white
              w-full
              mb-6
              shadow-xl
              rounded-lg
              bg-blueGray-100
            ">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Expense Payments</h6>
        <button v-if="!isProcessing && !isGetting" @click="validateEntry()" class="
                    bg-lightBlue-500
                    text-white
                    active:bg-lightBlue-600
                    font-bold
                    uppercase
                    text-xs
                    px-4
                    py-2
                    rounded
                    shadow
                    hover:shadow-md
                    outline-none
                    focus:outline-none
                    mr-1
                    ease-linear
                    transition-all
                    duration-150
                  " type="button">
          Make Expense Payments
        </button>
        <button v-if="isProcessing || isGetting" class="
                    bg-emerald-500
                    text-white
                    active:bg-emerald-600
                    font-bold
                    uppercase
                    text-small
                    px-4
                    py-2
                    rounded
                    shadow
                    hover:shadow-md
                    outline-none
                    focus:outline-none
                    mr-1
                    mb-1
                    ease-linear
                    transition-all
                    duration-150
                  " type="button">
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Type of Expense
            </label>
            <select name="" id="" class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      " v-model="expense.type_id">
              <option v-for="(b, i) in expenseTypes" :key="i" :value="b.id">
                {{ b.type_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
              Mode of Payment
            </label>
            <select name="" id="" class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      " v-model="expense.mode_id">
              <option v-for="(s, i) in paymentModes" :key="i" :value="s.id">
                {{ s.mode_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Amount
            </label>
            <input v-model="expense.expense_amount" type="number" class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      " />
          </div>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Description
            </label>
            <input v-model="expense.expense_description" type="text" class="
                        border-0
                        px-3
                        py-3
                        placeholder-blueGray-300
                        text-blueGray-600
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:ring
                        w-full
                        ease-linear
                        transition-all
                        duration-150
                      " />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2 overflow-scroll">
          <CardExpensePaymentsTable :color="'light'" :payments="payments" :isProcessing="isProcessing"
            @delete-payment="deletePayment" @update-payment="updatePayment" />
          <serverside-pager :data="paginateRecords" @page-clicked="getExpensePayments" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardExpensePaymentsTable from "@/components/Tables/CardExpensePaymentsTable";
import useVuelidate from "@vuelidate/core";
import ServersidePager from "@/components/ServersidePager.vue";
import { required } from "@vuelidate/validators";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
export default {
  name: "ExpensePayments",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      expense: {
        type_id: "",
        mode_id: "",
        expense_amount: "",
        expense_description: "",
      },
      expenseTypes: [],
      paymentModes: [],
      payments: [],
      paginateRecords: {}
    };
  },
  validations() {
    return {
      expense: {
        type_id: { required },
        mode_id: { required },
        expense_amount: { required },
        expense_description: { required },
      },
    };
  },
  components: {
    CardExpensePaymentsTable,
    ServersidePager,
  },
  created() { },
  mounted() {
    this.getExpenseTypes();
    this.getPaymentModes();
    this.getExpensePayments();
  },
  methods: {
    assignCustomer(client) {
      console.log(client);
      this.property.customer_id = client.id;
    },
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveExpensePayment();
      }
    },
    getExpenseTypes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("expense-types")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.expenseTypes = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getPaymentModes() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("payment-mode")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.paymentModes = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getExpensePayments(pageUrl) {
      let _ = this;
      this.isGetting = true;
      this.payments = []
      pageUrl = pageUrl || "expense-payments/paginate/yes"
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get(pageUrl)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.payments = res.data.data.data;
            _.paginateRecords = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    saveExpensePayment() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("expense-payment-store", this.expense)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.$wkToast("Saved", {
              duration: 800,
              className: "wk-success",
            });
            _.getExpensePayments();
            // _.renderPaymentVoucher(res.data.data.expense);
            let savedData = res.data.data.expense;
            let cashData = "";

            let momoData = "";

            let chequeNumber = "";

            let amountInWords = this.amountToWords(savedData.expense_amount);

            let values = {
              id: savedData.reference_num,
              amount: savedData.expense_amount,
              date: savedData.created_at,
              cash: cashData,
              momo: momoData,
              cheque_number: chequeNumber,
              amount_in_words: amountInWords,
              payment_reason: savedData.expense_description,
              user_name: savedData.approved_by,
              customer_name: savedData.name,
            };
            let invoiceParams = values;
            setTimeout(() => {
              _.$router.push({ path: "/voucher", query: invoiceParams });
            }, 500);
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    renderPaymentVoucher(savedData) {
      let _ = this;
      loadFile("/payment_voucher.docx", function (error, content) {
        try {
          if (error) {
            throw error;
          }
          var zip = new PizZip(content);
          var doc = new Docxtemplater(zip);

          let cashData = "";

          let momoData = "";

          let chequeNumber = "";

          let amountInWords = _.amountToWords(savedData.expense_amount);

          doc.setData({
            id: savedData.reference_num,
            amount: savedData.expense_amount,
            date: savedData.created_at,
            cash: cashData,
            momo: momoData,
            cheque_number: chequeNumber,
            amount_in_words: amountInWords,
            payment_reason: savedData.expense_description,
            user_name: savedData.paid_by,
            customer_name: savedData.name,
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            _.$wkToast(error, {
              duration: 3000,
              className: "wk-alert",
            });
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            /*eslint-disable*/
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function (
                  error,
                  key
                ) {
                  error[key] = value[key];
                  return error;
                },
                  {});
              }
              return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));
            /*eslint-enable*/

            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join("\n");
              /*eslint-disable*/
              console.log("errorMessages", errorMessages);
              /*eslint-enable*/
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          var out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }); //Output the document using Data-URI
          let fullname = savedData.name;
          fullname = fullname.replace(/\s+/g, "_");
          // let business_name = _.approval.loan_application.operator.business_name
          // business_name = business_name.replace(/\s+/g, '_');
          saveAs(out, fullname + "_" + "Payment_Voucher.docx");
          _.$wkToast("Payment Voucher Issued!", {
            duration: 3000,
            className: "wk-success",
          });
          _.isProcessing = false;
        } catch (error) {
          /*eslint-disable*/
          console.log(error);
          /*eslint-enable*/
          _.$wkToast(error, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
          return;
        }
      });
    },
    amountToWords(amount) {
      if (!amount) return "";
      var converter = require("number-to-words");
      return this.captializeText(converter.toWords(amount));
    },
    captializeText(value) {
      if (!value) return "";
      // value = value.toString()
      // return value.charAt(0).toUpperCase() + value.slice(1)

      let capitalized = [];
      value.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    },
    deletePayment(payment) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("expense-payment-delete/" + payment.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getExpensePayments();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updatePayment(payment) {
      this.expense = payment;
      this.isEdit = true;
    },
    emptyEntry() {
      this.expense = {
        type_id: "",
        mode_id: "",
        expense_amount: "",
        expense_description: "",
      };
    },
  },
};
</script>
