import { createApp } from "vue";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// routes

import router from '@/router.js'
import store from '@/store.js'

// import Toasted from 'vue-toasted'

import axios from 'axios';
import VueAxios from 'vue-axios';

// import Multiselect from 'vue-multiselect'

// import VueLodash from "vue-lodash";
// import lodash from 'lodash';

// import useVuelidate from "@vuelidate/core";

import Toast from 'vue3-toast-single'
import 'vue3-toast-single/dist/toast.css'

import VueHtmlToPaper from './plugins/VueHtmlToPaper';

import moment from 'moment'

import mitt from 'mitt'

import CKEditor from '@ckeditor/ckeditor5-vue';

const emitter = mitt()

var ax = axios.create({
  // baseURL: 'https://es.techbereau.com/api',
  baseURL: 'https://erp.estatemastersapp.com/public/api',
  // baseURL: "http://localhost:8002/api",
  headers: { 'Access-Control-Allow-Origin': '*' }
});

const htmlToPaperOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    // 'assets/styles/'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

const app = createApp(App)

app.use(store)
app.use(router)
// app.use(Toasted)
app.use(VueAxios, ax)
// app.use(require('vue-moment').default)
// app.component('multiselect', Multiselect)
// app.use(VueLodash, { name: 'custom', lodash: lodash })
// app.use(useVuelidate)
app.use(Toast, { horizontalPosition: 'center', verticalPosition: 'top', transition: 'slide-up' })

app.use(VueHtmlToPaper, htmlToPaperOptions);

app.config.globalProperties.emitter = emitter

app.config.globalProperties.$filters = {
  timeAgo(date) {
    return moment(date).fromNow()
  },
  cleanDate(date) {
    return moment(date).format('dddd, MMMM Do YYYY')
  },
  parseRawDate(date) {
    return moment(date).format('YYYY-MM-DD')
  },
  nextMonthDate() {
    var currentDate = moment();
    var futureMonth = moment(currentDate).add(1, 'M');
    var futureMonthEnd = moment(futureMonth).endOf('month');

    if (currentDate.date() != futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
      futureMonth = futureMonth.add(1, 'd');
    }
    return futureMonth.format('YYYY-MM-DD')
  },
}

app.use(CKEditor)

app.mount("#app");
