<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4 mb-3">
      <div class="flex flex-wrap">
        <button
          @click="page = 0"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-credit-card"></i>
          Expense Payment
        </button>
        <button
          @click="page = 2"
          class="
            bg-indigo-500
            text-white
            active:bg-indigo-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-credit-card"></i>
          Expense Type
        </button>
        <button
          @click="page = 3"
          class="
            bg-purple-500
            text-white
            active:bg-purple-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-table"></i>
          Report
        </button>
      </div>
      <!-- <div v-if="page > 0">
        <button
          @click="page = 0"
          class="
            bg-black
            text-white
            active:bg-slate-100
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-arrow-left"></i>
          Go Back
        </button>
      </div> -->
    </div>
    <div v-if="page == 0" class="w-full lg:w-12/12 px-4">
      <ExpensePayments />
    </div>
    <div v-if="page == 2" class="w-full lg:w-12/12 px-4">
      <ExpenseTypes />
    </div>
    <div v-if="page == 3" class="w-full lg:w-12/12 px-4">
      <ExpenseReports />
    </div>
  </div>
</template>
<script>
import ExpensePayments from "@/views/accounts/ExpensePayments.vue";
import ExpenseTypes from "@/views/accounts/ExpenseTypes.vue";
import ExpenseReports from "@/views/accounts/ExpenseReports.vue";

export default {
  name: "Expenses",
  components: {
    ExpensePayments,
    ExpenseTypes,
    ExpenseReports,
  },
  data() {
    return {
      pageName: "Expenses",
      page: 0,
    };
  },
  mounted() {},
};
</script>
