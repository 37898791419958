<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4 mb-3">
      <div class="flex flex-wrap">
        <button
          @click="page = 0"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-users"></i>
          Customers
        </button>
        <button
          @click="page = 2"
          class="
            bg-indigo-500
            text-white
            active:bg-indigo-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-user"></i>
          Next Ok Kin
        </button>
        <button
          @click="page = 3"
          class="
            bg-purple-500
            text-white
            active:bg-purple-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-home"></i>
          Property
        </button>
        <!-- <button
          @click="page = 4"
          class="
            bg-orange-500
            text-white
            active:bg-orange-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-2
            mb-1
          "
          type="button"
        >
          <i class="fas fa-table"></i>
          Balances
        </button> -->
      </div>
      <!-- <div v-if="page > 0">
        <button
          @click="page = 0"
          class="
            bg-black
            text-white
            active:bg-slate-100
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-arrow-left"></i>
          Go Back
        </button>
      </div> -->
    </div>
    <div v-if="page == 0" class="w-full lg:w-12/12 px-4">
      <Customers />
    </div>
    <div v-if="page == 2" class="w-full lg:w-12/12 px-4">
      <CustomerNextOfKin />
    </div>
    <div v-if="page == 3" class="w-full lg:w-12/12 px-4">
      <CustomerProperty />
    </div>
    <div v-if="page == 4" class="w-full lg:w-12/12 px-4">
      <CustomerBalances />
    </div>
  </div>
</template>
<script>
import Customers from "@/views/marketing/Customers.vue";
import CustomerNextOfKin from "@/views/admin/CustomerNextOfKin.vue";
import CustomerProperty from "@/views/marketing/CustomerProperty.vue";
import CustomerBalances from "@/views/accounts/CustomerBalances.vue";

export default {
  name: "CustomersPage",
  components: {
    Customers,
    CustomerNextOfKin,
    CustomerProperty,
    CustomerBalances,
  },
  data() {
    return {
      pageName: "CustomersPage",
      page: 0,
    };
  },
  mounted() {},
};
</script>
