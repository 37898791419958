<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <CardLandPricing />
    </div>
  </div>
</template>
<script>
import CardLandPricing from "@/components/Cards/CardLandPricing.vue";

export default {
  name: "LandPricing",
  components: {
    CardLandPricing,
  },
  data() {
    return {
      pageName: "Land Pricing",
    };
  },
};
</script>
