<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Stock Disbursal</h6>
        <button
          v-if="!isProcessing && !isGetting"
          @click="validateEntry()"
          class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-xs
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          Disburse
        </button>
        <button
          v-if="isProcessing || isGetting"
          class="
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          "
          type="button"
        >
          <i class="fas fa-spinner"></i>
        </button>
      </div>
    </div>
    <div class="px-2">
      <div class="px-2 mb-2 mt-2 flex flex-wrap">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Product
            </label>
            <select
              name=""
              id=""
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
              v-model="disbursal.product_id"
            >
              <option v-for="(s, i) in products" :key="i" :value="s.id">
                {{ s.product_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Disbursal Qty
            </label>
            <input
              v-model="disbursal.disburse_qty"
              type="number"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Disbursal Note
            </label>
            <input
              v-model="disbursal.disburse_note"
              type="text"
              class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full px-4 text-center mt-2">
          <CardStockDisbursalsTable
            :color="light"
            :disbursals="disbursals"
            :isProcessing="isProcessing"
            @delete-disbursal="deleteDisbursal"
            @update-disbursal="updateDisbursal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStockDisbursalsTable from "@/components/Tables/CardStockDisbursalsTable";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "CardStockDisbursals",
  data() {
    return {
      v$: useVuelidate(),
      isGetting: false,
      isProcessing: false,
      isEdit: false,
      disbursal: {
        product_id: "",
        disburse_qty: "",
        disburse_note: "",
      },
      disbursals: [],
      products: [],
    };
  },
  validations() {
    return {
      disbursal: {
        product_id: { required },
        disburse_qty: { required },
        disburse_note: { required },
      },
    };
  },
  components: {
    CardStockDisbursalsTable,
  },
  created() {},
  mounted() {
    this.getProducts();
    this.getDisbursals();
  },
  methods: {
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.saveDisbursal();
      }
    },
    getProducts() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("product-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.products = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getDisbursals() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("stock-disburse-list")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.disbursals = res.data.data;
            _.emptyEntry();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    saveDisbursal() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("stock-disbursal-store", this.disbursal)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getDisbursals();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    deleteDisbursal(disbursal) {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .delete("disburse-stock-delete/" + disbursal.id)
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.getDisbursals();
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-success",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isProcessing = false;
        });
    },
    updateDisbursal(disbursal) {
      this.disbursal = disbursal;
      this.isEdit = true;
    },
    emptyEntry() {
      this.disbursal = {
        product_id: "",
        disburse_qty: "",
        disburse_note: "",
      };
    },
  },
};
</script>
