<template>
    <div class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-xl
      rounded-lg
      bg-blueGray-100
    ">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">
                    Other Internal Commissions
                </h6>
                <button v-if="isProcessing || isGetting" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          " type="button">
                    <i class="fas fa-spinner"></i>
                </button>
            </div>
        </div>
        <div class="px-2">
            <div class="px-2 mb-2 mt-2 flex flex-wrap">
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            User Group
                        </label>
                        <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="comm.user_group">
                            <option value="managers">
                                Managers
                            </option>
                            <option value="staff">
                                Junior Staff
                            </option>
                            <option value="external_agent">
                                External Agent
                            </option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="grid-password">
                            Land Site
                        </label>
                        <select name="" id="" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " v-model="comm.land_site_id">
                            <option v-for="(s, i) in sites" :key="i" :value="s.id">
                                {{ s.site_name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="w-full lg:w-4/12 px-4">
                    <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            Percentage
                        </label>
                        <input v-model="comm.percentage_due" type="number" class="
                border-0
                px-3
                py-3
                placeholder-blueGray-300
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              " />
                    </div>
                </div>
                <div class="w-full lg:w-12/12 px-4">
                    <div class="relative w-full mb-3 text-right">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                            <span v-if="isEdit">Update</span> <span v-else>Save</span> Setting
                        </label>
                        <button v-if="!isProcessing && !isGetting && isEdit" class="
            bg-black
            text-white
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          " type="button" @click="clearEntry()">
                            <i class="fas fa-trash"></i> Cancel
                        </button>
                        <button v-if="!isProcessing && !isGetting" class="
            bg-lightBlue-500
            text-white
            active:bg-lightBlue-600
            font-bold
            uppercase
            text-small
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            mb-1
            ease-linear
            transition-all
            duration-150
          " :class="{ 'bg-puple-500': isEdit }" type="button" @click="validateEntry()">
                            <i class="fas fa-cog"></i> <span v-if="isEdit">Update</span>
                            <span v-else>Save</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap">
                <div class="w-full px-4 text-center mt-2 overflow-scroll">
                    <CardOtherCommissionsTable ref="enquiryReport" :color="'light'" :settings="settings"
                        :isProcessing="isProcessing" @editSettings="parseSettingsToEdit" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardOtherCommissionsTable from "@/components/Tables/CardOtherCommissionsTable.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
    name: "OtherCommissions",
    data() {
        return {
            v$: useVuelidate(),
            isGetting: false,
            isProcessing: false,
            isEdit: false,
            queryString: {
                from: "",
                to: "",
                department_to: "",
                enquiry_complaint_type: "",
                enquiry_complaint_status: "",
            },
            expenseTypes: [],
            enquiries: {
                sum: "",
                transaction: [],
            },
            owners: [],
            departments: [],
            enquiryTypes: [],
            enquiryStatus: [],
            comm: {
                base: '0',
                limit: '',
                percentage_due: '',
                land_site_id: '',
                user_group: '',
            },
            sites: [],
            settings: []
        };
    },
    validations() {
        return {
            comm: {
                percentage_due: { required },
                user_group: { required },
                department_to: {},
                enquiry_complaint_type: {},
                enquiry_complaint_status: {},
            },
        };
    },
    components: {
        CardOtherCommissionsTable,
    },
    created() { },
    mounted() {
        this.getSettings();
        this.getSites();
    },
    methods: {
        clearEntry() {
            this.comm = {
                base: '0',
                limit: '',
                user_group: '',
                percentage_due: '',
                land_site_id: ''
            }
        },
        getSites() {
            let _ = this;
            this.isGetting = true;
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("accessToken");
            this.axios
                .get("land-sites")
                .then((res) => {
                    if (res.data.status == 201 || res.data.status == 200) {
                        _.sites = res.data.data;
                    } else {
                        _.$wkToast(res.data.error, {
                            duration: 3000,
                            className: "wk-alert",
                        });
                    }
                    _.isGetting = false;
                    _.isProcessing = false;
                })
                .catch((err) => {
                    _.$wkToast(err.response.data.message, {
                        duration: 3000,
                        className: "wk-alert",
                    });
                    _.isGetting = false;
                });
        },
        printReport() {
            this.$refs.enquiryReport.printReport();
        },
        assignCustomer(client) {
            console.log(client);
            this.property.customer_id = client.id;
        },
        validateEntry() {
            this.v$.$reset();
            this.v$.$validate();
            if (this.v$.$error) {
                this.$wkToast("Check your input", {
                    duration: 3000,
                    className: "wk-alert",
                });
            } else {
                this.saveCommissionsSetting();
            }
        },
        parseSettingsToEdit(data) {
            let parse = JSON.stringify(data)
            this.comm = JSON.parse(parse)
            this.isEdit = true
        },
        saveCommissionsSetting() {
            let _ = this;
            this.isGetting = true;
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("accessToken");
            if (this.isEdit) {
                this.axios
                    .put("commissions/settings/" + this.comm.id, this.comm)
                    .then((res) => {
                        if (res.data.status == 201 || res.data.status == 200) {
                            _.getSettings();
                            _.$wkToast("Commission Settings Updated!", {
                                duration: 3000,
                                className: "wk-success",
                            });
                            _.isEdit = false
                        } else {
                            _.$wkToast(res.data.error, {
                                duration: 3000,
                                className: "wk-alert",
                            });
                        }
                        _.isGetting = false;
                    })
                    .catch((err) => {
                        _.$wkToast(err.response.data.message, {
                            duration: 3000,
                            className: "wk-alert",
                        });
                        _.isGetting = false;
                    });
            } else {
                this.axios
                    .post("commissions/settings", this.comm)
                    .then((res) => {
                        if (res.data.status == 201 || res.data.status == 200) {
                            _.getSettings();
                            _.$wkToast("Commission Settings Saved!", {
                                duration: 3000,
                                className: "wk-success",
                            });
                        } else {
                            _.$wkToast(res.data.error, {
                                duration: 3000,
                                className: "wk-alert",
                            });
                        }
                        _.isGetting = false;
                    })
                    .catch((err) => {
                        _.$wkToast(err.response.data.message, {
                            duration: 3000,
                            className: "wk-alert",
                        });
                        _.isGetting = false;
                    });
            }
        },
        getSettings() {
            let _ = this;
            this.isGetting = true;
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("accessToken");
            this.axios
                .get("commissions/settings/managers")
                .then((res) => {
                    if (res.data.status == 201 || res.data.status == 200) {
                        _.settings = res.data.data;
                        _.clearEntry();
                    } else {
                        _.$wkToast(res.data.error, {
                            duration: 3000,
                            className: "wk-alert",
                        });
                    }
                    _.isGetting = false;
                    _.isProcessing = false;
                })
                .catch((err) => {
                    _.$wkToast(err.response.data.message, {
                        duration: 3000,
                        className: "wk-alert",
                    });
                    _.isGetting = false;
                });
        },
        getEnquiryTypes() {
            let _ = this;
            this.isGetting = true;
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("accessToken");
            this.axios
                .get("enquiry-types")
                .then((res) => {
                    if (res.data.status == 201 || res.data.status == 200) {
                        _.enquiryTypes = res.data.data;
                    } else {
                        _.$wkToast(res.data.message, {
                            duration: 3000,
                            className: "wk-alert",
                        });
                    }
                    _.isGetting = false;
                })
                .catch((err) => {
                    _.isGetting = false;
                    _.$wkToast(err.response.data.message, {
                        duration: 3000,
                        className: "wk-alert",
                    });
                });
        },
        getEnquiryStatus() {
            let _ = this;
            this.isGetting = true;
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("accessToken");
            this.axios
                .get("enquiry-status")
                .then((res) => {
                    if (res.data.status == 201 || res.data.status == 200) {
                        _.enquiryStatus = res.data.data;
                    } else {
                        _.$wkToast(res.data.message, {
                            duration: 3000,
                            className: "wk-alert",
                        });
                    }
                    _.isGetting = false;
                })
                .catch((err) => {
                    _.isGetting = false;
                    _.$wkToast(err.response.data.message, {
                        duration: 3000,
                        className: "wk-alert",
                    });
                });
        },
        getLandOwners() {
            let _ = this;
            this.isGetting = true;
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("accessToken");
            this.axios
                .get("land-owner-list")
                .then((res) => {
                    if (res.data.status == 201 || res.data.status == 200) {
                        _.owners = res.data.data;
                    } else {
                        _.$wkToast(res.data.error, {
                            duration: 3000,
                            className: "wk-alert",
                        });
                    }
                    _.isGetting = false;
                    _.isProcessing = false;
                })
                .catch((err) => {
                    _.$wkToast(err.response.data.message, {
                        duration: 3000,
                        className: "wk-alert",
                    });
                    _.isGetting = false;
                });
        },
        getReport() {
            let _ = this;
            this.isGetting = true;
            this.axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("accessToken");
            this.axios
                .post("enquiry-complaint-report", this.queryString)
                .then((res) => {
                    if (res.data.status == 201 || res.data.status == 200) {
                        _.enquiries = res.data.data;
                    } else {
                        _.$wkToast(res.data.error, {
                            duration: 3000,
                            className: "wk-alert",
                        });
                    }
                    _.isGetting = false;
                })
                .catch((err) => {
                    _.$wkToast(err.response.data.message, {
                        duration: 3000,
                        className: "wk-alert",
                    });
                    _.isGetting = false;
                });
        },
    },
};
</script>
