<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-4/12 px-4">
        <CustomerSearch @emit-data="toggleDetails" />
      </div>
      <div class="w-full lg:w-6/12 px-8">
        <CardStats />
      </div>
      <div class="w-full lg:w-6/12 px-8">
        <CardStats />
      </div>
    </div>
  </div>
</template>
<script>
import CustomerSearch from "@/components/Search/CustomerSearch.vue";
import CardStats from "@/components/Cards/CardProfile.vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: "CustomerActivity",
  components: {
    CustomerSearch,
    CardStats,
  },
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      isGetting: false,
      customers: [],
      isEdit: false,
      user: {
        name: "",
        type: "1",
        email: "",
        password: "",
        password_confirmation: "",
      },
      showModal: false,
      countries: [],
      identifications: [],
      branches: [],
      referrals: [],
      selectedCustomer: {},
    };
  },
  created() {
    // this.emitter.on("view-customer-details", (data) => {
    //   this.selectedCustomer = data;
    //   this.showModal = true;
    // });
  },
  mounted() {
    this.getCountries();
    this.getIdentifications();
    this.getBranches();
    this.getReferrals();
    this.getCustomers();
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    clearSelectedCustomer() {
      this.selectedCustomer = {};
      this.isEdit = false;
    },
    viewSelectedCustomer(data) {
      this.selectedCustomer = data;
      this.isEdit = true;
      this.showModal = true;
    },
    getReferrals() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("referrals")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.referrals = res.data.data;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
          _.isProcessing = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
    getCustomers() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("customers/paginate/no")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.customers = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getCountries() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("countries")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.countries = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getIdentifications() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("identifications")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.identifications = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getBranches() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("branches")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            _.branches = res.data.data;
          } else {
            _.$wkToast(res.data.message, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.isGetting = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
  },
};
</script>
