<template>
  <div class="flex flex-wrap">
    <div class="w-full lg:w-12/12 px-4">
      <CardProductStocks />
    </div>
  </div>
</template>
<script>
import CardProductStocks from "@/components/Cards/CardProductStocks.vue";

export default {
  components: {
    CardProductStocks,
  },
  data() {
    return {
      pageName: "ProductStocks",
    };
  },
};
</script>
