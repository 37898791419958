<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="
            relative
            flex flex-col
            min-w-0
            break-words
            w-full
            mb-6
            shadow-lg
            rounded-lg
            bg-blueGray-200
            border-0
          "
        >
          <div class="flex-auto px-4 lg:px-10 py-10">
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  "
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  v-model="user.email"
                  type="email"
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  placeholder="Email"
                />
                <p
                  class="mt-2 text-sm text-red-600"
                  v-if="v$.user.email.$error"
                >
                  Email is required
                </p>
                <p
                  class="mt-2 text-sm text-red-600"
                  v-if="!v$.user.email.email"
                >
                  Please use a valid email address
                </p>
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  "
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  v-model="user.password"
                  @keyup.enter="validateEntry()"
                  type="password"
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  placeholder="Password"
                />
                <p
                  class="mt-2 text-sm text-red-600"
                  v-if="v$.user.email.$error"
                >
                  Password is required
                </p>
              </div>
              <!-- <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="
                      form-checkbox
                      border-0
                      rounded
                      text-blueGray-700
                      ml-1
                      w-5
                      h-5
                      ease-linear
                      transition-all
                      duration-150
                    "
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Remember me
                  </span>
                </label>
              </div> -->

              <div class="text-center mt-6">
                <button
                  v-if="!isProcessing"
                  @click="validateEntry()"
                  class="
                    bg-blueGray-800
                    text-white
                    active:bg-blueGray-600
                    text-sm
                    font-bold
                    uppercase
                    px-6
                    py-3
                    rounded
                    shadow
                    hover:shadow-lg
                    outline-none
                    focus:outline-none
                    mr-1
                    mb-1
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  type="button"
                >
                  Sign In
                </button>
                <!-- <button
                  class="
                    bg-blueGray-800
                    text-white
                    active:bg-blueGray-600
                    text-sm
                    font-bold
                    uppercase
                    px-6
                    py-3
                    rounded
                    shadow
                    hover:shadow-lg
                    outline-none
                    focus:outline-none
                    mr-1
                    mb-1
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  type="button"
                >
                  Processing
                </button> -->
                <button
                  v-if="isProcessing"
                  class="
                    bg-emerald-500
                    text-white
                    active:bg-emerald-600
                    font-bold
                    uppercase
                    text-small
                    px-4
                    py-2
                    rounded
                    shadow
                    hover:shadow-md
                    outline-none
                    focus:outline-none
                    mr-1
                    mb-1
                    ease-linear
                    transition-all
                    duration-150
                  "
                  type="button"
                >
                  <i class="fas fa-spinner"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <!-- <div class="w-1/2">
            <a href="javascript:void(0)" class="text-blueGray-200">
              <small>Forgot password?</small>
            </a>
          </div> -->
          <!-- <div class="w-1/2 text-right">
            <router-link to="/auth/register" class="text-blueGray-200">
              <small>Create new account</small>
            </router-link>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useVuelidate(),
      isProcessing: false,
      user: {
        email: "",
        password: "",
      },
    };
  },
  validations() {
    return {
      user: {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    };
  },
  methods: {
    validateEntry() {
      this.v$.$reset();
      this.v$.$validate();
      if (this.v$.$error) {
        this.$wkToast("Check your input", {
          duration: 3000,
          className: "wk-alert",
        });
      } else {
        this.login();
      }
    },
    login() {
      let _ = this;
      this.isProcessing = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .post("login", this.user)
        .then((res) => {
          if (res.data.status == 200 || res.data.status == 201) {
            localStorage.setItem("accessToken", res.data.data.token);
            localStorage.setItem("department", res.data.data.department_id);
            localStorage.setItem("salesRepId", res.data.data.sales_rep_id);
            localStorage.setItem("setIsAdmin", res.data.data.is_admin);
            localStorage.setItem("setIsManager", res.data.data.is_manager);
            localStorage.setItem("storeApprovalLevel", res.data.data.approval_level);
            _.$store.dispatch(
              "setDepartment",
              localStorage.getItem("department")
            );
            _.getCompanyInfo();
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
            _.isProcessing = false;
          }
        })
        .catch((err) => {
          _.isProcessing = false;
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
        });
    },
    getCompanyInfo() {
      let _ = this;
      this.isGetting = true;
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("accessToken");
      this.axios
        .get("company-info")
        .then((res) => {
          if (res.data.status == 201 || res.data.status == 200) {
            let company = res.data.data;
            localStorage.setItem("companyInfo", JSON.stringify(company));
            _.$store.dispatch("setCompanyInfo", company);
            _.$router.push("/admin/dashboard");
            this.$wkToast("Welcome", {
              className: "wk-success",
              transition: "slide-up",
              duration: "1500",
            });
            _.isProcessing = false;
          } else {
            _.$wkToast(res.data.error, {
              duration: 3000,
              className: "wk-alert",
            });
          }
          _.isGetting = false;
        })
        .catch((err) => {
          _.$wkToast(err.response.data.message, {
            duration: 3000,
            className: "wk-alert",
          });
          _.isGetting = false;
        });
    },
  },
};
</script>
